import React, { ChangeEvent, Component } from 'react';
import FirebaseContext from '../firebase_context';
import { Errors, IAnswer, ISection, ISpace, IQuestion, VoteQuestionType } from '../types';
import UIkit from 'uikit';
import { STRING_MAX_LENGTH_VOTE_QUESTION, validateStringLength, validateStringRequired } from '../validator';
import styles from './vote-question-form.module.scss';

type IProps = {
  space: ISpace;
  currentSection: ISection;
  nickname?: string;
  questions: IQuestion[];
  answers: IAnswer[];
  onAnswer: (question: IQuestion, values: number[], nickname?: string) => void;
  onPostQuestion: (space: ISpace, section: ISection, question: IQuestion) => void;
};

type IState = {
  text: string;
  voteQuestionType: VoteQuestionType;
  errors: Errors;
};

class VoteQuestionForm extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private modalRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      text: '',
      voteQuestionType: 'question',
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
  }

  componentWillUnmount() {
    UIkit.modal(this.modalRef.current!).hide();
  }

  handleOpenModalButtonClick = (event: React.MouseEvent) => {
    this.context.logEvent('event', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.currentSection.id,
      target: 'post_question',
    });
    this.setState({
      text: '',
      errors: {},
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleTextChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const text = event.target.value;
    this.setState({
      text,
      errors: validateStringLength(text, 'text', STRING_MAX_LENGTH_VOTE_QUESTION, this.state.errors, true),
    });
  };

  handleVoteQuestionTypeChange = (event: ChangeEvent<HTMLInputElement>) => {
    const voteQuestionType = event.target.value as VoteQuestionType;
    this.setState({
      voteQuestionType,
    });
  };

  handlePostQuestionButtonClick = async (event: React.MouseEvent) => {
    this.context.logEvent('post_question_dialog', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.currentSection.id,
      target: 'post',
    });
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.props.onPostQuestion(this.props.space, this.props.currentSection, {
      text: this.state.text,
      author: this.context.currentUser()!.uid,
      type: 'vote',
      consumed: false,
      visibleResult: false,
      open: true,
      items: [],
      nickname: this.props.nickname,
      createdAt: new Date(),
      voteQuestionType: this.state.voteQuestionType,
      selected: false,
    });
    UIkit.modal(this.modalRef.current!).hide();
  };

  handleCancelButtonClick = (event: React.MouseEvent<HTMLAnchorElement>): void => {
    event.preventDefault();
    UIkit.modal(this.modalRef.current!).hide();
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.text, 'text', errors, false);
    errors = validateStringLength(this.state.text, 'text', STRING_MAX_LENGTH_VOTE_QUESTION, errors, false);
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  render(): React.ReactNode {
    return (
      <div>
        <button
          className={`uk-button uk-button-primary ${styles.open_modal_button}`}
          onClick={this.handleOpenModalButtonClick}
        />

        <div uk-modal='true' ref={this.modalRef}>
          <div className='uk-modal-dialog'>
            <button className='uk-modal-close-default uk-visible@s' type='button' uk-close='true' />
            <div className='uk-modal-body'>
              <div className={styles.vote_question_form}>
                <div className={styles.vote_question_form_header}>
                  <div className={styles.vote_question_form_header_title}>質問を投稿する</div>
                </div>
                <div className={styles.vote_question_form_detail}>
                  <div className={styles.vote_question_form_detail_post}>
                    <textarea
                      className={`${styles.vote_question_form_detail_post_field} uk-textarea`}
                      rows={5}
                      placeholder='140文字くらいがちょうど良いです'
                      value={this.state.text}
                      onChange={this.handleTextChange}
                    />
                    <span className={`${styles.vote_question_form_detail_post_message} uk-text-danger`}>
                      {this.state.errors.text}
                    </span>
                    <div className={styles.vote_question_form_detail_post_buttons}>
                      <a href='/#' className='uk-hidden@s' onClick={this.handleCancelButtonClick}>
                        キャンセル
                      </a>
                      <button
                        className={`${styles.vote_question_form_detail_post_button} uk-button uk-button-primary`}
                        type='button'
                        onClick={this.handlePostQuestionButtonClick}
                        disabled={!this.state.text || !!this.state.errors.text}
                      >
                        質問する
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default VoteQuestionForm;
