import React, { Component } from 'react';
import UIkit from 'uikit';
import SectionList from './section-list';
import { ISection, ISpace } from '../types';

type IProps = {
  space?: ISpace;
  sections: ISection[];
  editSection?: ISection;
  onEditSectionChange: (section: ISection) => void;
  onSectionOrderChange: (source: ISection, target: ISection) => void;
};

type IState = {
  visible: boolean;
};

class SectionListOffCanvas extends Component<IProps, IState> {
  offCanvasRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      visible: false,
    };
    this.offCanvasRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    const util: any = UIkit.util;
    util.on(this.offCanvasRef.current!, 'hidden', () => {
      this.setState({
        visible: false,
      });
    });
  }

  toggleOffCanvas = () => {
    if (this.state.visible) {
      UIkit.offcanvas(this.offCanvasRef.current!).hide();
    } else {
      UIkit.offcanvas(this.offCanvasRef.current!).show();
    }
    this.setState({
      visible: !this.state.visible,
    });
  };

  handleEditSectionChange = (section: ISection): void => {
    UIkit.offcanvas(this.offCanvasRef.current!).hide();
    this.setState({
      visible: false,
    });
    this.props.onEditSectionChange(section);
  };

  render(): React.ReactNode {
    return (
      <div ref={this.offCanvasRef} uk-offcanvas='flip: true'>
        <div className='uk-offcanvas-bar'>
          <SectionList
            space={this.props.space}
            sections={this.props.sections}
            editSection={this.props.editSection}
            onEditSectionChange={this.handleEditSectionChange}
            createButtonStyle={{
              margin: '0 0 0 calc(328px - 65px)',
            }}
            header={false}
            onSectionOrderChange={this.props.onSectionOrderChange}
          />
        </div>
      </div>
    );
  }
}

export default SectionListOffCanvas;
