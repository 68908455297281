import React from 'react';
import JoinedSpacesForAdmin from './joined-spaces-for-admin';
import CreateSpace from './create-space';
import AbstractAdminPage, { IAdminPageState } from './abstract-admin-page';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styles from './admin-top.module.scss';
import AdminHeader from './admin-header';
import AdminNavbar from './admin-navbar';
import { ISpaceFilter } from '../types';

interface IState extends IAdminPageState {
  spaceFilter: ISpaceFilter;
}

class AdminTop extends AbstractAdminPage<{}, IState> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {
      signingIn: true,
      signedInAsAuthorizedUser: false,
      spaceFilter: 'open',
    };
  }

  handleSpaceFilterChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
    this.setState({
      spaceFilter: event.target.value as ISpaceFilter,
    });
  };

  renderFilter(): React.ReactNode {
    return (
      <select value={this.state.spaceFilter} onChange={this.handleSpaceFilterChange}>
        <option value='open'>公開中</option>
        <option value='close'>非公開</option>
        <option value='all'>全て</option>
      </select>
    );
  }

  renderImpl(): React.ReactNode {
    return (
      <div>
        <AdminHeader />
        <AdminNavbar activePage='space_list' />
        <div className={styles.content_container}>
          <div className={styles.content_wrapper}>
            <div className={styles.content}>
              <div className={styles.spaces_header}>
                <div className={styles.spaces_header_title}>イベントの管理</div>
                <CreateSpace />
                {this.renderFilter()}
              </div>
              <JoinedSpacesForAdmin filter={this.state.spaceFilter} />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(AdminTop);
