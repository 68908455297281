import React, { Component } from 'react';
import { ISection, ISpace } from '../types';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import DelayedTask from '../common/delayed-task';
import styles from './open-close-section.module.scss';

type IProps = {
  space: ISpace;
  section: ISection;
  onOpenCloseChange: (section: ISection, open: boolean) => void;
};

class OpenCloseSection extends Component<IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  delayedTask: DelayedTask<boolean>;

  constructor(props: IProps) {
    super(props);
    this.delayedTask = new DelayedTask<boolean>(1000, this.doOpenSection);
  }

  handleOpenCloseChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const open = event.target.value === 'open';
    this.props.onOpenCloseChange(this.props.section, open);
    this.delayedTask.waitAndGo(open);
  };

  doOpenSection = async (open: boolean): Promise<void> => {
    const openSection = this.context.functions.httpsCallable('openSection');
    const result = await openSection({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      open,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <label>
          <input
            className='uk-radio'
            type='radio'
            name={`section-${this.props.section.id}-open`}
            checked={this.props.section.open}
            onChange={this.handleOpenCloseChange}
            value='open'
          />
          <span>受付中</span>
        </label>
        <label>
          <input
            className='uk-radio'
            type='radio'
            name={`question-${this.props.section.id}-open`}
            checked={!this.props.section.open}
            onChange={this.handleOpenCloseChange}
            value='close'
          />
          <span>受付終了</span>
        </label>
      </div>
    );
  }
}

export default OpenCloseSection;
