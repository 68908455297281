import React, { ChangeEvent, Component, CSSProperties } from 'react';
import styles from './create-section-button.module.scss';
import FirebaseContext from '../firebase_context';
import { Errors, SectionType } from '../types';
import moment from 'moment';
import UIkit from 'uikit';
import { STRING_MAX_LENGTH_SECTION_NAME, validateStringLength, validateStringRequired } from '../validator';
import newid from '../newid';

type IProp = {
  buttonStyle?: CSSProperties;
  spaceId?: string;
};

type IState = {
  sectionName: string;
  sectionType: SectionType;
  loading: boolean;
  errors: Errors;
};

class CreateSectionButton extends Component<IProp, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private modalRef: React.RefObject<HTMLDivElement>;

  sectionNameId: string;
  sectionTypeId: string;

  constructor(prop: IProp) {
    super(prop);
    this.state = {
      sectionName: moment().format('YYYY-MM-DD HH:mm:ss'),
      sectionType: 'q_and_a',
      loading: false,
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
    this.sectionNameId = newid();
    this.sectionTypeId = newid();
  }

  handleButtonClick = (_event: React.MouseEvent) => {
    this.setState({
      sectionName: moment().format('YYYY-MM-DD HH:mm:ss'),
      sectionType: 'q_and_a',
      errors: {},
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleSectionNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const sectionName = event.target.value;
    this.setState({
      sectionName,
      errors: validateStringLength(sectionName, 'sectionName', STRING_MAX_LENGTH_SECTION_NAME, this.state.errors, true),
    });
  };

  handleCreateSectionButtonClick = async (event: React.MouseEvent) => {
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const createSection = this.context.functions.httpsCallable('createSection');
    const result = await createSection({
      spaceId: this.props.spaceId,
      name: this.state.sectionName,
      type: this.state.sectionType,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      UIkit.modal(this.modalRef.current!).hide();
    } else {
      UIkit.modal(this.modalRef.current!).hide();
      UIkit.modal.alert(`Error: ${result.data.errorMessage}`).then(() => {
        UIkit.modal(this.modalRef.current!).show();
      });
    }
  };

  handleSectionTypeChange = (event: ChangeEvent<HTMLSelectElement>) => {
    this.setState({
      sectionType: event.target.value as SectionType,
    });
  };

  handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.sectionName, 'sectionName', errors, false);
    errors = validateStringLength(this.state.sectionName, 'sectionName', STRING_MAX_LENGTH_SECTION_NAME, errors, false);
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderCreateButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className='uk-button uk-button-primary' type='button' onClick={this.handleCreateSectionButtonClick}>
          セクション作成
        </button>
      );
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <button
          className={`uk-button uk-button-primary ${styles.create_button}`}
          onClick={this.handleButtonClick}
          style={this.props.buttonStyle}
        />

        <div uk-modal='true' ref={this.modalRef}>
          <div className='uk-modal-dialog'>
            <button className='uk-modal-close-default' type='button' uk-close='true' />
            <div className='uk-modal-body'>
              <h2>新しいセクションの作成</h2>
              <form className='uk-form-stacked' onSubmit={this.handleFormSubmit}>
                <div className='uk-margin'>
                  <label className='uk-form-label' htmlFor={this.sectionNameId}>
                    セクション名の設定*
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id={this.sectionNameId}
                      type='text'
                      placeholder='名前をつけてください'
                      value={this.state.sectionName}
                      onChange={this.handleSectionNameChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.sectionName}</span>
                  </div>
                </div>
                <div className='uk-margin'>
                  <label className='uk-form-label' htmlFor={this.sectionTypeId}>
                    セクションの種別
                  </label>
                  <div className='uk-form-controls'>
                    <select
                      id={this.sectionTypeId}
                      className={`uk-select ${styles.question_type_select}`}
                      value={this.state.sectionType}
                      onChange={this.handleSectionTypeChange}
                    >
                      <option value='q_and_a'>質疑応答</option>
                      <option value='survey'>アンケート</option>
                    </select>
                  </div>
                </div>
                <div className='uk-margin'>
                  <div className={styles.description}>
                    参加者が登壇者に質問を投げかけたり、運営側が作成したアンケートに回答できる場のことをセクションと呼びます。
                  </div>
                </div>
              </form>
            </div>
            <div className='uk-modal-footer uk-text-right'>{this.renderCreateButton()}</div>
          </div>
        </div>
      </>
    );
  }
}

export default CreateSectionButton;
