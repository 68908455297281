import { Errors } from './types';

export const STRING_MAX_LENGTH_SPACE_NAME = 128;
export const STRING_MAX_LENGTH_SPACE_ID = 32;
export const STRING_MAX_LENGTH_SPACE_PASSWORD = 128;
export const STRING_MAX_LENGTH_ADMIN_PASSWORD = 128;
export const STRING_MAX_LENGTH_SECTION_NAME = 128;
export const STRING_MAX_LENGTH_NICKNAME = 16;
export const STRING_MAX_LENGTH_VOTE_QUESTION = 512;
export const STRING_MAX_LENGTH_SURVEY_QUESTION = 256;
export const STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM = 128;
export const STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK = 1024;
export const MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM = 100;
export const MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM = 100;

export const validateStringLength = (
  source: string,
  name: string,
  length: number,
  errors: Errors,
  clear: boolean
): Errors => {
  const copied: Errors = Object.assign({}, errors);
  if (clear) {
    delete copied[name];
  }
  if (source.trim().length > length) {
    copied[name] = `${length}文字以内です。`;
  }
  return copied;
};

export const validateStringRequired = (source: string, name: string, errors: Errors, clear: boolean): Errors => {
  const copied: Errors = Object.assign({}, errors);
  if (clear) {
    delete copied[name];
  }
  if (source.trim().length === 0) {
    copied[name] = `何か入力してください。`;
  }
  return copied;
};

export const validateArrayLength = (
  source: any[],
  name: string,
  length: number,
  errors: Errors,
  clear: boolean
): Errors => {
  const copied: Errors = Object.assign({}, errors);
  if (clear) {
    delete copied[name];
  }
  if (source.length > length) {
    copied[name] = `${length}個以内です。`;
  }
  return copied;
};

export const validateArrayRequired = (
  source: any[],
  name: string,
  length: number,
  errors: Errors,
  clear: boolean
): Errors => {
  const copied: Errors = Object.assign({}, errors);
  if (clear) {
    delete copied[name];
  }
  if (source.length < length) {
    copied[name] = `${length}個以上必要です。`;
  }
  return copied;
};

export const validateNotContains = (
  source: string,
  name: string,
  disallowed: string[],
  errors: Errors,
  clear: boolean
): Errors => {
  const copied: Errors = Object.assign({}, errors);
  if (clear) {
    delete copied[name];
  }
  for (const d of disallowed) {
    if (source.indexOf(d) !== -1) {
      copied[name] = `"${d}"を含まないでください。`;
    }
  }
  return copied;
};
