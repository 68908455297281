import React, { Component } from 'react';
import { renderSectionType, sortQuestions } from '../utils';
import styles from './full-screen-questions.module.scss';
import { IAnswer, IQuestion, ISection, ISpace, QuestionsFilter, QuestionsSortOrder, ViewMode } from '../types';
import VoteQuestion from '../common/vote-question';
import SingleAnswerQuestion from '../common/single-answer-question';
import MultipleAnswersQuestion from '../common/multiple-answers-question';
import QuestionsControlButtons from '../common/questions-control-buttons';
import FirebaseContext from '../firebase_context';

type IProps = {
  space?: ISpace;
  section?: ISection;
  answers: IAnswer[];
  questions: IQuestion[];
  onConsumedChange: (consumed: boolean, question: IQuestion) => void;
  onQuestionChange: (question: IQuestion) => void;
  onQuestionsSortOrderListChange: (section: ISection, questionsSortOrder: QuestionsSortOrder) => void;
  onQuestionsFilterChange: (section: ISection, questionsFilter: QuestionsFilter) => void;
  onExitFullscreenButtonClick: () => void;
  onQuestionDelete: (question: IQuestion) => void;
};

type IState = {
  viewMode: ViewMode;
  orderedQuestions: IQuestion[];
  cardViewIndex: number;
  questionsSortOrder: QuestionsSortOrder;
  questionsFilter: QuestionsFilter;
};

class FullScreenQuestions extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  cardViewDivRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      viewMode: 'list',
      orderedQuestions: [],
      cardViewIndex: 0,
      questionsSortOrder: props.section ? props.section.questionsSortOrder : 'created_at_asc',
      questionsFilter: props.section ? props.section.questionsFilter : 'all',
    };
    this.cardViewDivRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    const currentUser = this.context.currentUser()!;
    const orderedQuestions = this.props.section
      ? sortQuestions(this.props.section, this.props.answers, this.props.questions, currentUser.uid)
      : [];
    this.setState({
      orderedQuestions,
    });
  }

  componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>, snapshot?: any) {
    if (
      this.state.questionsSortOrder !== this.props.section?.questionsSortOrder ||
      this.state.questionsFilter !== this.props.section?.questionsFilter ||
      prevProps.section !== this.props.section ||
      prevProps.questions !== this.props.questions
    ) {
      this.setState({
        orderedQuestions: this.sortQuestions(),
      });
    }
    if (this.state.viewMode === 'card') {
      this.cardViewDivRef.current!.focus();
    }
  }

  sortQuestions(): IQuestion[] {
    const currentUser = this.context.currentUser()!;
    const orderedQuestions = this.props.section
      ? sortQuestions(this.props.section, this.props.answers, this.props.questions, currentUser.uid)
      : [];
    this.setState({
      questionsSortOrder: this.props.section ? this.props.section.questionsSortOrder : 'created_at_asc',
      questionsFilter: this.props.section ? this.props.section.questionsFilter : 'all',
    });
    return orderedQuestions;
  }

  handleCardViewCloseButtonClick = (): void => {
    this.closeCardView();
  };

  closeCardView(): void {
    const orderedQuestions = this.sortQuestions();
    this.setState({
      viewMode: 'list',
      orderedQuestions,
      cardViewIndex: 0,
    });
  }

  moveToPreviousQuestionOnCardView(): void {
    if (this.state.cardViewIndex === 0) {
      this.setState({
        cardViewIndex: this.state.orderedQuestions.length - 1,
      });
    } else {
      this.setState({
        cardViewIndex: this.state.cardViewIndex - 1,
      });
    }
  }

  moveToNextQuestionOnCardView(): void {
    if (this.state.cardViewIndex === this.state.orderedQuestions.length - 1) {
      this.setState({
        cardViewIndex: 0,
      });
    } else {
      this.setState({
        cardViewIndex: this.state.cardViewIndex + 1,
      });
    }
  }

  handleCardViewKeyDown = (event: React.KeyboardEvent<HTMLDivElement>): void => {
    event.stopPropagation();
    if (this.state.viewMode === 'card') {
      if (event.keyCode === 38) {
        // Up arrow key
        this.moveToPreviousQuestionOnCardView();
      } else if (event.keyCode === 40 || event.keyCode === 32) {
        // Down arrow and space key
        this.moveToNextQuestionOnCardView();
      } else if (event.keyCode === 27) {
        // ESC key
        this.closeCardView();
      }
    }
  };

  handleVoteQuestionClick = (question: IQuestion): void => {
    const orderedQuestions = this.sortQuestions();
    const cardViewIndex = orderedQuestions.findIndex((q) => {
      return q.id === question.id;
    });
    this.setState({
      viewMode: 'card',
      orderedQuestions,
      cardViewIndex,
    });
  };

  adjustCardViewIndex() {
    if (this.state.viewMode === 'card') {
      if (this.state.orderedQuestions.length === 1) {
        this.closeCardView();
      } else if (this.state.orderedQuestions.length - 1 === this.state.cardViewIndex) {
        this.setState({
          cardViewIndex: this.state.cardViewIndex - 1,
        });
      }
    }
  }

  onConsumedChange = (consumed: boolean, question: IQuestion): void => {
    this.adjustCardViewIndex();
    this.props.onConsumedChange(consumed, question);
  };

  onQuestionDelete = (question: IQuestion): void => {
    this.adjustCardViewIndex();
    this.props.onQuestionDelete(question);
  };

  renderListView(): React.ReactNode {
    const questionNodes = this.state.orderedQuestions.map((question, index) => {
      const answers = this.props.answers.filter((answer: IAnswer) => answer.questionId === question.id);
      if (question.type === 'vote') {
        return (
          <VoteQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onConsumedChange={this.onConsumedChange}
            cardView={false}
            onDelete={this.onQuestionDelete}
            onClick={this.state.viewMode === 'list' ? this.handleVoteQuestionClick : undefined}
            fullscreen={true}
          />
        );
      } else if (question.type === 'single_answer') {
        return (
          <SingleAnswerQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onQuestionChange={this.props.onQuestionChange}
            fullScreen={true}
            questionOffset={index}
            questionTotalSize={this.state.orderedQuestions.length}
            offsetInQuestions={index}
          />
        );
      } else {
        // 'multi_answers'
        return (
          <MultipleAnswersQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onQuestionChange={this.props.onQuestionChange}
            fullScreen={true}
            questionOffset={index}
            questionTotalSize={this.state.orderedQuestions.length}
            offsetInQuestions={index}
          />
        );
      }
    });
    return (
      <div className={styles.fullscreen_questions_list_view_container}>
        <div className={styles.fullscreen_questions_list_view_wrapper}>{questionNodes}</div>
      </div>
    );
  }

  renderCardView(): React.ReactNode {
    if (this.state.viewMode !== 'card') {
      return null;
    }
    const question = this.state.orderedQuestions[this.state.cardViewIndex];
    const answers = this.props.answers.filter((answer: IAnswer) => answer.questionId === question.id);
    let questionNode: React.ReactNode;
    if (question.type === 'vote') {
      questionNode = (
        <VoteQuestion
          key={question.id}
          space={this.props.space!}
          section={this.props.section!}
          question={question}
          answers={answers}
          forAdmin={true}
          onConsumedChange={this.onConsumedChange}
          cardView={true}
          onDelete={this.onQuestionDelete}
          fullscreen={true}
        />
      );
    } else {
      throw new Error(`The question type is invalid: ${question.type}`);
    }
    return (
      <div
        className={styles.fullscreen_questions_card_view_container}
        tabIndex={0}
        onKeyDown={this.handleCardViewKeyDown}
        ref={this.cardViewDivRef}
      >
        <div className={styles.fullscreen_questions_card_view_header}>
          <div
            className={styles.fullscreen_questions_card_view_close_button}
            onClick={this.handleCardViewCloseButtonClick}
          />
        </div>
        <div className={styles.fullscreen_questions_card_view_wrapper}>
          <div className={styles.fullscreen_questions_card_view_question}>{questionNode}</div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    if (!this.props.section) {
      return null;
    }
    return (
      <div className={styles.fullscreen_container}>
        <div className={styles.fullscreen_header}>
          <div className={styles.fullscreen_section_type}>{renderSectionType(this.props.section!)}</div>
          <div className={styles.fullscreen_control_buttons_row}>
            <QuestionsControlButtons
              space={this.props.space}
              section={this.props.section}
              forFullScreen={true}
              onQuestionsSortOrderListChange={this.props.onQuestionsSortOrderListChange}
              onQuestionsFilterChange={this.props.onQuestionsFilterChange}
              onExitFullscreenButtonClick={this.props.onExitFullscreenButtonClick}
              supportRandomSort={true}
              forAdmin={true}
            />
          </div>
        </div>
        {this.renderListView()}
        {this.renderCardView()}
      </div>
    );
  }
}

export default FullScreenQuestions;
