// See: https://www.sitepoint.com/react-toggle-switch-reusable-component/

import React, { Component } from 'react';
import './toggle-switch.scss';

type IProps = {
  id: string;
  text: string[];
  name?: string;
  onChange?: (checked: boolean) => Promise<void>;
  small?: boolean;
  currentValue?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
};

type IState = {
  checked: boolean;
};

class ToggleSwitch extends Component<IProps, IState> {
  static defaultProps = {
    text: ['Yes', 'No'],
  };

  constructor(props: IProps) {
    super(props);
    this.state = {
      checked: this.props.currentValue || false,
    };
  }

  onChange = async (e: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    console.log('onChange');
    const checked = e.target.checked;
    this.setState({
      checked,
    });
    if (this.props.onChange) {
      await this.props.onChange(checked);
    }
  };

  render(): React.ReactNode {
    return (
      <div
        className={'toggle-switch' + (this.props.small ? ' small-switch' : '')}
        style={this.props.style}
        onClick={(event) => event.stopPropagation()}
      >
        <input
          type='checkbox'
          name={this.props.name}
          className='toggle-switch-checkbox'
          id={this.props.id}
          checked={this.props.currentValue}
          onChange={this.onChange}
          disabled={this.props.disabled}
        />
        {this.props.id ? (
          <label className='toggle-switch-label' htmlFor={this.props.id}>
            <span
              className={this.props.disabled ? 'toggle-switch-inner toggle-switch-disabled' : 'toggle-switch-inner'}
              data-yes={this.props.text[0]}
              data-no={this.props.text[1]}
            />
            <span
              className={this.props.disabled ? 'toggle-switch-switch toggle-switch-disabled' : 'toggle-switch-switch'}
            />
          </label>
        ) : null}
      </div>
    );
  }
}

export default ToggleSwitch;
