import React, { Component } from 'react';
import FirebaseContext from './firebase_context';
import { RouteComponentProps, withRouter } from 'react-router-dom';

type IState = {
  username: string;
  password: string;
};

class Autify extends Component<RouteComponentProps<{}>, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {
      username: '',
      password: '',
    };
  }

  componentDidMount(): void {
    if (process.env.REACT_APP_ENVIRONMENT_NAME === 'production') {
      this.props.history.push('/');
    }
  }

  handleButtonClick = async (): Promise<void> => {
    const authenticateForAutify = this.context.functions.httpsCallable('authenticateForAutify');
    const result = await authenticateForAutify({
      username: this.state.username,
      password: this.state.password,
    });
    if (result.data.success) {
      const customToken = result.data.customToken;
      await this.context.doSignInWithCustomToken(customToken);
      this.props.history.push('/admin');
    } else {
      console.log(result.data);
      this.props.history.push('/');
    }
  };

  handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const username = event.target.value;
    this.setState({
      username,
    });
  };

  handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const password = event.target.value;
    this.setState({
      password,
    });
  };

  render(): React.ReactNode {
    return (
      <div>
        <input id='autify-username' value={this.state.username} onChange={this.handleUsernameChange} />
        <input id='autify-password' value={this.state.password} onChange={this.handlePasswordChange} />
        <button onClick={this.handleButtonClick}>Login</button>
      </div>
    );
  }
}

export default withRouter(Autify);
