import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import InProgress from '../common/in-progress';

export interface IAdminPageState {
  signingIn: boolean;
  signedInAsAuthorizedUser: boolean;
}

abstract class AbstractAdminPage<P, T extends IAdminPageState> extends Component<RouteComponentProps<P>, T> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  didSignIn(): void {}

  doSignInWithGoogle(): void {
    this.context
      .doSignInWithGoogle()
      .then((value) => {
        console.log('didSignInWithGoogle');
      })
      .catch((reason) => {
        UIkit.modal.alert(`Error: ${reason}`).then(() => {
          this.props.history.push('/');
        });
      });
  }

  doSignOut(): void {
    this.context
      .doSignOut()
      .then(() => {
        this.setState({
          signedInAsAuthorizedUser: false,
        });
      })
      .catch((reason) => {
        UIkit.modal.alert(`Error: ${reason}`).then(() => {
          this.props.history.push('/');
        });
      });
  }

  componentDidMount() {
    this.context.subscribeAuthStateChangedEvent((user) => {
      if (user === null) {
        this.doSignInWithGoogle();
      } else {
        if (user.isAnonymous) {
          this.setState({
            signingIn: false,
          });
        } else {
          user.getIdTokenResult().then((idTokenResult) => {
            if (idTokenResult.claims.autifyUser) {
              this.setState({
                signingIn: false,
                signedInAsAuthorizedUser: true,
              });
              this.didSignIn();
              return;
            }
            const checkTester = this.context.functions.httpsCallable('checkTester');
            checkTester().then((result) => {
              if (result.data.success) {
                if (result.data.isTester) {
                  this.setState({
                    signingIn: false,
                    signedInAsAuthorizedUser: true,
                  });
                  this.didSignIn();
                } else {
                  UIkit.modal.alert('Your account is not an authorized user.').then(() => {
                    this.doSignOut();
                  });
                }
              } else {
                UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
              }
            });
          });
        }
      }
    });
  }

  componentWillUnmount() {
    this.context.unsubscribeAuthStateChangedEvent();
  }

  handleSignInClick = (event: React.MouseEvent): void => {
    this.doSignInWithGoogle();
  };

  abstract renderImpl(): React.ReactNode;

  render(): React.ReactNode {
    if (this.state.signingIn) {
      return <InProgress />;
    } else if (this.state.signedInAsAuthorizedUser) {
      return this.renderImpl();
    } else {
      return (
        <div>
          はじめに
          <button
            className='uk-button uk-button-link uk-text-bold uk-margin-small-left uk-margin-small-right'
            onClick={this.handleSignInClick}
          >
            サインイン
          </button>
          してください。
        </div>
      );
    }
  }
}

export default AbstractAdminPage;
