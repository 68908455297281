import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import JoinToSpaceForm from './attendee/join-to-space-form';
import styles from './top.module.scss';
import Footer from './common/footer';

class Top extends Component<any, any> {
  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.title_container}>
          <div className={`${styles.title} uk-heading-large uk-text-center`} />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_panel_wrapper}>
            <div className={styles.form_panel}>
              <div className={styles.form_title}>イベントに参加</div>
              <JoinToSpaceForm screenName='top' />
            </div>
          </div>
          <div className={styles.form_panel_wrapper}>
            <div className={styles.form_panel}>
              <div className={styles.form_title}>ログインしてイベント運営</div>
              <Link className={`${styles.form_admin_login_button} uk-button uk-button-danger`} to='/admin'>
                <span>Googleアカウント連携</span>
              </Link>
            </div>
          </div>
        </div>
        <div className={styles.modrow_container}>
          <div className={styles.modrow} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default Top;
