import React from 'react';
import { withRouter } from 'react-router-dom';
import JoinToSpaceForm from './join-to-space-form';
import AbstractAttendeePage, { IAttendeePageState } from './abstract-attendee-page';
import styles from './space-entrance.module.scss';
import Footer from '../common/footer';
import FirebaseContext from '../firebase_context';

interface IState extends IAttendeePageState {
  spaceId: string;
}

interface IParams {
  spaceId: string;
}

class SpaceEntrance extends AbstractAttendeePage<IParams, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      signedIn: false,
      spaceId: decodeURIComponent(this.props.match.params.spaceId),
    };
  }

  componentDidMount() {
    super.componentDidMount();
    this.context.logEvent('event_entrance', 'open', {
      event_id: this.state.spaceId,
    });
  }

  handleTitleClick = (): void => {
    this.props.history.push('/');
  };

  renderImpl(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.title_container}>
          <div className={`${styles.title} uk-heading-large uk-text-center`} onClick={this.handleTitleClick} />
        </div>
        <div className={styles.form_container}>
          <div className={styles.form_panel_wrapper}>
            <div className={styles.form_panel}>
              <div className={styles.form_title}>イベントに参加</div>
              <JoinToSpaceForm customSpaceId={this.state.spaceId} withPassword={true} screenName='event_entrance' />
            </div>
          </div>
        </div>
        <div className={styles.modrow_container}>
          <div className={styles.modrow} />
        </div>
        <Footer />
      </div>
    );
  }
}

export default withRouter(SpaceEntrance);
