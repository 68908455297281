import React, { ChangeEvent, Component } from 'react';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import { withRouter } from 'react-router-dom';
import { Errors } from '../types';
import { generateDefaultPassword, generateDefaultSpaceId } from '../utils';
import {
  STRING_MAX_LENGTH_ADMIN_PASSWORD,
  STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
  STRING_MAX_LENGTH_SPACE_ID,
  STRING_MAX_LENGTH_SPACE_NAME,
  STRING_MAX_LENGTH_SPACE_PASSWORD,
  validateNotContains,
  validateStringLength,
  validateStringRequired,
} from '../validator';
import styles from './create-space.module.scss';

type IState = {
  spaceName: string;
  spaceId: string;
  spacePassword: string;
  adminPassword: string;
  slackIncomingWebhook: string;
  slackSendMessage: boolean;
  loading: boolean;
  errors: Errors;
};

class CreateSpace extends Component<any, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private modalRef: React.RefObject<HTMLDivElement>;

  constructor(prop: any) {
    super(prop);
    this.state = {
      spaceName: '',
      spaceId: '',
      spacePassword: '',
      adminPassword: '',
      slackIncomingWebhook: '',
      slackSendMessage: false,
      loading: false,
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
  }

  handleOpenModalButtonClick = (event: React.MouseEvent) => {
    this.setState({
      spaceName: '',
      spaceId: generateDefaultSpaceId(),
      spacePassword: generateDefaultPassword(),
      adminPassword: generateDefaultPassword(),
      slackIncomingWebhook: '',
      slackSendMessage: false,
      errors: {},
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleSpaceNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spaceName = event.target.value;
    this.setState({
      spaceName,
      errors: validateStringLength(spaceName, 'spaceName', STRING_MAX_LENGTH_SPACE_NAME, this.state.errors, true),
    });
  };

  handleSpaceIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spaceId = event.target.value;
    this.setState({
      spaceId,
      errors: validateStringLength(spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, this.state.errors, true),
    });
  };

  handleSpacePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spacePassword = event.target.value;
    this.setState({
      spacePassword,
      errors: validateStringLength(
        spacePassword,
        'spacePassword',
        STRING_MAX_LENGTH_SPACE_PASSWORD,
        this.state.errors,
        true
      ),
    });
  };

  handleAdminPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const adminPassword = event.target.value;
    this.setState({
      adminPassword,
      errors: validateStringLength(
        adminPassword,
        'adminPassword',
        STRING_MAX_LENGTH_ADMIN_PASSWORD,
        this.state.errors,
        true
      ),
    });
  };

  handleCreateSpaceButtonClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const createSpace = this.context.functions.httpsCallable('createSpace');
    const result = await createSpace({
      name: this.state.spaceName,
      customSpaceId: this.state.spaceId,
      spacePassword: this.state.spacePassword,
      adminPassword: this.state.adminPassword,
      slackIncomingWebhook: this.state.slackIncomingWebhook,
      slackSendMessage: this.state.slackSendMessage,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      UIkit.modal(this.modalRef.current!).hide();
    } else {
      UIkit.modal(this.modalRef.current!).hide();
      UIkit.modal.alert(`Error: ${result.data.errorMessage}`).then(() => {
        UIkit.modal(this.modalRef.current!).show();
      });
    }
  };

  handleSlackIncomingWebhookChange = (event: ChangeEvent<HTMLInputElement>) => {
    const slackIncomingWebhook = event.target.value;
    this.setState({
      slackIncomingWebhook,
      errors: validateStringLength(
        slackIncomingWebhook,
        'slackIncomingWebhook',
        STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
        this.state.errors,
        true
      ),
    });
  };

  handleSlackSendMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const slackSendMessage = event.target.checked;
    this.setState({
      slackSendMessage,
    });
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.spaceName, 'spaceName', errors, false);
    errors = validateStringLength(this.state.spaceName, 'spaceName', STRING_MAX_LENGTH_SPACE_NAME, errors, false);
    errors = validateStringRequired(this.state.spaceId, 'spaceId', errors, false);
    errors = validateStringLength(this.state.spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, errors, false);
    errors = validateStringLength(
      this.state.spacePassword,
      'spacePassword',
      STRING_MAX_LENGTH_SPACE_PASSWORD,
      errors,
      false
    );
    errors = validateStringRequired(this.state.adminPassword, 'adminPassword', errors, false);
    errors = validateStringLength(
      this.state.adminPassword,
      'adminPassword',
      STRING_MAX_LENGTH_ADMIN_PASSWORD,
      errors,
      false
    );
    errors = validateStringLength(
      this.state.slackIncomingWebhook,
      'slackIncomingWebhook',
      STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
      errors,
      false
    );
    errors = validateNotContains(this.state.spaceId, 'spaceId', ['/'], errors, false);
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderCreateButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className='uk-button uk-button-primary' type='button' onClick={this.handleCreateSpaceButtonClick}>
          イベント作成
        </button>
      );
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <button
          className={`uk-button uk-button-primary ${styles.create_button}`}
          onClick={this.handleOpenModalButtonClick}
        />

        <div uk-modal='true' ref={this.modalRef}>
          <div className='uk-modal-dialog'>
            <button className='uk-modal-close-default' type='button' uk-close='true' />
            <div className='uk-modal-body'>
              <h2>新しいイベントの作成</h2>
              <div className={styles.form_container}>
                <div className={styles.form_row}>
                  <label htmlFor='create-space-form-space-name'>イベント名の設定*</label>
                  <input
                    className='uk-input'
                    id='create-space-form-space-name'
                    type='text'
                    placeholder='イベント名を入力してください。'
                    value={this.state.spaceName}
                    onChange={this.handleSpaceNameChange}
                  />
                  <span className={`${styles.warning_message} uk-text-danger`}>{this.state.errors.spaceName}</span>
                </div>
                <div className={styles.form_row}>
                  <label htmlFor='create-space-form-space-id'>イベントIDの設定（覚えやすい値に変更OK）*</label>
                  <input
                    className='uk-input'
                    id='create-space-form-space-id'
                    type='text'
                    placeholder='イベントIDを入力してください。'
                    value={this.state.spaceId}
                    onChange={this.handleSpaceIdChange}
                  />
                  <span className={`${styles.warning_message} uk-text-danger`}>{this.state.errors.spaceId}</span>
                </div>
                <div className={styles.form_row}>
                  <label htmlFor='create-space-form-space-password'>参加パスワードの設定（覚えやすい値に変更OK）</label>
                  <input
                    className='uk-input'
                    id='create-space-form-space-password'
                    type='text'
                    placeholder='イベントに参加するためのパスワードを入力してください。'
                    value={this.state.spacePassword}
                    onChange={this.handleSpacePasswordChange}
                  />
                  <span className={`${styles.warning_message} uk-text-danger`}>{this.state.errors.spacePassword}</span>
                </div>
                <div className={styles.form_row}>
                  <label htmlFor='create-space-form-admin-password'>
                    管理用パスワードの設定（覚えやすい値に変更OK）*
                  </label>
                  <input
                    className='uk-input'
                    id='create-space-form-admin-password'
                    type='text'
                    placeholder='イベントを管理するためのパスワードを入力してください。'
                    value={this.state.adminPassword}
                    onChange={this.handleAdminPasswordChange}
                  />
                  <span className={`${styles.warning_message} uk-text-danger`}>{this.state.errors.adminPassword}</span>
                </div>
                <div className={styles.form_row}>
                  <label htmlFor='create-space-form-slack-incoming-webhook'>Slack Incoming Webhook URL</label>
                  <input
                    className='uk-input'
                    id='create-space-form-slack-incoming-webhook'
                    type='text'
                    placeholder='投稿された質問をSlackに送信したい場合に入力してください。'
                    value={this.state.slackIncomingWebhook}
                    onChange={this.handleSlackIncomingWebhookChange}
                  />
                  <span className='uk-text-danger'>{this.state.errors.slackIncomingWebhook}</span>
                  <div className={styles.form_sub_row}>
                    <label>
                      <input
                        className='uk-checkbox'
                        id='edit-space-form-slack-send-message'
                        type='checkbox'
                        checked={this.state.slackSendMessage}
                        onChange={this.handleSlackSendMessageChange}
                      />
                      Slackにメッセージを送信する。
                    </label>
                  </div>
                </div>
                <div className={styles.form_footer}>{this.renderCreateButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(CreateSpace);
