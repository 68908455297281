import React from 'react';
import styles from './bulk-control.module.scss';
import { ISection } from '../types';

type IProps = {
  section: ISection;
  handleAllSelectionChanged: (checked: boolean) => void;
  handleBulkOperationCheckAsAnsweredClick: () => Promise<void>;
  handleBulkOperationCheckAsNotAnsweredClick: () => Promise<void>;
  handleBulkOperationDeleteQuestionsClick: () => Promise<void>;
  allSelectionChecked: boolean;
};

export default class BulkControl extends React.Component<IProps, any> {
  handleAllSelectionChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    this.props.handleAllSelectionChanged(event.target.checked);
  };

  handleBulkOperationCheckAsAnsweredClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    await this.props.handleBulkOperationCheckAsAnsweredClick();
  };

  handleBulkOperationCheckAsNotAnsweredClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    await this.props.handleBulkOperationCheckAsNotAnsweredClick();
  };

  handleBulkOperationDeleteQuestionsClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    await this.props.handleBulkOperationDeleteQuestionsClick();
  };

  render(): React.ReactNode {
    return (
      <React.Fragment>
        <div className={styles.bulk_control}>
          <input
            type='checkbox'
            className={`uk-checkbox`}
            checked={this.props.allSelectionChecked}
            onChange={this.handleAllSelectionChange}
          />
          <div id='bulk-control-toggle'>一括操作</div>
        </div>
        <div
          uk-dropdown='mode: click; pos: bottom-left; animation: false; flip: false; delay-hide: 0; toggle: #bulk-control-toggle;'
          className='dropdown-pos-30'
        >
          <div className={styles.bulk_control_menu_container}>
            <ul>
              <li>
                <div
                  onClick={
                    this.props.section.questionsFilter === 'not_answered_only'
                      ? this.handleBulkOperationCheckAsAnsweredClick
                      : this.handleBulkOperationCheckAsNotAnsweredClick
                  }
                >
                  {this.props.section.questionsFilter === 'not_answered_only' ? '回答済みにする' : '投稿に戻す'}
                </div>
              </li>
              <li>
                <div onClick={this.handleBulkOperationDeleteQuestionsClick}>削除する</div>
              </li>
            </ul>
          </div>
        </div>
      </React.Fragment>
    );
  }
}
