import React, { Component } from 'react';
import { IExportTarget, ISpace } from '../types';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import styles from './export-raw-data.module.scss';

type IProps = {
  space: ISpace;
  exportTarget: IExportTarget;
};

type IState = {
  loading: boolean;
};

class ExportRawData extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleExportButtonClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const exportData = this.context.functions.httpsCallable('exportData');
    const result = await exportData({
      spaceId: this.props.space.id,
      sectionId: this.props.exportTarget.section!.id,
      questionId: this.props.exportTarget.question?.id,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      const blob = new Blob([result.data.csv], { type: 'text/csv' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      if (this.props.exportTarget.type === 'section') {
        link.download = `${this.props.exportTarget.section!.name}.csv`;
      } else {
        link.download = `${this.props.exportTarget.question!.text}.csv`;
      }
      link.click();
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return <div className={styles.export_button} onClick={this.handleExportButtonClick} />;
    }
  }
}

export default ExportRawData;
