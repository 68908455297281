import React, { Component } from 'react';
import FirebaseContext from '../firebase_context';
import { ChartType, IAnswer, IQuestion, ISection, ISpace } from '../types';
import { cloneQuestion, nl2br } from '../utils';
import { Chart } from 'react-google-charts';
import UIkit from 'uikit';
import DelayedTask from './delayed-task';
import styles from './abstract-survey-question.module.scss';
import CounterBadge from './counter-badge';
import EditSurveyQuestionForm from './edit-survey-question-form';

export type IProps = {
  space: ISpace;
  section: ISection;
  question: IQuestion;
  answers: IAnswer[];
  forAdmin: boolean;
  fullScreen: boolean;
  nickname?: string;
  onQuestionChange: (question: IQuestion) => void;
  onAnswer?: (question: IQuestion, values: number[], nickname?: string) => void;
  questionOffset: number;
  questionTotalSize: number;
  onQuestionMoveUp?: (question: IQuestion, offset: number) => void;
  onQuestionMoveDown?: (question: IQuestion, offset: number) => void;
  onDelete?: (question: IQuestion) => void;
  offsetInQuestions: number;
};

abstract class AbstractSurveyQuestion extends Component<IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  unsubscribeQuestionChange?: () => void;

  delayedTaskPostAnswer: DelayedTask<number[]>;
  delayedTaskQuestionOpenCloseChange: DelayedTask<boolean>;
  delayedTaskQuestionVisibleResultChange: DelayedTask<boolean>;
  delayedTaskQuestionChartTypeChange: DelayedTask<string>;
  delayedTaskDeleteQuestion: DelayedTask<IQuestion>;

  dropdownMenuRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.delayedTaskPostAnswer = new DelayedTask<number[]>(1000, this.doPostAnswer);
    this.delayedTaskQuestionOpenCloseChange = new DelayedTask<boolean>(1000, this.doQuestionOpenCloseChange);
    this.delayedTaskQuestionVisibleResultChange = new DelayedTask<boolean>(1000, this.doQuestionVisibleResultChange);
    this.delayedTaskQuestionChartTypeChange = new DelayedTask<string>(1000, this.doQuestionChartTypeChange);
    this.delayedTaskDeleteQuestion = new DelayedTask<IQuestion>(1000, this.doDelete);
    this.dropdownMenuRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    // if (this.props.fullScreen) {
    //   return;
    // }
    this.unsubscribeQuestionChange = this.context.db
      .collection('spaces')
      .doc(this.props.space.id)
      .collection('sections')
      .doc(this.props.section.id)
      .collection('questions')
      .doc(this.props.question.id)
      .onSnapshot((documentSnapshot) => {
        const question: IQuestion = {
          id: documentSnapshot.id,
          nickname: documentSnapshot.data()!.nickname,
          items: documentSnapshot.data()!.items,
          open: documentSnapshot.data()!.open,
          consumed: documentSnapshot.data()!.consumed,
          visibleResult: documentSnapshot.data()!.visible_result,
          author: documentSnapshot.data()!.author,
          text: documentSnapshot.data()!.text,
          type: documentSnapshot.data()!.type,
          chartType: documentSnapshot.data()!.chart_type,
          createdAt: documentSnapshot.data()!.created_at.toDate(),
          sequenceNumber: documentSnapshot.data()!.sequence_number,
          selectionLimitCount: documentSnapshot.data()!.selection_limit_count,
          voteQuestionType: documentSnapshot.data()!.vote_question_type || 'question',
          selected: false,
        };
        this.props.onQuestionChange(question);
      });
  }

  componentWillUnmount(): void {
    this.unsubscribeQuestionChange && this.unsubscribeQuestionChange();
  }

  doPostAnswer = async (values: number[]): Promise<void> => {
    const postAnswer = this.context.functions.httpsCallable('postAnswer');
    const result = await postAnswer({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      values,
      nickname: this.props.nickname,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  doQuestionOpenCloseChange = async (open: boolean): Promise<void> => {
    const openQuestion = this.context.functions.httpsCallable('openQuestion');
    const result = await openQuestion({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      open,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  doQuestionVisibleResultChange = async (visibleResult: boolean): Promise<void> => {
    const changeQuestionVisibleResult = this.context.functions.httpsCallable('changeQuestionVisibleResult');
    const result = await changeQuestionVisibleResult({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      visibleResult,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  doQuestionChartTypeChange = async (chartType: string): Promise<void> => {
    const changeQuestionChartType = this.context.functions.httpsCallable('changeQuestionChartType');
    const result = await changeQuestionChartType({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      chartType,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  doDelete = async (value: IQuestion): Promise<void> => {
    const deleteQuestion = this.context.functions.httpsCallable('deleteQuestion');
    const result = await deleteQuestion({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: value.id,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  getMyAnswer(): IAnswer | undefined {
    return this.props.answers.find((answer) => answer.uid === this.context.currentUser()!.uid);
  }

  abstract createAnswerMap(): { [p: number]: number };

  abstract renderItems(): React.ReactNode;

  handleQuestionOpenCloseChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const open = event.target.value === 'open';
    const newQuestion = cloneQuestion(this.props.question);
    newQuestion.open = open;
    this.props.onQuestionChange(newQuestion);
    this.delayedTaskQuestionOpenCloseChange.waitAndGo(open);
  };

  handleQuestionVisibleResultChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const visibleResult = event.target.value === 'visible';
    const newQuestion = cloneQuestion(this.props.question);
    newQuestion.visibleResult = visibleResult;
    this.props.onQuestionChange(newQuestion);
    this.delayedTaskQuestionVisibleResultChange.waitAndGo(visibleResult);
  };

  handleQuestionChartTypeChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    const chartType: ChartType = event.target.value as ChartType;
    const newQuestion = cloneQuestion(this.props.question);
    newQuestion.chartType = chartType;
    this.props.onQuestionChange(newQuestion);
    this.delayedTaskQuestionChartTypeChange.waitAndGo(chartType);
  };

  handleMoveUpMenuItemClick = async (event: React.MouseEvent): Promise<void> => {
    UIkit.dropdown(this.dropdownMenuRef.current!).hide();
    if (this.props.onQuestionMoveUp) {
      this.props.onQuestionMoveUp(this.props.question, this.props.questionOffset);
    }
  };

  handleMoveDownMenuItemClick = async (event: React.MouseEvent): Promise<void> => {
    UIkit.dropdown(this.dropdownMenuRef.current!).hide();
    if (this.props.onQuestionMoveDown) {
      this.props.onQuestionMoveDown(this.props.question, this.props.questionOffset);
    }
  };

  handleDeleteQuestionClick = async (event: React.MouseEvent): Promise<void> => {
    UIkit.dropdown(this.dropdownMenuRef.current!).hide();
    try {
      await UIkit.modal.confirm('このアンケート項目を削除します。');
    } catch (reason) {
      return;
    }
    this.context.logEvent('delete_question_confirm_dialog', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'ok',
    });
    if (this.props.onDelete) {
      this.props.onDelete(this.props.question);
    }
    this.delayedTaskDeleteQuestion.waitAndGo(this.props.question);
  };

  handleEditQuestionMenuClick = (): void => {
    UIkit.dropdown(this.dropdownMenuRef.current!).hide();
  };

  renderQuestionOpenCloseBadge(): React.ReactNode {
    const classNames = [styles.survey_question_badge];
    if (this.props.question.open) {
      classNames.push(styles.survey_question_badge_open);
      return <div className={classNames.join(' ')}>受付中</div>;
    } else {
      classNames.push(styles.survey_question_badge_close);
      return <div className={classNames.join(' ')}>受付終了</div>;
    }
  }

  renderFooter(): React.ReactNode {
    return (
      <>
        <div className={styles.survey_question_for_admin_footer_option}>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={this.props.question.open}
              onChange={this.handleQuestionOpenCloseChange}
              value='open'
            />
            <span>回答受付中</span>
          </label>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={!this.props.question.open}
              onChange={this.handleQuestionOpenCloseChange}
              value='close'
            />
            <span>受付終了</span>
          </label>
        </div>
        <div className={styles.survey_question_for_admin_footer_option}>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={this.props.question.visibleResult}
              onChange={this.handleQuestionVisibleResultChange}
              value='visible'
            />
            <span>結果を見せる</span>
          </label>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={!this.props.question.visibleResult}
              onChange={this.handleQuestionVisibleResultChange}
              value='invisible'
            />
            <span>結果を見せない</span>
          </label>
        </div>
        <div className={styles.survey_question_for_admin_footer_option}>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={this.props.question.chartType === 'bar'}
              onChange={this.handleQuestionChartTypeChange}
              value='bar'
            />
            <span>棒グラフ</span>
          </label>
          <label>
            <input
              className='uk-radio'
              type='radio'
              checked={this.props.question.chartType === 'pie'}
              onChange={this.handleQuestionChartTypeChange}
              value='pie'
            />
            <span>円グラフ</span>
          </label>
        </div>
      </>
    );
  }

  renderChart(): React.ReactNode {
    const answerMap: { [p: number]: number } = this.createAnswerMap();
    const graphData = this.props.question.items
      .map((item, index) => {
        return [item.substring(0, 14), answerMap[index] || 0];
      })
      .sort((a: (string | number)[], b: (string | number)[]) => {
        return Number(b[1]) - Number(a[1]);
      })
      .filter((x: (string | number)[]) => x[1] > 0);
    if (graphData.length === 0) {
      return <div className={styles.survey_question_for_admin_chart_no_data}>回答がまだありません。</div>;
    }
    const chartType = this.props.question.chartType || 'bar';
    if (chartType === 'bar') {
      return (
        <Chart
          chartType='BarChart'
          data={[['Item', 'Count'], ...graphData.slice(0, 10)]}
          width='100%'
          height='296px'
          legendToggle={true}
          options={{
            legend: { position: 'none' },
            chartArea: {
              width: '50%',
              height: '80%',
            },
            hAxis: {
              minValue: 0,
            },
          }}
        />
      );
    } else {
      // 'pie'
      return (
        <Chart
          chartType='PieChart'
          data={[['Item', 'Count'], ...graphData]}
          width='100%'
          height='296px'
          legendToggle={true}
          options={{
            legend: { position: 'right' },
            chartArea: {
              width: '80%',
              height: '80%',
            },
          }}
        />
      );
    }
  }

  isRenderChart(): boolean {
    console.log(`fullscreen=${this.props.fullScreen} forAdmin=${this.props.forAdmin}`);
    if (this.props.fullScreen || !this.props.forAdmin) {
      return this.props.question.visibleResult;
    } else {
      return true;
    }
  }

  renderChartForAdmin(): React.ReactNode {
    if (this.props.fullScreen && !this.props.question.visibleResult) {
      return null;
    } else {
      return (
        <div className={styles.survey_question_for_admin_chart_wrapper}>
          <div className={styles.survey_question_for_admin_chart}>{this.renderChart()}</div>
        </div>
      );
    }
  }

  renderChartForAttendee(): React.ReactNode {
    if (this.props.question.visibleResult) {
      return (
        <div className={styles.survey_question_content_chart_wrapper}>
          <div className={styles.survey_question_content_chart}>{this.renderChart()}</div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderMoveUpMenuItem(): React.ReactNode {
    if (this.props.questionTotalSize > 1 && 0 < this.props.questionOffset) {
      return <li onClick={this.handleMoveUpMenuItemClick}>▲ 1つ上に移動</li>;
    } else {
      return null;
    }
  }

  renderMoveDownMenuItem(): React.ReactNode {
    if (this.props.questionTotalSize > 1 && this.props.questionOffset < this.props.questionTotalSize - 1) {
      return <li onClick={this.handleMoveDownMenuItemClick}>▼ 1つ下に移動</li>;
    } else {
      return null;
    }
  }

  renderDropdownMenu(): React.ReactNode {
    if (!this.props.fullScreen) {
      return (
        <div className={`uk-inline ${styles.survey_question_for_admin_menu}`}>
          <div className={styles.survey_question_for_admin_menu_icon} />
          <div
            uk-dropdown='mode: click; pos: bottom-right; animation: false; flip: false; delay-hide: 0'
            className='dropdown-pos-90'
            ref={this.dropdownMenuRef}
          >
            <div className={styles.survey_question_for_admin_menu_container}>
              <ul>
                {this.renderMoveUpMenuItem()}
                {this.renderMoveDownMenuItem()}
                <EditSurveyQuestionForm
                  space={this.props.space}
                  section={this.props.section}
                  question={this.props.question}
                  onEditQuestionMenuClick={this.handleEditQuestionMenuClick}
                  offsetInQuestions={this.props.offsetInQuestions}
                />
                <li>
                  <div
                    className={styles.survey_question_for_admin_menu_delete}
                    onClick={this.handleDeleteQuestionClick}
                  >
                    削除する
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }

  renderSelectionLimitCount(): React.ReactNode {
    return null;
  }

  render(): React.ReactNode {
    if (this.props.forAdmin) {
      return (
        <div key={this.props.question.id} className={styles.survey_question_for_admin}>
          <div className={styles.survey_question_for_admin_top}>
            <div className={styles.survey_question_for_admin_answer_count}>
              <CounterBadge count={this.props.answers.length} label='回答数' color='primary' />
            </div>
            {this.renderDropdownMenu()}
          </div>
          <div className={styles.survey_question_for_admin_header}>
            {this.renderQuestionOpenCloseBadge()}
            <div className={styles.survey_question_for_admin_header_text}>
              {nl2br(this.props.question.text)}
              {this.renderSelectionLimitCount()}
            </div>
          </div>
          {this.renderItems()}
          {this.renderChartForAdmin()}
          <div className={styles.survey_question_for_admin_footer}>{this.renderFooter()}</div>
        </div>
      );
    } else {
      const containerClasses = [styles.survey_question_content];
      if (this.getMyAnswer()) {
        containerClasses.push(styles.survey_question_content_answered);
      }
      return (
        <div key={this.props.question.id} className={styles.survey_question}>
          <div className={styles.survey_question_answer_count}>
            <CounterBadge count={this.props.answers.length} label='回答数' color='primary' />
          </div>
          <div className={styles.survey_question_header}>
            {this.renderQuestionOpenCloseBadge()}
            <div className={styles.survey_question_header_text}>
              {nl2br(this.props.question.text)}
              {this.renderSelectionLimitCount()}
            </div>
          </div>
          <div className={`${containerClasses.join(' ')}`}>
            <div className={styles.survey_question_content_items}>{this.renderItems()}</div>
            {this.renderChartForAttendee()}
          </div>
        </div>
      );
    }
  }
}

export default AbstractSurveyQuestion;
