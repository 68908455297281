import React, { Component } from 'react';
import styles from './vote-question-consume-toggle-button.module.scss';
import FirebaseContext from '../firebase_context';
import DelayedTask from '../common/delayed-task';
import { IQuestion } from '../types';
import UIkit from 'uikit';

type IProps = {
  forAdmin: boolean;
  spaceId: string;
  sectionId: string;
  question: IQuestion;
  onConsumedChange?: (consumed: boolean, question: IQuestion) => void;
};

class VoteQuestionConsumeToggleButton extends Component<IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  delayedTask: DelayedTask<boolean>;

  constructor(props: IProps) {
    super(props);
    this.delayedTask = new DelayedTask<boolean>(1000, this.doChangeConsumed);
  }

  doChangeConsumed = async (consumed: boolean): Promise<void> => {
    const consumeQuestion = this.context.functions.httpsCallable('consumeQuestion');
    const result = await consumeQuestion({
      spaceId: this.props.spaceId,
      sectionId: this.props.sectionId,
      questionId: this.props.question.id,
      consumed,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleClick(event: React.MouseEvent<HTMLDivElement>): void {
    event.stopPropagation();
    const checked = !this.props.question.consumed;
    this.props.onConsumedChange!(checked, this.props.question);
    this.delayedTask.waitAndGo(checked);
  }

  render(): React.ReactNode {
    if (this.props.forAdmin) {
      return (
        <div className={styles.consume_toggle_button} onClick={this.handleClick.bind(this)}>
          {this.props.question.consumed ? (
            <span className={styles.consume_toggle_button_back}>投稿に戻す</span>
          ) : (
            <span className={styles.consume_toggle_button_consume}>回答済みへ</span>
          )}
        </div>
      );
    } else {
      return null;
    }
  }
}

export default VoteQuestionConsumeToggleButton;
