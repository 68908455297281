import React from 'react';
import { withRouter } from 'react-router-dom';
import Firebase from 'firebase';
import * as queryString from 'query-string';
import UIkit from 'uikit';
import SpaceInfo from './space-info';
import VoteQuestionForm from '../common/vote-question-form';
import { cloneSection, renderSectionType, sortQuestions } from '../utils';
import { ERROR_PASSWORD_NOT_MATCH, ERROR_SPACE_NOT_FOUND } from '../errors';
import {
  IAnswer,
  IJoinedSpaces,
  IQuestion,
  ISection,
  ISpace,
  QuestionsFilter,
  QuestionsSortOrder,
  UserStatistics,
} from '../types';
import FirebaseContext from '../firebase_context';
import VoteQuestion from '../common/vote-question';
import SingleAnswerQuestion from '../common/single-answer-question';
import MultipleAnswersQuestion from '../common/multiple-answers-question';
import AbstractAttendeePage, { IAttendeePageState } from './abstract-attendee-page';
import styles from './space.module.scss';
import InProgress from '../common/in-progress';
import QuestionsControlButtons from '../common/questions-control-buttons';
import DelayedTask from '../common/delayed-task';
import Footer from '../common/footer';
import CounterBadge from '../common/counter-badge';

interface IState extends IAttendeePageState {
  authenticating: boolean;
  space?: ISpace;
  sections: ISection[];
  questions: IQuestion[];
  answers: IAnswer[];
  currentSection?: ISection;
  joinedSpaces?: IJoinedSpaces;
  postedQuestions: IPostedQuestion[];
}

interface IParams {
  spaceId: string;
}

interface IPostedQuestion {
  space: ISpace;
  section: ISection;
  question: IQuestion;
}

class Space extends AbstractAttendeePage<IParams, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private postQuestionDelayedTasks: DelayedTask<IPostedQuestion>[];

  unsubscribeAnswersChange?: () => void;
  unsubscribeQuestionsChange?: () => void;
  unsubscribeSpaceChange?: () => void;
  unsubscribeSectionChange?: () => void;
  unsubscribeSectionsChange?: () => void;
  unsubscribeJoinedSpacesChange?: () => void;

  userStatisticsIntervalId?: any;

  constructor(props: any) {
    super(props);
    this.state = {
      signedIn: false,
      authenticating: true,
      sections: [],
      questions: [],
      answers: [],
      postedQuestions: [],
    };
    this.postQuestionDelayedTasks = [];
  }

  componentDidMount(): void {
    super.componentDidMount();
    this.userStatisticsIntervalId = setInterval(async () => {
      await this.handleUserStatisticsInterval();
    }, 60 * 1000);
  }

  componentWillUnmount(): void {
    this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
    this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
    this.unsubscribeSpaceChange && this.unsubscribeSpaceChange();
    this.unsubscribeSectionChange && this.unsubscribeSectionChange();
    this.unsubscribeSectionsChange && this.unsubscribeSectionsChange();
    this.unsubscribeJoinedSpacesChange && this.unsubscribeJoinedSpacesChange();
    clearInterval(this.userStatisticsIntervalId);
    super.componentWillUnmount();
  }

  didSignIn(): void {
    this.context.logEvent('event', 'open', {
      event_id: this.props.match.params.spaceId,
    });
    this.checkOrJoinToSpace()
      .then((spaceId) => {
        this.setState({
          authenticating: false,
        });
        this.subscribeJoinedSpacesChange();
        this.subscribeSpaceChange(spaceId);
        this.subscribeSectionsChange(spaceId);
      })
      .catch((result) => {
        if (result.errorCode === ERROR_PASSWORD_NOT_MATCH) {
          const spaceId = encodeURIComponent(this.props.match.params.spaceId);
          this.props.history.push(`/e/${spaceId}/entrance`);
        } else {
          UIkit.modal.alert(`Error: ${result.errorMessage}`).then(() => {
            this.props.history.push('/');
          });
        }
      });
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IState>, snapshot?: any): void {
    if (this.state.space) {
      if (prevState.space) {
        if (prevState.space.currentSection !== this.state.space.currentSection) {
          if (prevState.space.currentSection) {
            this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
            this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
            this.unsubscribeSectionChange && this.unsubscribeSectionChange();
            this.setState({
              questions: [],
              answers: [],
              currentSection: undefined,
            });
          }
          if (this.state.space.currentSection) {
            this.setState({
              questions: [],
              answers: [],
              currentSection: undefined,
            });
            this.subscribeSectionChange(this.state.space.id, this.state.space.currentSection);
            this.subscribeQuestionsChange(this.state.space.id, this.state.space.currentSection);
            this.subscribeAnswersChange(this.state.space.id, this.state.space.currentSection);
          }
        }
      } else if (this.state.space.currentSection) {
        this.subscribeSectionChange(this.state.space.id, this.state.space.currentSection);
        this.subscribeQuestionsChange(this.state.space.id, this.state.space.currentSection);
        this.subscribeAnswersChange(this.state.space.id, this.state.space.currentSection);
      } else {
        this.setState({
          questions: [],
          answers: [],
          currentSection: undefined,
        });
      }
    }
  }

  checkOrJoinToSpace(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uid = this.context.currentUser()!.uid;
      this.context.db
        .collection('joined_spaces')
        .doc(uid)
        .get()
        .then((documentSnapshot: Firebase.firestore.DocumentSnapshot) => {
          const customSpaceId = decodeURIComponent(this.props.match.params.spaceId);
          this.context.db
            .collection('custom_space_id_map')
            .doc(customSpaceId)
            .get()
            .then((customSpaceIdSnapshot: Firebase.firestore.DocumentSnapshot) => {
              if (customSpaceIdSnapshot.exists) {
                const spaceId = customSpaceIdSnapshot.data()!.space_id;
                if (documentSnapshot.exists && Object.keys(documentSnapshot.data()!.spaces).includes(spaceId)) {
                  resolve(spaceId);
                } else {
                  const password = queryString.parse(this.props.location.search).p;
                  const joinToSpace = this.context.functions.httpsCallable('joinToSpace');
                  joinToSpace({
                    spaceId: spaceId,
                    spacePassword: password,
                    passwordType: 'encrypted',
                  }).then((result) => {
                    if (result.data.success) {
                      resolve(spaceId);
                    } else {
                      reject(result.data);
                    }
                  });
                }
              } else {
                reject({
                  success: false,
                  errorCode: ERROR_SPACE_NOT_FOUND,
                  errorMessage: `${customSpaceId} のイベントはありません。`,
                });
              }
            });
        });
    });
  }

  subscribeAnswersChange(spaceId: string, sectionId: string): void {
    this.unsubscribeAnswersChange = this.context.db
      .collectionGroup('answers')
      .where('spaceId', '==', spaceId)
      .where('sectionId', '==', sectionId)
      .onSnapshot((answersSnapshot) => {
        let newAnswers = [...this.state.answers];
        answersSnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const uid = change.doc.data().uid;
            const questionId = change.doc.data().questionId;
            newAnswers = newAnswers.filter((answer) => {
              return !(answer.id === undefined && answer.questionId === questionId && answer.uid === uid);
            });
            newAnswers.push({
              id: change.doc.id,
              uid: change.doc.data().uid,
              spaceId: change.doc.data().spaceId,
              sectionId: change.doc.data().sectionId,
              questionId: change.doc.data().questionId,
              values: change.doc.data().values,
              nickname: change.doc.data().nickname,
            });
          } else if (change.type === 'modified') {
            const answer = newAnswers.find((answer) => answer.id === change.doc.id);
            if (answer) {
              answer.values = change.doc.data().values;
            }
          } else if (change.type === 'removed') {
            newAnswers = newAnswers.filter((answer) => answer.id !== change.doc.id);
          }
        });
        this.setState({
          answers: newAnswers,
        });
      });
  }

  findSamePostedQuestion(text: string, author: string, type: string): IPostedQuestion | undefined {
    return this.state.postedQuestions.find((pq) => {
      const question = pq.question;
      return question.author === author && question.text === text && question.type === type;
    });
  }

  subscribeQuestionsChange(spaceId: string, sectionId: string): void {
    this.unsubscribeQuestionsChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .collection('sections')
      .doc(sectionId)
      .collection('questions')
      .onSnapshot((questionsSnapshot) => {
        let newQuestions = [...this.state.questions];
        questionsSnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            const postedQuestion = this.findSamePostedQuestion(
              change.doc.data().text,
              change.doc.data().author,
              change.doc.data().type
            );
            if (postedQuestion) {
              const question = postedQuestion.question;
              question.id = change.doc.id;
              question.open = change.doc.data().open;
              question.consumed = change.doc.data().consumed;
              question.visibleResult = change.doc.data().visible_result;
              question.items = change.doc.data().items;
              question.nickname = change.doc.data().nickname;
              question.chartType = change.doc.data().chart_type;
              question.createdAt = change.doc.data().created_at.toDate();
              question.voteQuestionType = change.doc.data().vote_question_type || 'question';
              question.selected = false;
              newQuestions.push(question);
              const newPostedQuestions = [...this.state.postedQuestions].filter((pq) => {
                return pq !== postedQuestion;
              });
              this.setState({
                postedQuestions: newPostedQuestions,
              });
            } else {
              newQuestions.push({
                id: change.doc.id,
                text: change.doc.data().text,
                author: change.doc.data().author,
                type: change.doc.data().type,
                open: change.doc.data().open,
                consumed: change.doc.data().consumed,
                visibleResult: change.doc.data().visible_result,
                items: change.doc.data().items,
                nickname: change.doc.data().nickname,
                chartType: change.doc.data().chart_type,
                createdAt: change.doc.data().created_at.toDate(),
                sequenceNumber: change.doc.data().sequence_number,
                selectionLimitCount: change.doc.data().selection_limit_count,
                voteQuestionType: change.doc.data().vote_question_type || 'question',
                selected: false,
              });
            }
          } else if (change.type === 'modified') {
            const question = newQuestions.find((question) => question.id === change.doc.id);
            if (question) {
              question.text = change.doc.data().text;
              question.consumed = change.doc.data().consumed;
              question.items = change.doc.data().items;
              question.sequenceNumber = change.doc.data().sequence_number;
              question.open = change.doc.data().open;
              question.visibleResult = change.doc.data().visible_result;
              question.chartType = change.doc.data().chart_type;
              question.selectionLimitCount = change.doc.data().selection_limit_count;
              question.voteQuestionType = change.doc.data().vote_question_type || 'question';
              question.nickname = change.doc.data().nickname;
            }
          } else if (change.type === 'removed') {
            newQuestions = newQuestions.filter((question) => question.id !== change.doc.id);
          }
        });
        this.setState({
          questions: newQuestions,
        });
      });
  }

  subscribeSpaceChange(spaceId: string): void {
    this.unsubscribeSpaceChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .onSnapshot((spaceSnapshot) => {
        if (spaceSnapshot.data()!.deleted) {
          UIkit.modal.alert('イベントが見つかりません。').then(() => {
            window.location.href = '/';
          });
        } else if (!spaceSnapshot.data()!.visible) {
          UIkit.modal.alert('イベントが見つかりません。').then(() => {
            window.location.href = '/';
          });
        } else {
          this.setState({
            space: {
              id: spaceSnapshot.id,
              name: spaceSnapshot.data()!.name,
              currentSection: spaceSnapshot.data()!.current_section,
              customSpaceId: spaceSnapshot.data()!.custom_space_id,
              visible: spaceSnapshot.data()!.visible || true,
              createdAt: spaceSnapshot.data()!.created_at.toDate(),
            },
          });
        }
      });
  }

  subscribeSectionChange(spaceId: string, sectionId: string): void {
    this.unsubscribeSectionChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .collection('sections')
      .doc(sectionId)
      .onSnapshot((sectionSnapshot) => {
        this.setState({
          currentSection: {
            id: sectionSnapshot.id,
            name: sectionSnapshot.data()!.name,
            questionsSortOrder: 'created_at_desc',
            questionsFilter: 'all',
            type: sectionSnapshot.data()!.type,
            open: sectionSnapshot.data()!.open,
            createdAt: sectionSnapshot.data()!.created_at.toDate(),
            sequenceNumber: sectionSnapshot.data()!.sequence_number,
          },
        });
      });
  }

  subscribeSectionsChange(spaceId: string): void {
    this.unsubscribeSectionsChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .collection('sections')
      .onSnapshot((snapshot) => {
        let newSections = [...this.state.sections];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            newSections.push({
              id: change.doc.id,
              name: change.doc.data().name,
              questionsSortOrder: 'created_at_desc',
              questionsFilter: 'all',
              type: change.doc.data().type,
              open: change.doc.data().open,
              createdAt: change.doc.data().created_at.toDate(),
              sequenceNumber: change.doc.data().sequence_number,
            });
          } else if (change.type === 'modified') {
            const section = newSections.find((section) => section.id === change.doc.id);
            if (section) {
              section.name = change.doc.data().name;
              section.createdAt = change.doc.data().created_at.toDate();
              section.open = change.doc.data().open;
              section.type = change.doc.data().type;
              section.sequenceNumber = change.doc.data().sequence_number;
            } else {
              throw new Error(`Section[${change.doc.id}] not found in state.sections`);
            }
          } else if (change.type === 'removed') {
            newSections = newSections.filter((section) => section.id !== change.doc.id);
          }
        });
        this.setState({
          sections: newSections,
        });
      });
  }

  subscribeJoinedSpacesChange(): void {
    this.unsubscribeJoinedSpacesChange = this.context.db
      .collection('joined_spaces')
      .doc(this.context.currentUser()!.uid)
      .onSnapshot((documentSnapshot) => {
        if (documentSnapshot.exists) {
          this.setState({
            joinedSpaces: {
              id: documentSnapshot.id,
              uid: documentSnapshot.data()!.uid,
              spaces: documentSnapshot.data()!.spaces,
              createdAt: documentSnapshot.data()!.created_at,
            },
          });
        } else {
          this.setState({
            joinedSpaces: undefined,
          });
        }
      });
  }

  getMyNickname(): string | undefined {
    if (this.state.joinedSpaces && this.state.space) {
      return this.state.joinedSpaces.spaces[this.state.space.id];
    }
    return undefined;
  }

  async handleUserStatisticsInterval(): Promise<void> {
    if (this.state.space) {
      const userStatistics: UserStatistics = {
        spaceId: this.state.space.id,
        type: 'user_session',
      };
      if (this.state.currentSection) {
        userStatistics.sectionId = this.state.currentSection.id;
      }
      const writeUserStatistics = this.context.functions.httpsCallable('writeUserStatistics');
      await writeUserStatistics(userStatistics);
    }
  }

  handleQuestionChange = (question: IQuestion): void => {
    const newQuestions = [...this.state.questions].filter((value) => value.id !== question.id);
    newQuestions.push(question);
    this.setState({
      questions: newQuestions,
    });
  };

  handleAnswer = (question: IQuestion, values: number[], nickname?: string): void => {
    const newAnswers = [...this.state.answers];
    const answer = newAnswers.find((answer) => {
      return answer.questionId === question.id && answer.uid === this.context.currentUser()!.uid;
    });
    if (answer) {
      answer.values = values;
    } else {
      newAnswers.push({
        spaceId: this.state.space!.id,
        sectionId: this.state.currentSection!.id,
        questionId: question.id!,
        uid: this.context.currentUser()!.uid,
        values,
        nickname: nickname || '',
      });
    }
    this.setState({
      answers: newAnswers,
    });
  };

  handleDeleteQuestion = (question: IQuestion): void => {
    const newQuestions = this.state.questions.filter((q) => {
      return q.id !== question.id;
    });
    this.setState({
      questions: newQuestions,
    });
  };

  handleQuestionsSortOrderListChange = (section: ISection, questionsSortOrder: QuestionsSortOrder): void => {
    this.context.logEvent('event', 'change_dropdown', {
      event_id: this.props.match.params.spaceId,
      section_id: this.state.currentSection!.id,
      target: 'sort_order',
      value: questionsSortOrder,
    });
    const newSections = [...this.state.sections];
    newSections.find((s) => s.id === section.id)!.questionsSortOrder = questionsSortOrder;
    this.setState({
      sections: newSections,
    });
    if (this.state.currentSection && this.state.currentSection.id === section.id) {
      const newCurrentSession = cloneSection(this.state.currentSection);
      newCurrentSession.questionsSortOrder = questionsSortOrder;
      this.setState({
        currentSection: newCurrentSession,
      });
    }
  };

  handleQuestionsFilterChange = (section: ISection, questionsFilter: QuestionsFilter): void => {
    this.context.logEvent('event', 'change_dropdown', {
      event_id: this.props.match.params.spaceId,
      section_id: this.state.currentSection!.id,
      target: 'filter',
      value: questionsFilter,
    });
    const newSections = [...this.state.sections];
    newSections.find((s) => s.id === section.id)!.questionsFilter = questionsFilter;
    this.setState({
      sections: newSections,
    });
    if (this.state.currentSection && this.state.currentSection.id === section.id) {
      const newCurrentSession = cloneSection(this.state.currentSection);
      newCurrentSession.questionsFilter = questionsFilter;
      this.setState({
        currentSection: newCurrentSession,
      });
    }
  };

  handlePostQuestion = async (space: ISpace, section: ISection, question: IQuestion): Promise<void> => {
    const newPostedQuestions: IPostedQuestion[] = [...this.state.postedQuestions];
    const postedQuestion = {
      space,
      section,
      question,
    };
    newPostedQuestions.push(postedQuestion);
    this.setState({
      postedQuestions: newPostedQuestions,
    });
    const delayedTask = new DelayedTask(800, this.doPostQuestion);
    this.postQuestionDelayedTasks.push(delayedTask);
    delayedTask.waitAndGo(postedQuestion);
  };

  doPostQuestion = async (
    postedQuestion: IPostedQuestion,
    delayedTask: DelayedTask<IPostedQuestion>
  ): Promise<void> => {
    const postQuestion = this.context.functions.httpsCallable('postQuestion');
    const space = postedQuestion.space;
    const section = postedQuestion.section;
    const question = postedQuestion.question;
    const result = await postQuestion({
      spaceId: space.id,
      sectionId: section.id,
      text: question.text,
      author: question.author,
      type: question.type,
      nickname: question.nickname,
      voteQuestionType: question.voteQuestionType,
    });
    if (result.data.success) {
      this.postQuestionDelayedTasks = this.postQuestionDelayedTasks.filter((t) => {
        return t !== delayedTask;
      });
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  renderPostedQuestions(): React.ReactNode {
    return this.state.postedQuestions.map((postedQuestion) => {
      const question = postedQuestion.question;
      if (question.type === 'vote') {
        return (
          <VoteQuestion
            key={`posted-question-${new Date().getTime()}`}
            space={postedQuestion.space}
            section={postedQuestion.section}
            question={question}
            answers={[]}
            forAdmin={false}
            nickname={this.getMyNickname()}
            preview={true}
            fullscreen={false}
          />
        );
      } else {
        throw new Error('Not supported question type.');
      }
    });
  }

  renderQuestions(): React.ReactNode {
    if (!this.state.currentSection) {
      return null;
    }
    const currentUser = this.context.currentUser()!;
    const orderedQuestions = sortQuestions(
      this.state.currentSection,
      this.state.answers,
      this.state.questions,
      currentUser.uid
    );
    return orderedQuestions.map((question, index) => {
      const answers = this.state.answers.filter((answer) => answer.questionId === question.id);
      if (question.type === 'vote') {
        return (
          <VoteQuestion
            key={question.id}
            space={this.state.space!}
            section={this.state.currentSection!}
            question={question}
            answers={answers}
            forAdmin={false}
            nickname={this.getMyNickname()}
            onAnswer={this.handleAnswer}
            onDelete={this.handleDeleteQuestion}
            fullscreen={false}
          />
        );
      } else if (question.type === 'single_answer') {
        return (
          <SingleAnswerQuestion
            key={question.id}
            space={this.state.space!}
            section={this.state.currentSection!}
            question={question}
            answers={answers}
            forAdmin={false}
            onQuestionChange={this.handleQuestionChange}
            onAnswer={this.handleAnswer}
            fullScreen={false}
            nickname={this.getMyNickname()}
            questionOffset={index}
            questionTotalSize={orderedQuestions.length}
            offsetInQuestions={index}
          />
        );
      } else {
        // 'multi_answers'
        return (
          <MultipleAnswersQuestion
            key={question.id}
            space={this.state.space!}
            section={this.state.currentSection!}
            question={question}
            answers={answers}
            forAdmin={false}
            onQuestionChange={this.handleQuestionChange}
            onAnswer={this.handleAnswer}
            fullScreen={false}
            nickname={this.getMyNickname()}
            questionOffset={index}
            questionTotalSize={orderedQuestions.length}
            offsetInQuestions={index}
          />
        );
      }
    });
  }

  renderCurrentSectionName(): React.ReactNode {
    const sectionName = this.state.currentSection ? this.state.currentSection.name : '';
    return <div className={styles.current_section_row}>{sectionName}</div>;
  }

  renderVoteQuestionForm(): React.ReactNode {
    if (
      this.state.space &&
      this.state.currentSection &&
      this.state.currentSection.type === 'q_and_a' &&
      this.state.currentSection.open
    ) {
      return (
        <VoteQuestionForm
          space={this.state.space}
          currentSection={this.state.currentSection}
          nickname={this.getMyNickname()}
          questions={this.state.questions}
          answers={this.state.answers}
          onAnswer={this.handleAnswer}
          onPostQuestion={this.handlePostQuestion}
        />
      );
    } else {
      return null;
    }
  }

  renderPostedQuestionCount(): React.ReactNode {
    if (this.state.space && this.state.currentSection && this.state.currentSection.type === 'q_and_a') {
      return (
        <div className={styles.section_detail_info_counter}>
          <CounterBadge count={this.state.questions.length} label='投稿数' color='primary' />
        </div>
      );
    } else {
      return null;
    }
  }

  renderQuestionsControlButtons(): React.ReactNode {
    if (this.state.currentSection && this.state.currentSection.type === 'q_and_a') {
      return (
        <div className={styles.section_controls}>
          <QuestionsControlButtons
            space={this.state.space}
            section={this.state.currentSection}
            forFullScreen={false}
            onQuestionsSortOrderListChange={this.handleQuestionsSortOrderListChange}
            onQuestionsFilterChange={this.handleQuestionsFilterChange}
            supportRandomSort={false}
            forAdmin={false}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  renderSectionDetailHeader(): React.ReactNode {
    if (!this.state.currentSection) {
      return null;
    }
    const headerClassName =
      this.state.currentSection.type === 'q_and_a'
        ? styles.section_detail_header_q_and_a
        : styles.section_detail_header_survey;
    return (
      <div className={`${styles.section_detail_header} ${headerClassName}`}>
        <div className={styles.section_detail_header_wrapper}>
          <div className={styles.section_detail_info}>
            <div className={styles.section_name}>{renderSectionType(this.state.currentSection)}</div>
            {this.renderVoteQuestionForm()}
            {this.renderPostedQuestionCount()}
          </div>
          {this.renderQuestionsControlButtons()}
        </div>
      </div>
    );
  }

  renderModrow(): React.ReactNode {
    return <div className={styles.modrow} />;
  }

  renderSectionContent(): React.ReactNode {
    if (this.state.currentSection) {
      const wrapperClassName =
        this.state.currentSection.type === 'q_and_a'
          ? styles.section_detail_wrapper_q_and_a
          : styles.section_detail_wrapper_survey;
      return (
        <div className={`${styles.section_detail_wrapper} ${wrapperClassName}`}>
          <div className={styles.section_detail_container}>
            {this.renderPostedQuestions()}
            {this.renderQuestions()}
            {this.renderModrow()}
            <Footer />
          </div>
        </div>
      );
    } else {
      return (
        <div className={styles.section_detail_wrapper}>
          <div className={styles.section_detail_container}>
            <div className={styles.no_any_sections_container}>
              <div className={styles.no_any_sections_modrow} />
              <div className={styles.no_any_sections_message}>
                まだ始まっていないようです。
                <br />
                <span className={styles.no_any_sections_message_edit_nickname_button} />
                で名前をつけて待っていてください。
              </div>
            </div>
            <Footer />
          </div>
        </div>
      );
    }
  }

  renderImpl(): React.ReactNode {
    if (this.state.authenticating) {
      return <InProgress />;
    } else {
      return (
        <div className={styles.container}>
          <SpaceInfo space={this.state.space} nickname={this.getMyNickname()} />
          {this.renderCurrentSectionName()}
          {this.renderSectionDetailHeader()}
          {this.renderSectionContent()}
        </div>
      );
    }
  }
}

export default withRouter(Space);
