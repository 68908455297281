import React, { Component } from 'react';
import FirebaseContext from '../firebase_context';
import { IExportTarget, ISpace } from '../types';
import JSZip from 'jszip';
import UIkit from 'uikit';

type IProps = {
  space: ISpace;
  exportTargets: IExportTarget[];
};

type IState = {
  loading: boolean;
};

class ExportAllRawData extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleExportAllButtonClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const exportData = this.context.functions.httpsCallable('exportData');
    const result = await Promise.all(
      this.props.exportTargets.map((exportTarget) => {
        return exportData({
          spaceId: this.props.space.id,
          sectionId: exportTarget.section!.id,
          questionId: exportTarget.question?.id,
        });
      })
    );
    const errors = result.filter((v) => !v.data.success);
    if (errors.length > 0) {
      this.setState({
        loading: false,
      });
      await UIkit.modal.alert(`Error: ${errors[0].data.errorMessage}`);
      return;
    }
    const zip = new JSZip();
    this.props.exportTargets.forEach((exportTarget, index) => {
      let filename;
      if (exportTarget.type === 'section') {
        filename = `${exportTarget.section!.name}.csv`;
      } else {
        filename = `${exportTarget.question!.text}.csv`;
      }
      zip.file(filename, result[index].data.csv);
    });
    const blob = await zip.generateAsync({
      type: 'blob',
    });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = `export-all-${new Date().getTime()}.zip`;
    link.click();
    this.setState({
      loading: false,
    });
  };

  render(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className='uk-button uk-button-default' onClick={this.handleExportAllButtonClick}>
          一括エクスポート
        </button>
      );
    }
  }
}

export default ExportAllRawData;
