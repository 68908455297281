import React, { Component } from 'react';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import { Errors, ISpace } from '../types';
import { STRING_MAX_LENGTH_NICKNAME, validateStringLength } from '../validator';
import styles from './change-nickname.module.scss';

type IProps = {
  space: ISpace;
  nickname?: string;
};

type IState = {
  nickname: string;
  loading: boolean;
  errors: Errors;
};

class ChangeNickname extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private modalRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      nickname: '',
      loading: false,
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
  }

  handleNicknameChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const nickname = event.target.value;
    this.setState({
      nickname,
      errors: validateStringLength(nickname, 'nickname', STRING_MAX_LENGTH_NICKNAME, this.state.errors, true),
    });
  };

  handleNicknameClick = (event: React.MouseEvent): void => {
    this.context.logEvent('event', 'click', {
      event_id: this.props.space.customSpaceId,
      target: 'change_nickname',
    });
    this.setState({
      nickname: this.props.nickname || '',
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleChangeNicknameButtonClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    this.context.logEvent('change_nickname_dialog', 'click', {
      event_id: this.props.space.customSpaceId,
      target: 'change',
    });
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const changeNickname = this.context.functions.httpsCallable('changeNickname');
    const result = await changeNickname({
      spaceId: this.props.space!.id,
      nickname: this.state.nickname,
    });
    this.setState({
      loading: false,
    });
    UIkit.modal(this.modalRef.current!).hide();
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringLength(
      this.state.nickname,
      'nickname',
      STRING_MAX_LENGTH_NICKNAME,
      this.state.errors,
      false
    );
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderNicknameChangeButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className='uk-button uk-button-primary' type='button' onClick={this.handleChangeNicknameButtonClick}>
          変更する
        </button>
      );
    }
  }

  renderNicknameModal(): React.ReactNode {
    return (
      <div uk-modal='true' ref={this.modalRef}>
        <div className='uk-modal-dialog'>
          <button className='uk-modal-close-default' type='button' uk-close='true' />
          <div className='uk-modal-body'>
            <h2 className='uk-modal-title'>ニックネームの変更</h2>
            <form className='uk-form-stacked' onSubmit={this.handleFormSubmit}>
              <div className='uk-margin'>
                <label className='uk-form-label' htmlFor='nickname-form-nickname'>
                  ニックネーム
                </label>
                <div className='uk-form-controls'>
                  <input
                    className='uk-input'
                    id='nickname-form-nickname'
                    type='text'
                    placeholder='ニックネームを入力してください。'
                    value={this.state.nickname}
                    onChange={this.handleNicknameChange}
                  />
                  <span className='uk-text-danger'>{this.state.errors.nickname}</span>
                </div>
              </div>
            </form>
          </div>
          <div className='uk-modal-footer uk-text-right'>{this.renderNicknameChangeButton()}</div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <>
        <i className={styles.change_button} onClick={this.handleNicknameClick} />
        {this.renderNicknameModal()}
      </>
    );
  }
}

export default ChangeNickname;
