import React, { ChangeEvent, Component } from 'react';
import FirebaseContext from '../firebase_context';
import { withRouter } from 'react-router-dom';
import { Errors } from '../types';
import {
  STRING_MAX_LENGTH_ADMIN_PASSWORD,
  STRING_MAX_LENGTH_SPACE_ID,
  validateStringLength,
  validateStringRequired,
} from '../validator';
import styles from './join-to-space-for-admin-form.module.scss';

type IState = {
  spaceId: string;
  adminPassword: string;
  errors: Errors;
};

class JoinToSpaceForAdminForm extends Component<any, IState> {
  constructor(props: any) {
    super(props);
    this.state = {
      spaceId: '',
      adminPassword: '',
      errors: {},
    };
  }

  handleSpaceIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spaceId = event.target.value;
    this.setState({
      spaceId,
      errors: validateStringLength(spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, this.state.errors, true),
    });
  };

  handleAdminPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const adminPassword = event.target.value;
    this.setState({
      adminPassword,
      errors: validateStringLength(
        adminPassword,
        'adminPassword',
        STRING_MAX_LENGTH_ADMIN_PASSWORD,
        this.state.errors,
        true
      ),
    });
  };

  handleJoinButtonClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (this.validate()) {
      this.props.history.push(
        `/admin/spaces/${encodeURIComponent(this.state.spaceId)}?p=${encodeURIComponent(this.state.adminPassword)}`
      );
    }
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.spaceId, 'spaceId', errors, false);
    errors = validateStringLength(this.state.spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, errors, false);
    errors = validateStringRequired(this.state.adminPassword, 'adminPassword', errors, false);
    errors = validateStringLength(
      this.state.adminPassword,
      'adminPassword',
      STRING_MAX_LENGTH_ADMIN_PASSWORD,
      errors,
      false
    );
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <label htmlFor='join-to-space-for-admin-form-space-id'>イベントIDを入れてね</label>
        <div className={styles.field_row}>
          <input
            className='uk-input'
            id='join-to-space-for-admin-form-space-id'
            type='text'
            placeholder='イベントID'
            value={this.state.spaceId}
            onChange={this.handleSpaceIdChange}
          />
          <div className={`${styles.warning} uk-text-danger`}>{this.state.errors.spaceId}</div>
        </div>
        <label htmlFor='join-to-space-for-admin-form-space-password'>管理者パスワード</label>
        <div className={styles.field_row}>
          <input
            className='uk-input'
            id='join-to-space-for-admin-form-space-password'
            type='password'
            placeholder='管理者パスワード'
            value={this.state.adminPassword}
            onChange={this.handleAdminPasswordChange}
          />
          <div className={`${styles.warning} uk-text-danger`}>{this.state.errors.adminPassword}</div>
        </div>
        <button className='uk-button uk-button-secondary' type='button' onClick={this.handleJoinButtonClick}>
          このイベントに入る
        </button>
      </div>
    );
  }
}

JoinToSpaceForAdminForm.contextType = FirebaseContext;

export default withRouter(JoinToSpaceForAdminForm);
