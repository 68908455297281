import './index.scss';
import React from 'react';
import App from './app';
import ReactDOM from 'react-dom';

if (process.env.NODE_ENV === 'production') {
  const StackdriverErrorReporter = require('stackdriver-errors-js/dist/stackdriver-errors-concat.min');
  const errorHandler = new StackdriverErrorReporter();
  errorHandler.start({
    key: process.env.REACT_APP_ERROR_REPORTING_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  });
  console.log('Stackdriver Error Reporting started.');
}

const uikit = require('uikit');
const icons = require('uikit/dist/js/uikit-icons');
uikit.use(icons);

ReactDOM.render(<App />, document.getElementById('root'));
