import React, { Component } from 'react';
import styles from './admin-header.module.scss';
import { RouteComponentProps, withRouter } from 'react-router-dom';

class AdminHeader extends Component<RouteComponentProps<any>, any> {
  handleTitleClick = (event: React.MouseEvent): void => {
    this.props.history.push('/');
  };

  render(): React.ReactNode {
    return (
      <div className={styles.header}>
        <div className={styles.title} onClick={this.handleTitleClick} />
      </div>
    );
  }
}

export default withRouter(AdminHeader);
