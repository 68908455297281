import React, { Component } from 'react';
import { ISection, ISpace, QuestionsFilter } from '../types';

type IProps = {
  space?: ISpace;
  currentSection?: ISection;
  onQuestionsFilterChange: (currentSection: ISection, questionsFilter: QuestionsFilter) => void;
  forAdmin: boolean;
};

class QuestionsFilterList extends Component<IProps, any> {
  handleQuestionsFilterChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const questionsFilter: QuestionsFilter = event.target.value as QuestionsFilter;
    this.props.onQuestionsFilterChange(this.props.currentSection!, questionsFilter);
  };

  render(): React.ReactNode {
    return (
      <select value={this.props.currentSection!.questionsFilter} onChange={this.handleQuestionsFilterChange}>
        {this.props.forAdmin ? null : <option value='all'>全て表示</option>}
        <option value='not_answered_only'>未回答のみ表示</option>
        <option value='answered_only'>回答済のみ表示</option>
        {this.props.forAdmin ? null : <option value='own_only'>自分の投稿のみ表示</option>}
      </select>
    );
  }
}

export default QuestionsFilterList;
