import React, { Fragment } from 'react';
import { IAnswer, IQuestion, ISection, ISpace, ISpaceCredential } from './types';

export const nl2br = (source: string): React.ReactNode => {
  return source.split('\n').map((line, key) => {
    if (key === 0) {
      return <Fragment key={key}>{line}</Fragment>;
    } else {
      return (
        <Fragment key={key}>
          <br />
          {line}
        </Fragment>
      );
    }
  });
};

export const renderSectionType = (section: ISection): React.ReactNode => {
  if (section.type === 'q_and_a') {
    return <span className='section-type_label'>投稿</span>;
  } else {
    // 'survey'
    return <span className='section-type_label'>アンケート</span>;
  }
};

export const generateDefaultPassword = (): string => {
  const source = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz!$#';
  return [...Array(16)].map(() => source[Math.floor(Math.random() * 65)]).join('');
};

export const generateDefaultSpaceId = (): string => {
  const source = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789abcdefghijklmnopqrstuvwxyz';
  return [...Array(8)].map(() => source[Math.floor(Math.random() * 62)]).join('');
};

export const cloneQuestion = (question: IQuestion): IQuestion => {
  return {
    id: question.id,
    text: question.text,
    visibleResult: question.visibleResult,
    consumed: question.consumed,
    createdAt: question.createdAt,
    type: question.type,
    author: question.author,
    items: question.items,
    nickname: question.nickname,
    open: question.open,
    chartType: question.chartType,
    sequenceNumber: question.sequenceNumber,
    selectionLimitCount: question.selectionLimitCount,
    voteQuestionType: question.voteQuestionType,
    selected: question.selected,
  };
};

export const cloneSection = (section: ISection): ISection => {
  return {
    id: section.id,
    open: section.open,
    createdAt: section.createdAt,
    name: section.name,
    questionsFilter: section.questionsFilter,
    questionsSortOrder: section.questionsSortOrder,
    type: section.type,
    sequenceNumber: section.sequenceNumber,
  };
};

export const cloneSpace = (space: ISpace): ISpace => {
  return {
    id: space.id,
    customSpaceId: space.customSpaceId,
    name: space.name,
    createdAt: space.createdAt,
    currentSection: space.currentSection,
    visible: space.visible,
  };
};

export const createSpaceUrl = (space?: ISpace, spaceCredential?: ISpaceCredential) => {
  if (!space || !spaceCredential) {
    return '';
  }
  let spaceUrl;
  if (spaceCredential.encryptedSpacePassword) {
    const password = encodeURIComponent(spaceCredential.encryptedSpacePassword);
    spaceUrl = `${origin}/e/${encodeURIComponent(space.customSpaceId)}?p=${password}`;
  } else {
    spaceUrl = `${origin}/e/${encodeURIComponent(space.customSpaceId)}`;
  }
  return spaceUrl;
};

export const sortQuestions = (currentSection: ISection, answers: IAnswer[], questions: IQuestion[], uid: string) => {
  let orderedQuestions: IQuestion[];
  if (currentSection.type === 'q_and_a') {
    const voteCountMap: { [p: string]: number } = answers.reduce<{
      [p: string]: number;
    }>((m, v) => {
      let count = m[v.questionId] || 0;
      count += v.values[0];
      m[v.questionId] = count;
      return m;
    }, {});
    if (currentSection.questionsSortOrder === 'random') {
      orderedQuestions = shuffle(questions);
    } else {
      orderedQuestions = questions.slice().sort((a, b) => {
        if (currentSection.questionsSortOrder === 'vote_asc') {
          const ca = voteCountMap[a.id!] || 0;
          const cb = voteCountMap[b.id!] || 0;
          if (ca !== cb) {
            return ca - cb;
          } else {
            return a.createdAt.getTime() - b.createdAt.getTime();
          }
        } else if (currentSection.questionsSortOrder === 'vote_desc') {
          const ca = voteCountMap[a.id!] || 0;
          const cb = voteCountMap[b.id!] || 0;
          if (ca !== cb) {
            return cb - ca;
          } else {
            return a.createdAt.getTime() - b.createdAt.getTime();
          }
        } else if (currentSection.questionsSortOrder === 'created_at_asc') {
          return a.createdAt.getTime() - b.createdAt.getTime();
        } else {
          // 'created_at_desc'
          return b.createdAt.getTime() - a.createdAt.getTime();
        }
      });
    }
    if (currentSection.questionsFilter === 'not_answered_only') {
      orderedQuestions = orderedQuestions.filter((question) => !question.consumed);
    } else if (currentSection.questionsFilter === 'answered_only') {
      orderedQuestions = orderedQuestions.filter((question) => question.consumed);
    } else if (currentSection.questionsFilter === 'own_only') {
      orderedQuestions = orderedQuestions.filter((question) => question.author === uid);
    }
  } else {
    // 'survey'
    orderedQuestions = questions.slice().sort((a, b) => {
      return a.sequenceNumber! - b.sequenceNumber!;
    });
  }
  return orderedQuestions;
};

const shuffle = (array: any[]): any[] => {
  const newArray = [...array];
  for (let i = newArray.length - 1; i >= 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [newArray[i], newArray[j]] = [newArray[j], newArray[i]];
  }
  return newArray;
};

export const isAndroid = (): boolean => {
  return !!window.navigator.userAgent.match(/Android/);
};

export const isIOS = (): boolean => {
  return !!window.navigator.userAgent.match(/iPhone|iPad|iPod/);
};
