import React, { Component } from 'react';
import { IAnswer, IMoveQuestionOrderDirection, IQuestion, ISection, ISpace } from '../types';
import { sortQuestions } from '../utils';
import VoteQuestion from '../common/vote-question';
import SingleAnswerQuestion from '../common/single-answer-question';
import MultipleAnswersQuestion from '../common/multiple-answers-question';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import DelayedTask from '../common/delayed-task';

type IProps = {
  space?: ISpace;
  section?: ISection;
  answers: IAnswer[];
  questions: IQuestion[];
  onConsumedChange: (consumed: boolean, question: IQuestion) => void;
  onQuestionChange: (question: IQuestion) => void;
  onQuestionDelete: (question: IQuestion) => void;
  onQuestionMoveUp: (question: IQuestion, offset: number) => void;
  onQuestionMoveDown: (question: IQuestion, offset: number) => void;
  onQuestionSelected: (selected: boolean, question: IQuestion) => void;
};

type IMoveTargetQuestion = {
  question: IQuestion;
  offset: number;
  direction: IMoveQuestionOrderDirection;
};

class Questions extends Component<IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  delayedTask: DelayedTask<IMoveTargetQuestion>;

  constructor(props: IProps) {
    super(props);
    this.delayedTask = new DelayedTask<IMoveTargetQuestion>(1000, this.doMoveQuestionOrder);
  }

  doMoveQuestionOrder = async (moveTargetQuestion: IMoveTargetQuestion): Promise<void> => {
    const moveQuestionOrder = this.context.functions.httpsCallable('moveQuestionOrder');
    const result = await moveQuestionOrder({
      spaceId: this.props.space!.id,
      sectionId: this.props.section!.id,
      questionId: moveTargetQuestion.question.id,
      direction: moveTargetQuestion.direction,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleQuestionMoveUp = async (question: IQuestion, offset: number): Promise<void> => {
    this.props.onQuestionMoveUp(question, offset);
    this.delayedTask.waitAndGo({
      question,
      offset,
      direction: 'up',
    });
  };

  handleQuestionMoveDown = async (question: IQuestion, offset: number): Promise<void> => {
    this.props.onQuestionMoveDown(question, offset);
    this.delayedTask.waitAndGo({
      question,
      offset,
      direction: 'down',
    });
  };

  render(): React.ReactNode {
    if (!this.props.section) {
      return null;
    }
    const currentUser = this.context.currentUser()!;
    const orderedQuestions = sortQuestions(
      this.props.section,
      this.props.answers,
      this.props.questions,
      currentUser.uid
    );
    return orderedQuestions.map((question, index) => {
      const answers = this.props.answers.filter((answer: IAnswer) => answer.questionId === question.id);
      if (question.type === 'vote') {
        return (
          <VoteQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onConsumedChange={this.props.onConsumedChange}
            onDelete={this.props.onQuestionDelete}
            fullscreen={false}
            onSelected={this.props.onQuestionSelected}
          />
        );
      } else if (question.type === 'single_answer') {
        return (
          <SingleAnswerQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onQuestionChange={this.props.onQuestionChange}
            fullScreen={false}
            questionOffset={index}
            questionTotalSize={orderedQuestions.length}
            onQuestionMoveUp={this.handleQuestionMoveUp}
            onQuestionMoveDown={this.handleQuestionMoveDown}
            onDelete={this.props.onQuestionDelete}
            offsetInQuestions={index}
          />
        );
      } else {
        // 'multi_answers'
        return (
          <MultipleAnswersQuestion
            key={question.id}
            space={this.props.space!}
            section={this.props.section!}
            question={question}
            answers={answers}
            forAdmin={true}
            onQuestionChange={this.props.onQuestionChange}
            fullScreen={false}
            questionOffset={index}
            questionTotalSize={orderedQuestions.length}
            onQuestionMoveUp={this.handleQuestionMoveUp}
            onQuestionMoveDown={this.handleQuestionMoveDown}
            onDelete={this.props.onQuestionDelete}
            offsetInQuestions={index}
          />
        );
      }
    });
  }
}

export default Questions;
