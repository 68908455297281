import React, { Fragment } from 'react';
import AbstractSurveyQuestion from './abstract-survey-question';
import styles from './single-answer-question.module.scss';
import FirebaseContext from '../firebase_context';

class SingleAnswerQuestion extends AbstractSurveyQuestion {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  handleItemSelectionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const values: number[] = [Number(event.target.value)];
    this.context.logEvent('event', 'change_select', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'single_answer',
      value: values.join(','),
    });
    this.props.onAnswer!(this.props.question, values);
    this.delayedTaskPostAnswer.waitAndGo(values);
  };

  renderItems(): React.ReactNode {
    if (this.props.forAdmin) {
      return null;
    }
    const myValue = this.getMyAnswer()?.values[0];
    const itemFragments = this.props.question.items.map((item, index) => {
      return (
        <Fragment key={index}>
          <label>
            <input
              id={`question-${this.props.offsetInQuestions}-${index}`}
              className='uk-radio'
              type='radio'
              name={`question-${this.props.question.id}`}
              checked={myValue === index}
              onChange={this.handleItemSelectionChange}
              disabled={!this.props.section.open || !this.props.question.open}
              value={index}
            />
            <div className={myValue === index ? styles.single_answer_question_answered : ''}>{item}</div>
          </label>
        </Fragment>
      );
    });
    return <div className={styles.single_answer_question}>{itemFragments}</div>;
  }

  createAnswerMap(): { [p: number]: number } {
    return this.props.answers.reduce<{ [p: number]: number }>((map, answer) => {
      let number = map[answer.values[0]] || 0;
      map[answer.values[0]] = number + 1;
      return map;
    }, {});
  }
}

export default SingleAnswerQuestion;
