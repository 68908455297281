import React, { Component } from 'react';
import lottie from 'lottie-web';
import styles from './in-progress.module.scss';

class InProgress extends Component<any, any> {
  private lottieRef: React.RefObject<HTMLDivElement>;

  constructor(prop: any) {
    super(prop);
    this.lottieRef = React.createRef<HTMLDivElement>();
  }

  componentDidMount(): void {
    lottie.loadAnimation({
      container: this.lottieRef.current!,
      renderer: 'svg',
      loop: true,
      autoplay: true,
      path: process.env.PUBLIC_URL + '/in-progress.json',
      name: 'In Progress',
    });
  }

  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div ref={this.lottieRef} />
      </div>
    );
  }
}

export default InProgress;
