import React, { Component } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import { ISpace } from '../types';

type IProps = {
  space: ISpace;
};

class DeleteSpace extends Component<RouteComponentProps<{}> & IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  handleDeleteSpaceButtonClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    try {
      await UIkit.modal.confirm('<div class="uk-text-danger">このイベントを本当に削除しますか？</div>');
    } catch (reason) {
      return;
    }
    const deleteSpace = this.context.functions.httpsCallable('deleteSpace');
    const result = await deleteSpace({
      spaceId: this.props.space.id,
    });
    if (result.data.success) {
      this.props.history.go(0);
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    return (
      <a href='/#' onClick={async (event: React.MouseEvent) => await this.handleDeleteSpaceButtonClick(event)}>
        <i className='uk-icon-link' uk-icon='trash' />
      </a>
    );
  }
}

export default withRouter(DeleteSpace);
