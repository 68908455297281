import React, { Component } from 'react';
import styles from './footer.module.scss';

class Footer extends Component<any, any> {
  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.sentence}>Copyright &copy; 2023 Tably, Inc. All rights reserved.</div>
        <div className={styles.links}>
          <a href='/cookie.html' target='_blank'>
            クッキーポリシー
          </a>
          <a href='/tos.html' target='_blank'>
            利用規約
          </a>
        </div>
      </div>
    );
  }
}

export default Footer;
