import React, { Component } from 'react';
import { ISection, ISpace, QuestionsFilter, QuestionsSortOrder, ViewMode } from '../types';
import QuestionsSortOrderList from './questions-sort-order-list';
import QuestionsFilterList from './questions-filter-list';
import styles from './questions-control-buttons.module.scss';

type IProps = {
  space?: ISpace;
  section?: ISection;
  forFullScreen: boolean;
  onQuestionsSortOrderListChange: (section: ISection, questionsSortOrder: QuestionsSortOrder) => void;
  onQuestionsFilterChange: (section: ISection, questionsFilter: QuestionsFilter) => void;
  onExitFullscreenButtonClick?: () => void;
  supportRandomSort: boolean;
  forAdmin: boolean;
};

type IState = {
  viewMode: ViewMode;
};

class QuestionsControlButtons extends Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      viewMode: 'list',
    };
  }

  render(): React.ReactNode {
    if (!this.props.section) {
      return null;
    }
    const buttons: React.ReactNode[] = [];
    if (this.props.section.type === 'q_and_a') {
      buttons.push(
        <QuestionsSortOrderList
          key='sort-order-list'
          space={this.props.space}
          currentSection={this.props.section}
          onQuestionsSortOrderListChange={this.props.onQuestionsSortOrderListChange}
          supportRandomSort={this.props.supportRandomSort}
        />
      );
      if (this.props.forFullScreen || !this.props.forAdmin) {
        buttons.push(
          <QuestionsFilterList
            key='filter-list'
            space={this.props.space}
            currentSection={this.props.section}
            onQuestionsFilterChange={this.props.onQuestionsFilterChange}
            forAdmin={this.props.forAdmin}
          />
        );
      }
    }
    if (this.props.forFullScreen) {
      buttons.push(
        <button
          key='exit'
          className={`${styles.exit_fullscreen_button} uk-button`}
          onClick={this.props.onExitFullscreenButtonClick}
        >
          <span>終了</span>
        </button>
      );
    }
    return buttons;
  }
}

export default QuestionsControlButtons;
