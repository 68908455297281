import React from 'react';
import { withRouter } from 'react-router-dom';
import Firebase from 'firebase';
import * as queryString from 'query-string';
import UIkit from 'uikit';
import SectionList from './section-list';
import {
  IAnswer,
  IQuestion,
  ISection,
  ISpace,
  ISpaceCredential,
  ISpaceSlackSetting,
  QuestionsFilter,
  QuestionsSortOrder,
} from '../types';
import { cloneQuestion, cloneSpace, renderSectionType, sortQuestions } from '../utils';
import SurveyQuestionForm from '../common/survey-question-form';
import OpenCloseSection from './open-close-section';
import FirebaseContext from '../firebase_context';
import AbstractAdminPage, { IAdminPageState } from './abstract-admin-page';
import FullScreen from 'react-full-screen';
import fscreen from 'fscreen';
import styles from './admin-space.module.scss';
import AdminSpaceInfo from './admin-space-info';
import SectionListOffCanvas from './section-list-off-canvas';
import InProgress from '../common/in-progress';
import Questions from './questions';
import QuestionsControlButtons from '../common/questions-control-buttons';
import FullScreenQuestions from './full-screen-questions';
import CounterBadge from '../common/counter-badge';
import ToggleSwitch from '../common/toggle-switch';
import DelayedTask from '../common/delayed-task';
import BulkControl from './bulk-control';

interface IState extends IAdminPageState {
  authentication: boolean;
  space?: ISpace;
  sections: ISection[];
  questions: IQuestion[];
  answers: IAnswer[];
  editingSection?: ISection;
  credential?: ISpaceCredential;
  slackSetting?: ISpaceSlackSetting;
  isPresentationModeInFullScreen: boolean;
  isPresentationModeInPage: boolean;
  allSelectionChecked: boolean;
}

interface IParams {
  spaceId: string;
}

class AdminSpace extends AbstractAdminPage<IParams, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  unsubscribeAnswersChange?: () => void;
  unsubscribeQuestionsChange?: () => void;
  unsubscribeSpaceChange?: () => void;
  unsubscribeSectionsChange?: () => void;
  unsubscribeSpaceCredentialChange?: () => void;
  unsubscribeSpaceSlackSetting?: () => void;

  offCanvasRef: React.RefObject<SectionListOffCanvas>;
  fullScreenDropdownMenuRef: React.RefObject<HTMLDivElement>;

  delayedTaskChangeQuestionsConsumed: DelayedTask<IQuestion[]>;
  delayedTaskChangeQuestionsNotConsumed: DelayedTask<IQuestion[]>;
  delayedTaskDeleteQuestions: DelayedTask<IQuestion[]>;

  constructor(props: any) {
    super(props);
    this.state = {
      signingIn: true,
      signedInAsAuthorizedUser: false,
      authentication: true,
      sections: [],
      questions: [],
      answers: [],
      isPresentationModeInFullScreen: false,
      isPresentationModeInPage: false,
      allSelectionChecked: false,
    };
    this.offCanvasRef = React.createRef<SectionListOffCanvas>();
    this.fullScreenDropdownMenuRef = React.createRef<HTMLDivElement>();
    this.delayedTaskChangeQuestionsConsumed = new DelayedTask<IQuestion[]>(1000, this.doChangedQuestionsConsumed);
    this.delayedTaskChangeQuestionsNotConsumed = new DelayedTask<IQuestion[]>(1000, this.doChangedQuestionsNotConsumed);
    this.delayedTaskDeleteQuestions = new DelayedTask<IQuestion[]>(1000, this.doDeleteQuestions);
  }

  didSignIn(): void {
    this.checkOrJoinToSpaceForAdmin()
      .then((spaceId) => {
        this.setState({
          authentication: false,
        });
        this.subscribeSpaceChange(spaceId);
        this.subscribeSpaceCredentialChange(spaceId);
        this.subscribeSpaceSlackSettingChange(spaceId);
        this.subscribeSectionsChange(spaceId);
      })
      .catch((result) => {
        console.log(result);
        UIkit.modal.alert(`Error: ${result}`).then(() => {
          this.props.history.push('/');
        });
      });
  }

  componentWillUnmount(): void {
    this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
    this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
    this.unsubscribeSpaceChange && this.unsubscribeSpaceChange();
    this.unsubscribeSectionsChange && this.unsubscribeSectionsChange();
    this.unsubscribeSpaceCredentialChange && this.unsubscribeSpaceCredentialChange();
    this.unsubscribeSpaceSlackSetting && this.unsubscribeSpaceSlackSetting();
  }

  componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<IState>, snapshot?: any) {
    if (this.state.space) {
      if (prevState.editingSection) {
        if (this.state.editingSection) {
          if (prevState.editingSection.id !== this.state.editingSection.id) {
            this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
            this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
            this.setState({
              questions: [],
              answers: [],
            });
            this.subscribeQuestionsChange(this.state.space.id, this.state.editingSection.id);
            this.subscribeAnswersChange(this.state.space.id, this.state.editingSection.id);
          }
        } else {
          this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
          this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
          this.setState({
            questions: [],
            answers: [],
          });
        }
      } else {
        if (this.state.editingSection) {
          this.unsubscribeQuestionsChange && this.unsubscribeQuestionsChange();
          this.unsubscribeAnswersChange && this.unsubscribeAnswersChange();
          this.setState({
            questions: [],
            answers: [],
          });
          this.subscribeQuestionsChange(this.state.space.id, this.state.editingSection.id);
          this.subscribeAnswersChange(this.state.space.id, this.state.editingSection.id);
        }
      }
    }
  }

  subscribeAnswersChange(spaceId: string, sectionId: string): void {
    this.unsubscribeAnswersChange = this.context.db
      .collectionGroup('answers')
      .where('spaceId', '==', spaceId)
      .where('sectionId', '==', sectionId)
      .onSnapshot((answersSnapshot) => {
        let newAnswers = [...this.state.answers];
        answersSnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            newAnswers.push({
              id: change.doc.id,
              uid: change.doc.data().uid,
              spaceId: change.doc.data().spaceId,
              sectionId: change.doc.data().sectionId,
              questionId: change.doc.data().questionId,
              values: change.doc.data().values,
              nickname: change.doc.data().nickname,
            });
          } else if (change.type === 'modified') {
            const answer = newAnswers.find((answer) => answer.id === change.doc.id);
            if (answer) {
              answer.values = change.doc.data().values;
            }
          } else if (change.type === 'removed') {
            newAnswers = newAnswers.filter((answer) => answer.id !== change.doc.id);
          }
        });
        this.setState({
          answers: newAnswers,
        });
      });
  }

  subscribeQuestionsChange(spaceId: string, sectionId: string): void {
    this.unsubscribeQuestionsChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .collection('sections')
      .doc(sectionId)
      .collection('questions')
      .onSnapshot((questionsSnapshot) => {
        let newQuestions = [...this.state.questions];
        questionsSnapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            newQuestions.push({
              id: change.doc.id,
              text: change.doc.data().text,
              author: change.doc.data().author,
              type: change.doc.data().type,
              open: change.doc.data().open,
              consumed: change.doc.data().consumed,
              visibleResult: change.doc.data().visible_result,
              items: change.doc.data().items,
              nickname: change.doc.data().nickname,
              chartType: change.doc.data().chart_type,
              createdAt: change.doc.data().created_at.toDate(),
              sequenceNumber: change.doc.data().sequence_number,
              selectionLimitCount: change.doc.data().selection_limit_count || 0,
              voteQuestionType: change.doc.data().vote_question_type || 'question',
              selected: false,
            });
          } else if (change.type === 'modified') {
            const question = newQuestions.find((question) => question.id === change.doc.id);
            if (question) {
              question.text = change.doc.data().text;
              question.consumed = change.doc.data().consumed;
              question.open = change.doc.data().open;
              question.visibleResult = change.doc.data().visible_result;
              question.chartType = change.doc.data().chart_type;
              question.sequenceNumber = change.doc.data().sequence_number;
              question.selectionLimitCount = change.doc.data().selection_limit_count || 0;
              question.voteQuestionType = change.doc.data().vote_question_type || 'question';
              question.nickname = change.doc.data().nickname;
            }
          } else if (change.type === 'removed') {
            newQuestions = newQuestions.filter((question) => question.id !== change.doc.id);
          }
        });
        this.setState({
          questions: newQuestions,
        });
      });
  }

  subscribeSpaceChange(spaceId: string): void {
    this.unsubscribeSpaceChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .onSnapshot((spaceSnapshot) => {
        if (!spaceSnapshot.exists || spaceSnapshot.data()!.deleted) {
          // Force reload.
          window.location.href = '/admin';
        } else {
          this.setState({
            space: {
              id: spaceSnapshot.id,
              name: spaceSnapshot.data()!.name,
              currentSection: spaceSnapshot.data()!.current_section,
              customSpaceId: spaceSnapshot.data()!.custom_space_id,
              visible: spaceSnapshot.data()!.visible,
              createdAt: spaceSnapshot.data()!.created_at.toDate(),
            },
          });
        }
      });
  }

  subscribeSectionsChange(spaceId: string): void {
    this.unsubscribeSectionsChange = this.context.db
      .collection('spaces')
      .doc(spaceId)
      .collection('sections')
      .onSnapshot((snapshot) => {
        let newSections = [...this.state.sections];
        snapshot.docChanges().forEach((change) => {
          if (change.type === 'added') {
            newSections.push({
              id: change.doc.id,
              name: change.doc.data().name,
              questionsSortOrder: 'created_at_desc',
              questionsFilter: 'not_answered_only',
              type: change.doc.data().type,
              open: change.doc.data().open,
              createdAt: change.doc.data().created_at.toDate(),
              sequenceNumber: change.doc.data().sequence_number,
            });
          } else if (change.type === 'modified') {
            const section = newSections.find((section) => section.id === change.doc.id);
            if (section) {
              section.name = change.doc.data().name;
              section.createdAt = change.doc.data().created_at.toDate();
              section.open = change.doc.data().open;
              section.type = change.doc.data().type;
              section.sequenceNumber = change.doc.data().sequence_number;
            } else {
              throw new Error(`Section[${change.doc.id}] not found in state.sections`);
            }
          } else if (change.type === 'removed') {
            newSections = newSections.filter((section) => section.id !== change.doc.id);
          }
        });
        this.setState({
          sections: newSections,
        });
        if (this.state.editingSection) {
          this.setState({
            editingSection: this.state.sections.find((s) => s.id === this.state.editingSection!.id),
          });
        } else if (newSections.length > 0) {
          this.setState({
            editingSection: newSections[0],
          });
        }
      });
  }

  subscribeSpaceCredentialChange(spaceId: string): void {
    this.unsubscribeSpaceCredentialChange = this.context.db
      .collection('space_credentials')
      .doc(spaceId)
      .onSnapshot((snapshot) => {
        this.setState({
          credential: {
            spacePassword: snapshot.data()!.space_password,
            adminPassword: snapshot.data()!.admin_password,
            encryptedSpacePassword: snapshot.data()!.encrypted_space_password,
          },
        });
      });
  }

  subscribeSpaceSlackSettingChange(spaceId: string): void {
    this.unsubscribeSpaceSlackSetting = this.context.db
      .collection('space_slack_settings')
      .doc(spaceId)
      .onSnapshot((snapshot) => {
        this.setState({
          slackSetting: {
            incomingWebhook: snapshot.data()!.incoming_webhook,
            sendMessage: snapshot.data()!.send_message,
          },
        });
      });
  }

  checkOrJoinToSpaceForAdmin(): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const uid = this.context.currentUser()!.uid;
      this.context.db
        .collection('joined_spaces_for_admin')
        .doc(uid)
        .get()
        .then((joinedSpaceForAdminSnapshot: Firebase.firestore.DocumentSnapshot) => {
          const customSpaceId = decodeURIComponent(this.props.match.params.spaceId);
          this.context.db
            .collection('custom_space_id_map')
            .doc(customSpaceId)
            .get()
            .then((customSpaceIdSnapshot: Firebase.firestore.DocumentSnapshot) => {
              if (customSpaceIdSnapshot.exists) {
                const spaceId = customSpaceIdSnapshot.data()!.space_id;
                if (
                  joinedSpaceForAdminSnapshot.exists &&
                  Object.keys(joinedSpaceForAdminSnapshot.data()!.spaces).includes(spaceId)
                ) {
                  resolve(spaceId);
                } else {
                  const password = queryString.parse(this.props.location.search).p;
                  const joinToSpaceForAdmin = this.context.functions.httpsCallable('joinToSpaceForAdmin');
                  joinToSpaceForAdmin({
                    spaceId: spaceId,
                    adminPassword: password,
                  }).then((result) => {
                    if (result.data.success) {
                      resolve(spaceId);
                    } else {
                      reject(result.data.errorMessage);
                    }
                  });
                }
              } else {
                reject(`The space[${customSpaceId}] not found.`);
              }
            });
        });
    });
  }

  doChangedQuestionsConsumed = async (questions: IQuestion[]): Promise<void> => {
    for (const question of questions) {
      const consumeQuestion = this.context.functions.httpsCallable('consumeQuestion');
      const result = await consumeQuestion({
        spaceId: this.state.space!.id,
        sectionId: this.state.editingSection!.id,
        questionId: question.id,
        consumed: true,
      });
      if (!result.data.success) {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    }
  };

  doChangedQuestionsNotConsumed = async (questions: IQuestion[]): Promise<void> => {
    for (const question of questions) {
      const consumeQuestion = this.context.functions.httpsCallable('consumeQuestion');
      const result = await consumeQuestion({
        spaceId: this.state.space!.id,
        sectionId: this.state.editingSection!.id,
        questionId: question.id,
        consumed: false,
      });
      if (!result.data.success) {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    }
  };

  doDeleteQuestions = async (questions: IQuestion[]): Promise<void> => {
    for (const question of questions) {
      const deleteQuestion = this.context.functions.httpsCallable('deleteQuestion');
      const result = await deleteQuestion({
        spaceId: this.state.space!.id,
        sectionId: this.state.editingSection!.id,
        questionId: question.id,
      });
      if (!result.data.success) {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    }
  };

  clearAllQuestionsSelected(): void {
    const newQuestions = [...this.state.questions];
    newQuestions.forEach((question) => (question.selected = false));
    this.setState({ questions: newQuestions, allSelectionChecked: false });
  }

  handleEditSectionChange = (section: ISection): void => {
    this.setState({
      editingSection: section,
    });
  };

  handleQuestionChange = (question: IQuestion): void => {
    const newQuestions = [...this.state.questions].filter((value) => value.id !== question.id);
    newQuestions.push(question);
    this.setState({
      questions: newQuestions,
    });
  };

  handleOpenCloseSectionChange = (section: ISection, open: boolean): void => {
    const newSection: ISection = {
      id: section.id,
      name: section.name,
      type: section.type,
      questionsFilter: section.questionsFilter,
      questionsSortOrder: section.questionsSortOrder,
      createdAt: section.createdAt,
      open,
      sequenceNumber: section.sequenceNumber,
    };
    this.handleEditSectionChange(newSection);
  };

  handlePresentationModeInFullScreenMenuClick = (event: React.MouseEvent): void => {
    this.setState({
      isPresentationModeInFullScreen: true,
    });
  };

  handlePresentationModeInPageMenuClick = (event: React.MouseEvent): void => {
    this.setState({
      isPresentationModeInPage: true,
    });
  };

  handleToggleSectionListClick = (event: React.MouseEvent): void => {
    this.offCanvasRef.current!.toggleOffCanvas();
  };

  handleConsumedChange = (consumed: boolean, question: IQuestion): void => {
    const newQuestions = [...this.state.questions].filter((q) => q.id !== question.id);
    const newQuestion = cloneQuestion(question);
    newQuestion.consumed = consumed;
    newQuestion.selected = false;
    newQuestions.push(newQuestion);
    this.setState({
      questions: newQuestions,
    });
  };

  handleQuestionsSortOrderListChange = (section: ISection, questionsSortOrder: QuestionsSortOrder): void => {
    const newSections = [...this.state.sections];
    newSections.find((s) => s.id === section.id)!.questionsSortOrder = questionsSortOrder;
    this.setState({
      sections: newSections,
    });
  };

  handleQuestionsFilterChange = (section: ISection, questionsFilter: QuestionsFilter): void => {
    const newSections = [...this.state.sections];
    newSections.find((s) => s.id === section.id)!.questionsFilter = questionsFilter;
    this.setState({
      sections: newSections,
    });
    this.clearAllQuestionsSelected();
  };

  handleExitFullscreenButtonClick = (): void => {
    fscreen.exitFullscreen();
  };

  handleExitPresentationModeInPageButtonClick = (): void => {
    this.setState({
      isPresentationModeInPage: false,
    });
  };

  handleQuestionDelete = (question: IQuestion): void => {
    const newQuestions = this.state.questions.filter((q) => {
      return q.id !== question.id;
    });
    this.setState({
      questions: newQuestions,
    });
  };

  handleSpaceVisibleChange = (space: ISpace, visible: boolean): void => {
    const newSpace = cloneSpace(space);
    newSpace.visible = visible;
    this.setState({
      space: newSpace,
    });
  };

  handleToggleSwitchChange = async (checked: boolean): Promise<void> => {
    if (checked) {
      const newSpace = cloneSpace(this.state.space!);
      newSpace.currentSection = this.state.editingSection!.id;
      this.setState({
        space: newSpace,
      });
      const startSection = this.context.functions.httpsCallable('startSection');
      const result = await startSection({
        spaceId: this.state.space!.id,
        sectionId: this.state.editingSection!.id,
      });
      if (!result.data.success) {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    } else {
      const newSpace = cloneSpace(this.state.space!);
      newSpace.currentSection = undefined;
      this.setState({
        space: newSpace,
      });
      const endSection = this.context.functions.httpsCallable('endSection');
      const result = await endSection({
        spaceId: this.state.space!.id,
      });
      if (!result.data.success) {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    }
  };

  handleQuestionMoveUp = async (question: IQuestion, offset: number): Promise<void> => {
    const newQuestions = [...this.state.questions];
    const currentUser = this.context.currentUser()!;
    const sortedNewQuestions = sortQuestions(
      this.state.editingSection!,
      this.state.answers,
      newQuestions,
      currentUser.uid
    );
    const targetQuestionIndex = sortedNewQuestions.findIndex((q) => {
      return q.id === question.id;
    });
    const targetQuestionSequenceNumber = question.sequenceNumber;
    sortedNewQuestions[targetQuestionIndex].sequenceNumber = sortedNewQuestions[targetQuestionIndex - 1].sequenceNumber;
    sortedNewQuestions[targetQuestionIndex - 1].sequenceNumber = targetQuestionSequenceNumber;
    this.setState({
      questions: newQuestions,
    });
  };

  handleQuestionMoveDown = async (question: IQuestion, offset: number): Promise<void> => {
    const newQuestions = [...this.state.questions];
    const currentUser = this.context.currentUser()!;
    const sortedNewQuestions = sortQuestions(
      this.state.editingSection!,
      this.state.answers,
      newQuestions,
      currentUser.uid
    );
    const targetQuestionIndex = sortedNewQuestions.findIndex((q) => {
      return q.id === question.id;
    });
    const targetQuestionSequenceNumber = question.sequenceNumber;
    sortedNewQuestions[targetQuestionIndex].sequenceNumber = sortedNewQuestions[targetQuestionIndex + 1].sequenceNumber;
    sortedNewQuestions[targetQuestionIndex + 1].sequenceNumber = targetQuestionSequenceNumber;
    this.setState({
      questions: newQuestions,
    });
  };

  handleQuestionSelected = async (selected: boolean, question: IQuestion): Promise<void> => {
    const newQuestions = [...this.state.questions].filter((value) => value.id !== question.id);
    question.selected = selected;
    newQuestions.push(question);
    this.setState({
      questions: newQuestions,
    });
  };

  handleSectionOrderChange = (source: ISection, target: ISection): void => {
    // FIXME
    const sourceSequenceNumber = source.sequenceNumber;
    const targetSequenceNumber = target.sequenceNumber;
    source.sequenceNumber = targetSequenceNumber;
    target.sequenceNumber = sourceSequenceNumber;
    const newSections = [...this.state.sections];
    this.setState({
      sections: newSections,
    });
  };

  handleBulkOperationCheckAsAnsweredClick = async (): Promise<void> => {
    try {
      const selectedCount = this.state.questions.filter((question) => question.selected).length;
      await UIkit.modal.confirm(`選択された ${selectedCount} 個の質問を回答済みにしますか？`);
    } catch (reason) {
      return;
    }
    const questions: IQuestion[] = [];
    for (const question of this.state.questions.filter((question) => question.selected)) {
      question.selected = false;
      this.handleConsumedChange(true, question);
      questions.push(question);
    }
    await this.delayedTaskChangeQuestionsConsumed.waitAndGo(questions);
    this.setState({ allSelectionChecked: false });
  };

  handleBulkOperationCheckAsNotAnsweredClick = async (): Promise<void> => {
    try {
      const selectedCount = this.state.questions.filter((question) => question.selected).length;
      await UIkit.modal.confirm(`選択された ${selectedCount} 個の質問を投稿に戻しますか？`);
    } catch (reason) {
      return;
    }
    const questions: IQuestion[] = [];
    for (const question of this.state.questions.filter((question) => question.selected)) {
      question.selected = false;
      this.handleConsumedChange(false, question);
      questions.push(question);
    }
    await this.delayedTaskChangeQuestionsNotConsumed.waitAndGo(questions);
    this.setState({ allSelectionChecked: false });
  };

  handleBulkOperationDeleteQuestionsClick = async (): Promise<void> => {
    try {
      const selectedCount = this.state.questions.filter((question) => question.selected).length;
      await UIkit.modal.confirm(`選択された ${selectedCount} 個の質問を削除しますか？`);
    } catch (reason) {
      return;
    }
    const questions: IQuestion[] = [];
    for (const question of this.state.questions.filter((question) => question.selected)) {
      this.handleQuestionDelete(question);
      questions.push(question);
    }
    await this.delayedTaskDeleteQuestions.waitAndGo(questions);
    this.setState({ allSelectionChecked: false });
  };

  handleAllSelectionChanged = (checked: boolean): void => {
    const newQuestions = [...this.state.questions];
    newQuestions.forEach((question) => {
      question.selected = checked;
    });
    this.setState({
      allSelectionChecked: checked,
      questions: newQuestions,
    });
  };

  renderImpl(): React.ReactNode {
    if (this.state.authentication) {
      return <InProgress />;
    } else if (this.state.isPresentationModeInPage) {
      if (this.state.isPresentationModeInFullScreen) {
        return (
          <FullScreenQuestions
            space={this.state.space}
            section={this.state.editingSection}
            answers={this.state.answers}
            questions={this.state.questions}
            onConsumedChange={this.handleConsumedChange}
            onQuestionChange={this.handleQuestionChange}
            onQuestionsSortOrderListChange={this.handleQuestionsSortOrderListChange}
            onQuestionsFilterChange={this.handleQuestionsFilterChange}
            onExitFullscreenButtonClick={this.handleExitFullscreenButtonClick}
            onQuestionDelete={this.handleQuestionDelete}
          />
        );
      } else if (this.state.isPresentationModeInPage) {
        return (
          <FullScreenQuestions
            space={this.state.space}
            section={this.state.editingSection}
            answers={this.state.answers}
            questions={this.state.questions}
            onConsumedChange={this.handleConsumedChange}
            onQuestionChange={this.handleQuestionChange}
            onQuestionsSortOrderListChange={this.handleQuestionsSortOrderListChange}
            onQuestionsFilterChange={this.handleQuestionsFilterChange}
            onExitFullscreenButtonClick={this.handleExitPresentationModeInPageButtonClick}
            onQuestionDelete={this.handleQuestionDelete}
          />
        );
      } else {
        return <span />;
      }
    } else {
      return (
        <>
          <AdminSpaceInfo
            space={this.state.space}
            spaceCredential={this.state.credential}
            spaceSlackSetting={this.state.slackSetting}
            onToggleSectionListClick={this.handleToggleSectionListClick}
            onSpaceVisibleChange={this.handleSpaceVisibleChange}
          />
          <div className={styles.container}>
            <div className={`${styles.section_list} uk-width-1-3@s uk-visible@s`}>
              <SectionList
                space={this.state.space}
                sections={this.state.sections}
                editSection={this.state.editingSection}
                onEditSectionChange={this.handleEditSectionChange}
                createButtonStyle={{
                  margin: '0 0 0 calc(100% * 1 / 3.001 - 65px)',
                }}
                header={true}
                onSectionOrderChange={this.handleSectionOrderChange}
              />
            </div>
            <Section
              editingSection={this.state.editingSection}
              space={this.state.space}
              answers={this.state.answers}
              questions={this.state.questions}
              isPresentationModeInFullScreen={this.state.isPresentationModeInFullScreen}
              handleQuestionsSortOrderListChange={this.handleQuestionsSortOrderListChange}
              handleQuestionsFilterChange={this.handleQuestionsFilterChange}
              handleConsumedChange={this.handleConsumedChange}
              handleQuestionChange={this.handleQuestionChange}
              handleQuestionDelete={this.handleQuestionDelete}
              handleQuestionMoveUp={this.handleQuestionMoveUp}
              handleQuestionMoveDown={this.handleQuestionMoveDown}
              handleQuestionSelected={this.handleQuestionSelected}
              handleFullscreenChanged={(isFull: boolean) => this.setState({ isPresentationModeInFullScreen: isFull })}
              handleToggleSwitchChange={this.handleToggleSwitchChange}
              fullScreenDropdownMenuRef={this.fullScreenDropdownMenuRef}
              handlePresentationModeInFullScreenMenuClick={this.handlePresentationModeInFullScreenMenuClick}
              handlePresentationModeInPageMenuClick={this.handlePresentationModeInPageMenuClick}
              handleBulkOperationCheckAsAnsweredClick={this.handleBulkOperationCheckAsAnsweredClick}
              handleBulkOperationCheckAsNotAnsweredClick={this.handleBulkOperationCheckAsNotAnsweredClick}
              handleBulkOperationDeleteQuestionsClick={this.handleBulkOperationDeleteQuestionsClick}
              handleOpenCloseSectionChange={this.handleOpenCloseSectionChange}
              handleExitFullscreenButtonClick={this.handleExitFullscreenButtonClick}
              handleExitPresentationModeInPageButtonClick={this.handleExitPresentationModeInPageButtonClick}
              isPresentationModeInPage={this.state.isPresentationModeInPage}
              handleAllSelectionChanged={this.handleAllSelectionChanged}
              allSelectionChecked={this.state.allSelectionChecked}
            />
          </div>
          <SectionListOffCanvas
            ref={this.offCanvasRef}
            space={this.state.space}
            sections={this.state.sections}
            editSection={this.state.editingSection}
            onEditSectionChange={this.handleEditSectionChange}
            onSectionOrderChange={this.handleSectionOrderChange}
          />
        </>
      );
    }
  }
}

export default withRouter(AdminSpace);

type OnairToggleButtonProps = {
  space?: ISpace;
  editingSection: ISection;
  handleToggleSwitchChange: (checked: boolean) => Promise<void>;
};

function OnairToggleButton(props: OnairToggleButtonProps) {
  if (!(props.space && props.editingSection)) {
    return null;
  }
  const onair = props.space.currentSection === props.editingSection.id;
  return (
    <ToggleSwitch
      id={`onair-${props.editingSection.id}`}
      text={['ONAIR', 'OFF']}
      currentValue={onair}
      onChange={props.handleToggleSwitchChange}
    />
  );
}

type PresentationModeButtonProps = {
  isPresentationModeInFullScreen: boolean;
  fullScreenDropdownMenuRef: React.RefObject<HTMLDivElement>;
  handlePresentationModeInFullScreenMenuClick: (event: React.MouseEvent) => void;
  handlePresentationModeInPageMenuClick: (event: React.MouseEvent) => void;
};

function PresentationModeButton(props: PresentationModeButtonProps) {
  if (fscreen.fullscreenEnabled && !props.isPresentationModeInFullScreen) {
    return (
      <div className={`uk-inline ${styles.section_header_presentation_mode_menu}`}>
        <button
          className={`${styles.section_header_presentation_mode_button} uk-button uk-button-primary uk-visible@s`}
        >
          <span>プレゼンモード</span>
        </button>
        <div
          uk-dropdown='mode: click; pos: bottom-right; animation: false; flip: false; delay-hide: 0'
          className='dropdown-pos-90'
          ref={props.fullScreenDropdownMenuRef}
        >
          <div className={styles.section_header_presentation_mode_menu_container}>
            <ul>
              <li>
                <div
                  className={styles.section_header_presentation_mode_menu_in_full_screen}
                  onClick={props.handlePresentationModeInFullScreenMenuClick}
                >
                  全画面で実行
                </div>
              </li>
              <li>
                <div
                  className={styles.section_header_presentation_mode_menu_in_page}
                  onClick={props.handlePresentationModeInPageMenuClick}
                >
                  ページ内で実行
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
}

type OpenCloseListProps = {
  editingSection: ISection;
  space: ISpace;
  handleOpenCloseSectionChange: (section: ISection, open: boolean) => void;
};

function OpenCloseList(props: OpenCloseListProps) {
  if (!props.editingSection) {
    return null;
  }
  return (
    <OpenCloseSection
      space={props.space}
      section={props.editingSection}
      onOpenCloseChange={props.handleOpenCloseSectionChange}
    />
  );
}

type QuestionStatisticsProps = {
  editingSection: ISection;
  questions: IQuestion[];
};

function QuestionStatistics(props: QuestionStatisticsProps) {
  if (props.editingSection!.type === 'q_and_a') {
    const postedQuestionUserCount = Array.from(new Set(props.questions.map((x) => x.author))).length;
    return (
      <div className={styles.section_info_footer_left_counter}>
        <CounterBadge count={props.questions.length} label='投稿数' color='primary' />
        <CounterBadge count={postedQuestionUserCount} label='投稿人数' color='secondary' />
      </div>
    );
  } else {
    return null;
  }
}

type CurrentSectionProps = {
  editingSection: ISection;
  space: ISpace;
  handleOpenCloseSectionChange: (section: ISection, open: boolean) => void;
  questions: IQuestion[];
};

function CurrentSection(props: CurrentSectionProps) {
  if (props.editingSection) {
    return (
      <div className={styles.section_info}>
        <div className={styles.section_info_section_type}>{renderSectionType(props.editingSection)}</div>
        <div className={styles.section_info_name}>{props.editingSection.name}</div>
        <div className={styles.section_info_controls}>
          <OpenCloseList
            editingSection={props.editingSection}
            space={props.space}
            handleOpenCloseSectionChange={props.handleOpenCloseSectionChange}
          />
          <QuestionStatistics editingSection={props.editingSection} questions={props.questions} />
        </div>
      </div>
    );
  }
  return null;
}

type ConsumeTabProps = {
  editingSection: ISection;
  handleQuestionsFilterChange: (section: ISection, filter: QuestionsFilter) => void;
};

function ConsumeTab(props: ConsumeTabProps) {
  const notConsumedClassNames = [styles.consume_tab_item];
  if (props.editingSection.questionsFilter === 'not_answered_only') {
    notConsumedClassNames.push(styles.consume_tab_item_active);
  } else {
    notConsumedClassNames.push(styles.consume_tab_item_inactive);
  }
  const consumedClassNames = [styles.consume_tab_item];
  if (props.editingSection.questionsFilter === 'answered_only') {
    consumedClassNames.push(styles.consume_tab_item_active);
  } else {
    consumedClassNames.push(styles.consume_tab_item_inactive);
  }
  const onClickNotConsumed = () => {
    props.handleQuestionsFilterChange(props.editingSection, 'not_answered_only');
  };
  const onClickConsumed = () => {
    props.handleQuestionsFilterChange(props.editingSection, 'answered_only');
  };
  return (
    <ul className={styles.consume_tab_container}>
      <li
        className={notConsumedClassNames.join(' ')}
        onClick={() => {
          onClickNotConsumed();
        }}
      >
        投稿
      </li>
      <li
        className={consumedClassNames.join(' ')}
        onClick={() => {
          onClickConsumed();
        }}
      >
        回答済み
      </li>
    </ul>
  );
}

type SectionProps = {
  editingSection?: ISection;
  space?: ISpace;
  answers: IAnswer[];
  questions: IQuestion[];
  isPresentationModeInFullScreen: boolean;
  handleQuestionsSortOrderListChange: (section: ISection, questionsSortOrder: QuestionsSortOrder) => void;
  handleQuestionsFilterChange: (section: ISection, questionsFilter: QuestionsFilter) => void;
  handleConsumedChange: (consumed: boolean, question: IQuestion) => void;
  handleQuestionChange: (question: IQuestion) => void;
  handleQuestionDelete: (question: IQuestion) => void;
  handleQuestionMoveUp: (question: IQuestion, offset: number) => Promise<void>;
  handleQuestionMoveDown: (question: IQuestion, offset: number) => Promise<void>;
  handleQuestionSelected: (selected: boolean, question: IQuestion) => Promise<void>;
  handleFullscreenChanged: (isFull: boolean) => void;
  handleToggleSwitchChange: (checked: boolean) => Promise<void>;
  fullScreenDropdownMenuRef: React.RefObject<HTMLDivElement>;
  handlePresentationModeInFullScreenMenuClick: (event: React.MouseEvent) => void;
  handlePresentationModeInPageMenuClick: (event: React.MouseEvent) => void;
  handleBulkOperationCheckAsAnsweredClick: () => Promise<void>;
  handleBulkOperationCheckAsNotAnsweredClick: () => Promise<void>;
  handleBulkOperationDeleteQuestionsClick: () => Promise<void>;
  handleOpenCloseSectionChange: (section: ISection, open: boolean) => void;
  isPresentationModeInPage: boolean;
  handleExitFullscreenButtonClick: () => void;
  handleExitPresentationModeInPageButtonClick: () => void;
  handleAllSelectionChanged: (checked: boolean) => void;
  allSelectionChecked: boolean;
};

function Section(props: SectionProps) {
  if (props.editingSection) {
    return (
      <div className={`uk-width-expand@s ${styles.section_wrapper}`}>
        <div className={styles.section_header}>
          <OnairToggleButton
            space={props.space}
            editingSection={props.editingSection}
            handleToggleSwitchChange={props.handleToggleSwitchChange}
          />
          <PresentationModeButton
            isPresentationModeInFullScreen={props.isPresentationModeInFullScreen}
            fullScreenDropdownMenuRef={props.fullScreenDropdownMenuRef}
            handlePresentationModeInFullScreenMenuClick={props.handlePresentationModeInFullScreenMenuClick}
            handlePresentationModeInPageMenuClick={props.handlePresentationModeInPageMenuClick}
          />
        </div>
        <div className={`${styles.section_detail_container}`}>
          <CurrentSection
            editingSection={props.editingSection}
            space={props.space!}
            questions={props.questions}
            handleOpenCloseSectionChange={props.handleOpenCloseSectionChange}
          />
          {props.editingSection && props.editingSection.type === 'q_and_a' ? (
            <React.Fragment>
              <ConsumeTab
                editingSection={props.editingSection}
                handleQuestionsFilterChange={props.handleQuestionsFilterChange}
              />
              <div className={styles.consume_tab_content}>
                <div className={styles.consume_tab_content_header}>
                  <div className={styles.consume_tab_content_header_bulk_control}>
                    <BulkControl
                      section={props.editingSection!}
                      handleAllSelectionChanged={props.handleAllSelectionChanged}
                      handleBulkOperationCheckAsAnsweredClick={props.handleBulkOperationCheckAsAnsweredClick}
                      handleBulkOperationCheckAsNotAnsweredClick={props.handleBulkOperationCheckAsNotAnsweredClick}
                      allSelectionChecked={props.allSelectionChecked}
                      handleBulkOperationDeleteQuestionsClick={props.handleBulkOperationDeleteQuestionsClick}
                    />
                  </div>
                  <div className={styles.consume_tab_content_header_control_buttons}>
                    <QuestionsControlButtons
                      space={props.space}
                      section={props.editingSection}
                      forFullScreen={false}
                      onQuestionsSortOrderListChange={props.handleQuestionsSortOrderListChange}
                      onQuestionsFilterChange={props.handleQuestionsFilterChange}
                      supportRandomSort={true}
                      forAdmin={true}
                    />
                  </div>
                </div>
                <Questions
                  space={props.space}
                  section={props.editingSection}
                  answers={props.answers}
                  questions={props.questions}
                  onConsumedChange={props.handleConsumedChange}
                  onQuestionChange={props.handleQuestionChange}
                  onQuestionDelete={props.handleQuestionDelete}
                  onQuestionMoveUp={props.handleQuestionMoveUp}
                  onQuestionMoveDown={props.handleQuestionMoveDown}
                  onQuestionSelected={props.handleQuestionSelected}
                />
              </div>
            </React.Fragment>
          ) : props.editingSection && props.editingSection.type === 'survey' ? (
            <React.Fragment>
              <SurveyQuestionForm space={props.space} currentSection={props.editingSection} />
              <Questions
                space={props.space}
                section={props.editingSection}
                answers={props.answers}
                questions={props.questions}
                onConsumedChange={props.handleConsumedChange}
                onQuestionChange={props.handleQuestionChange}
                onQuestionDelete={props.handleQuestionDelete}
                onQuestionMoveUp={props.handleQuestionMoveUp}
                onQuestionMoveDown={props.handleQuestionMoveDown}
                onQuestionSelected={props.handleQuestionSelected}
              />
            </React.Fragment>
          ) : null}
          <div className={styles.section_detail_container_footer} />
          <FullScreen enabled={props.isPresentationModeInFullScreen} onChange={props.handleFullscreenChanged}>
            {props.isPresentationModeInFullScreen ? (
              <FullScreenQuestions
                space={props.space}
                section={props.editingSection}
                answers={props.answers}
                questions={props.questions}
                onConsumedChange={props.handleConsumedChange}
                onQuestionChange={props.handleQuestionChange}
                onQuestionsSortOrderListChange={props.handleQuestionsSortOrderListChange}
                onQuestionsFilterChange={props.handleQuestionsFilterChange}
                onExitFullscreenButtonClick={props.handleExitFullscreenButtonClick}
                onQuestionDelete={props.handleQuestionDelete}
              />
            ) : props.isPresentationModeInPage ? (
              <FullScreenQuestions
                space={props.space}
                section={props.editingSection}
                answers={props.answers}
                questions={props.questions}
                onConsumedChange={props.handleConsumedChange}
                onQuestionChange={props.handleQuestionChange}
                onQuestionsSortOrderListChange={props.handleQuestionsSortOrderListChange}
                onQuestionsFilterChange={props.handleQuestionsFilterChange}
                onExitFullscreenButtonClick={props.handleExitPresentationModeInPageButtonClick}
                onQuestionDelete={props.handleQuestionDelete}
              />
            ) : (
              <span />
            )}
          </FullScreen>
        </div>
      </div>
    );
  } else {
    return (
      <div className={`${styles.section_detail_container} uk-width-expand@s`}>
        <div className={styles.no_current_section_container}>
          <div className={styles.no_current_section_message}>
            ここでセクションごとの質疑応答、アンケートの管理をします
            <br />
            最初に
            <span className={styles.from_section_button} />
            セクションを作成しましょう〜
          </div>
          <div className={styles.modrow} />
        </div>
      </div>
    );
  }
}
