import firebase, { User } from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/functions';
import 'firebase/analytics';
import { ModeratorEventName, ModeratorEventParameter, ModeratorScreenName } from './types';

const config = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const FUNCTIONS_REGION = 'asia-northeast1';

class Firebase {
  public auth: firebase.auth.Auth;
  private unsubscribeAuthStateChanged?: firebase.Unsubscribe;

  public db: firebase.firestore.Firestore;
  public functions: firebase.functions.Functions;
  public analytics: firebase.analytics.Analytics;

  constructor() {
    firebase.initializeApp(config);
    const app = firebase.app();
    this.auth = app.auth();
    this.db = app.firestore();
    this.functions = app.functions(FUNCTIONS_REGION);
    this.analytics = app.analytics();
  }

  doSignInAnonymously(): Promise<firebase.auth.UserCredential> {
    return this.auth.signInAnonymously();
  }

  doSignInWithGoogle(): Promise<void> {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });
    return this.auth.signInWithRedirect(provider);
  }

  doSignInWithCustomToken(customToken: string): Promise<firebase.auth.UserCredential> {
    return this.auth.signInWithCustomToken(customToken);
  }

  doSignOut(): Promise<void> {
    return this.auth.signOut();
  }

  subscribeAuthStateChangedEvent(callback: (user: firebase.User | null) => void): void {
    this.unsubscribeAuthStateChanged && this.unsubscribeAuthStateChanged();
    this.unsubscribeAuthStateChanged = this.auth.onAuthStateChanged((user) => {
      if (user) {
        this.analytics.setUserId(user.uid);
      }
      callback(user);
    });
  }

  unsubscribeAuthStateChangedEvent(): void {
    this.unsubscribeAuthStateChanged && this.unsubscribeAuthStateChanged();
  }

  currentUser(): User | null {
    return this.auth.currentUser;
  }

  logEvent(screenName: ModeratorScreenName, eventName: ModeratorEventName, params: ModeratorEventParameter): void {
    this.analytics.setCurrentScreen(screenName);
    this.analytics.logEvent(eventName, params);
  }
}

export default Firebase;
