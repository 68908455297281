import React, { CSSProperties, useContext } from 'react';
import { ISection, ISpace } from '../types';
import SectionListItem from './section-list-item';
import styles from './section-list.module.scss';
import CreateSectionButton from './create-section-button';
import DelayedTask from '../common/delayed-task';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import { ERROR_QUESTION_EXISTS_IN_SECTION } from '../errors';

type IProps = {
  space?: ISpace;
  sections: ISection[];
  editSection?: ISection;
  onEditSectionChange: (section: ISection) => void;
  createButtonStyle?: CSSProperties;
  header: boolean;
  onSectionOrderChange: (source: ISection, target: ISection) => void;
};

type ISectionOrderChangeTarget = {
  source: ISection;
  target: ISection;
};

const SectionList = (props: IProps) => {
  const firebase = useContext(FirebaseContext);

  const doSectionOrderChange = async (sectionOrderChangeTarget: ISectionOrderChangeTarget): Promise<void> => {
    const moveSectionOrder = firebase.functions.httpsCallable('moveSectionOrder');
    const result = await moveSectionOrder({
      spaceId: props.space!.id,
      sourceSectionId: sectionOrderChangeTarget.source.id,
      targetSectionId: sectionOrderChangeTarget.target.id,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  const doDeleteSection = async (section: ISection): Promise<void> => {
    const deleteSection = firebase.functions.httpsCallable('deleteSection');
    const result = await deleteSection({
      spaceId: props.space!.id,
      sectionId: section.id,
    });
    if (!result.data.success) {
      if (result.data.errorCode === ERROR_QUESTION_EXISTS_IN_SECTION) {
        await UIkit.modal.alert('質問が一つ以上存在するため、このセクションを削除することができません。');
      } else {
        await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
      }
    }
  };

  const delayedTaskSectionOrderChange = new DelayedTask<ISectionOrderChangeTarget>(1000, doSectionOrderChange);
  const delayedTaskDeleteSection = new DelayedTask<ISection>(1000, doDeleteSection);

  const handleSectionOrderChange = (source: ISection, target: ISection): void => {
    props.onSectionOrderChange(source, target);
    delayedTaskSectionOrderChange.waitAndGo({ source, target });
  };

  const handleDeleteSection = (section: ISection): void => {
    delayedTaskDeleteSection.waitAndGo(section);
  };

  const renderSections = (): React.ReactNode[] => {
    return props.sections
      .sort((a, b) => a.sequenceNumber - b.sequenceNumber)
      .map((section) => (
        <SectionListItem
          key={section.id}
          space={props.space!}
          section={section}
          sections={props.sections}
          editing={props.editSection ? section.id === props.editSection.id : false}
          onEditSectionChange={props.onEditSectionChange}
          onSectionOrderChange={handleSectionOrderChange}
          onDeleteSection={handleDeleteSection}
        />
      ));
  };

  const renderSectionList = (): React.ReactNode => {
    if (props.sections.length > 0) {
      return renderSections();
    } else {
      return (
        <div className={styles.no_any_sections_container}>
          <div className={styles.black_modrow} />
          <div className={styles.no_any_sections_message}>まだセクションがないみたい</div>
        </div>
      );
    }
  };

  const renderHeader = (): React.ReactNode => {
    if (props.header) {
      return (
        <div className={styles.header}>
          <i className='uk-icon' uk-icon='list' />
          <span>セクション</span>
        </div>
      );
    } else {
      return null;
    }
  };

  return (
    <div className={styles.container}>
      {renderHeader()}
      {renderSectionList()}
      <CreateSectionButton spaceId={props.space?.id} buttonStyle={props.createButtonStyle} />
    </div>
  );
};

export default SectionList;
