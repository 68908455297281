import React, { ChangeEvent, Component } from 'react';
import styles from './edit-space.module.scss';
import { Errors, ISpace, ISpaceCredential, ISpaceSlackSetting } from '../types';
import {
  STRING_MAX_LENGTH_ADMIN_PASSWORD,
  STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
  STRING_MAX_LENGTH_SPACE_ID,
  STRING_MAX_LENGTH_SPACE_NAME,
  STRING_MAX_LENGTH_SPACE_PASSWORD,
  validateNotContains,
  validateStringLength,
  validateStringRequired,
} from '../validator';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import { createSpaceUrl } from '../utils';
import CopiableText from '../common/copiable-text';
import ToggleSwitch from '../common/toggle-switch';
import DelayedTask from '../common/delayed-task';

type IProps = {
  space?: ISpace;
  credential?: ISpaceCredential;
  slackSetting?: ISpaceSlackSetting;
  onSpaceVisibleChange: (space: ISpace, visible: boolean) => void;
};

type IState = {
  spaceName: string;
  spaceId: string;
  spacePassword: string;
  adminPassword: string;
  slackIncomingWebhook: string;
  slackSendMessage: boolean;
  loading: boolean;
  errors: Errors;
};

type ISpaceVisible = {
  space: ISpace;
  visible: boolean;
};

class EditSpace extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  modalRef: React.RefObject<HTMLDivElement>;

  delayedTask: DelayedTask<ISpaceVisible>;

  constructor(prop: IProps) {
    super(prop);
    this.state = {
      spaceName: this.props.space?.name || '',
      spaceId: this.props.space?.customSpaceId || '',
      spacePassword: this.props.credential?.spacePassword || '',
      adminPassword: this.props.credential?.adminPassword || '',
      slackIncomingWebhook: this.props.slackSetting?.incomingWebhook || '',
      slackSendMessage: this.props.slackSetting?.sendMessage || true,
      loading: false,
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
    this.delayedTask = new DelayedTask<ISpaceVisible>(1000, this.doChangeSpaceVisible);
  }

  doChangeSpaceVisible = async (spaceVisible: ISpaceVisible): Promise<void> => {
    const changeSpaceVisible = this.context.functions.httpsCallable('changeSpaceVisible');
    const result = await changeSpaceVisible({
      spaceId: spaceVisible.space.id,
      visible: spaceVisible.visible,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleEditSpaceButtonClick = (event: React.MouseEvent): void => {
    this.setState({
      spaceName: this.props.space?.name || '',
      spaceId: this.props.space?.customSpaceId || '',
      spacePassword: this.props.credential?.spacePassword || '',
      adminPassword: this.props.credential?.adminPassword || '',
      slackIncomingWebhook: this.props.slackSetting?.incomingWebhook || '',
      loading: false,
      errors: {},
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleSpaceNameChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spaceName = event.target.value;
    this.setState({
      spaceName,
      errors: validateStringLength(spaceName, 'spaceName', STRING_MAX_LENGTH_SPACE_NAME, this.state.errors, true),
    });
  };

  handleSpaceIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spaceId = event.target.value;
    this.setState({
      spaceId,
      errors: validateStringLength(spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, this.state.errors, true),
    });
  };

  handleSpacePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const spacePassword = event.target.value;
    this.setState({
      spacePassword,
      errors: validateStringLength(
        spacePassword,
        'spacePassword',
        STRING_MAX_LENGTH_SPACE_PASSWORD,
        this.state.errors,
        true
      ),
    });
  };

  handleAdminPasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    const adminPassword = event.target.value;
    this.setState({
      adminPassword,
      errors: validateStringLength(
        adminPassword,
        'adminPassword',
        STRING_MAX_LENGTH_ADMIN_PASSWORD,
        this.state.errors,
        true
      ),
    });
  };

  handleSlackIncomingWebhookChange = (event: ChangeEvent<HTMLInputElement>) => {
    const slackIncomingWebhook = event.target.value;
    this.setState({
      slackIncomingWebhook,
      errors: validateStringLength(
        slackIncomingWebhook,
        'slackIncomingWebhook',
        STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
        this.state.errors,
        true
      ),
    });
  };

  handleSlackSendMessageChange = (event: ChangeEvent<HTMLInputElement>) => {
    const slackSendMessage = event.target.checked;
    this.setState({
      slackSendMessage,
    });
  };

  handleToggleSwitchChange = async (visible: boolean): Promise<void> => {
    this.props.onSpaceVisibleChange(this.props.space!, visible);
    this.delayedTask.waitAndGo({
      space: this.props.space!,
      visible,
    });
  };

  handleUpdateSpaceButtonClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const updateSpace = this.context.functions.httpsCallable('updateSpace');
    const result = await updateSpace({
      spaceId: this.props.space!.id,
      name: this.state.spaceName,
      customSpaceId: this.state.spaceId,
      spacePassword: this.state.spacePassword,
      adminPassword: this.state.adminPassword,
      slackIncomingWebhook: this.state.slackIncomingWebhook,
      slackSendMessage: this.state.slackSendMessage,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      UIkit.modal(this.modalRef.current!).hide();
    } else {
      UIkit.modal(this.modalRef.current!).hide();
      UIkit.modal.alert(`Error: ${result.data.errorMessage}`).then(() => {
        UIkit.modal(this.modalRef.current!).show();
      });
    }
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.spaceName, 'spaceName', errors, false);
    errors = validateStringLength(this.state.spaceName, 'spaceName', STRING_MAX_LENGTH_SPACE_NAME, errors, false);
    errors = validateStringRequired(this.state.spaceId, 'spaceId', errors, false);
    errors = validateStringLength(this.state.spaceId, 'spaceId', STRING_MAX_LENGTH_SPACE_ID, errors, false);
    errors = validateStringLength(
      this.state.spacePassword,
      'spacePassword',
      STRING_MAX_LENGTH_SPACE_PASSWORD,
      errors,
      false
    );
    errors = validateStringRequired(this.state.adminPassword, 'adminPassword', errors, false);
    errors = validateStringLength(
      this.state.adminPassword,
      'adminPassword',
      STRING_MAX_LENGTH_ADMIN_PASSWORD,
      errors,
      false
    );
    errors = validateStringLength(
      this.state.slackIncomingWebhook,
      'slackIncomingWebhook',
      STRING_MAX_LENGTH_SLACK_INCOMING_WEBHOOK,
      errors,
      false
    );
    errors = validateNotContains(this.state.spaceId, 'spaceId', ['/'], errors, false);
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderUpdateButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className='uk-button uk-button-primary' type='button' onClick={this.handleUpdateSpaceButtonClick}>
          イベント更新
        </button>
      );
    }
  }

  renderSpacePasswordText(): React.ReactNode {
    if (this.props.credential?.spacePassword) {
      return (
        <div className={styles.copiable_text_container_wrapper}>
          <CopiableText value={this.props.credential?.spacePassword} displayText='参加パスワード' />
        </div>
      );
    } else {
      return null;
    }
  }

  renderVisibleToggleSwitch(): React.ReactNode {
    if (this.props.space) {
      return (
        <ToggleSwitch
          id={`edit-space-visible-toggle-${this.props.space.id}-${new Date().getTime()}`}
          text={['公開中', '非公開']}
          currentValue={this.props.space.visible}
          style={{
            marginLeft: '16px',
          }}
          onChange={(checked: boolean): Promise<void> => this.handleToggleSwitchChange(checked)}
        />
      );
    } else {
      return null;
    }
  }

  render(): React.ReactNode {
    const spaceUrl = createSpaceUrl(this.props.space, this.props.credential);
    return (
      <>
        <div className={styles.gear} onClick={this.handleEditSpaceButtonClick} />

        <div uk-modal='true' ref={this.modalRef}>
          <div className='uk-modal-dialog'>
            <button className='uk-modal-close-default' type='button' uk-close='true' />
            <div className='uk-modal-body'>
              <div className={styles.form_header}>
                <h2 className={styles.modal_title}>イベント情報</h2>
                {this.renderVisibleToggleSwitch()}
              </div>
              <div className={styles.form_container}>
                <div className='uk-margin uk-hidden@s'>
                  <div className={styles.copiable_text_container}>
                    <div className={styles.copiable_text_container_wrapper}>
                      <CopiableText value={spaceUrl} displayText='URL' />
                    </div>
                    {this.renderSpacePasswordText()}
                    <div className={styles.copiable_text_container_wrapper}>
                      <CopiableText value={this.props.space?.customSpaceId} displayText='イベントID' />
                    </div>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor='edit-space-form-space-name'>
                    イベント名の設定*
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-space-form-space-name'
                      type='text'
                      placeholder='イベント名を入力してください。'
                      value={this.state.spaceName}
                      onChange={this.handleSpaceNameChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.spaceName}</span>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor='edit-space-form-space-id'>
                    イベントIDの設定（覚えやすい値に変更OK）*
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-space-form-space-id'
                      type='text'
                      placeholder='イベントIDを入力してください。'
                      value={this.state.spaceId}
                      onChange={this.handleSpaceIdChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.spaceId}</span>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor='edit-space-form-space-password'>
                    参加パスワードの設定（覚えやすい値に変更OK）
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-space-form-space-password'
                      type='text'
                      placeholder='イベントに参加するためのパスワードを入力してください。'
                      value={this.state.spacePassword}
                      onChange={this.handleSpacePasswordChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.spacePassword}</span>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor='edit-space-form-admin-password'>
                    管理用パスワードの設定（覚えやすい値に変更OK）*
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-space-form-admin-password'
                      type='text'
                      placeholder='イベントを管理するためのパスワードを入力してください。'
                      value={this.state.adminPassword}
                      onChange={this.handleAdminPasswordChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.adminPassword}</span>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor='edit-space-form-slack-incoming-webhook'>
                    Slack Incoming Webhook URL
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-space-form-slack-incoming-webhook'
                      type='text'
                      placeholder='投稿された質問をSlackに送信したい場合に入力してください。'
                      value={this.state.slackIncomingWebhook}
                      onChange={this.handleSlackIncomingWebhookChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.slackIncomingWebhook}</span>
                    <div className={styles.form_sub_row}>
                      <label>
                        <input
                          className='uk-checkbox'
                          id='edit-space-form-slack-send-message'
                          type='checkbox'
                          checked={this.state.slackSendMessage}
                          onChange={this.handleSlackSendMessageChange}
                        />
                        Slackにメッセージを送信する。
                      </label>
                    </div>
                  </div>
                </div>
                <div className={styles.form_footer}>{this.renderUpdateButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditSpace;
