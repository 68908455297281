import React, { Component } from 'react';
import styles from './admin-navbar.module.scss';
import { Link } from 'react-router-dom';

type Pages = 'space_list' | 'space_entrance';

type IProps = {
  activePage: Pages;
};

class AdminNavbar extends Component<IProps, any> {
  render(): React.ReactNode {
    const [spaceListClassName, spaceEntranceClassName] =
      this.props.activePage === 'space_list'
        ? [`${styles.nav_item} ${styles.nav_item_active}`, `${styles.nav_item} ${styles.nav_item_inactive}`]
        : [`${styles.nav_item} ${styles.nav_item_inactive}`, `${styles.nav_item} ${styles.nav_item_active}`];
    return (
      <div className={styles.nav}>
        <div className={spaceListClassName}>
          <Link to='/admin'>
            <span>イベント一覧</span>
          </Link>
        </div>
        <div className={spaceEntranceClassName}>
          <Link to='/admin/entrance'>
            <span>他のイベントに入る</span>
          </Link>
        </div>
      </div>
    );
  }
}

export default AdminNavbar;
