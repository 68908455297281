import React, { Component } from 'react';
import styles from './not_found.module.scss';

class NotFound extends Component<any, any> {
  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.error_code}>404</div>
        <div className={styles.modrow} />
        <div className={styles.message}>
          Moderatorの
          <a href='/#'>トップ</a>
          にもどりましょう。
        </div>
      </div>
    );
  }
}

export default NotFound;
