import React, { Component } from 'react';
import styles from './copiable-text.module.scss';
import UIkit from 'uikit';

type IProps = {
  value?: string;
  displayText: string;
  style?: React.CSSProperties;
};

class CopiableText extends Component<IProps, any> {
  handleClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    event.preventDefault();
    if (navigator.clipboard) {
      await navigator.clipboard.writeText(this.props.value!);
    } else {
      const textarea = document.createElement('textarea');
      textarea.value = this.props.value || '';
      document.body.appendChild(textarea);
      const range = document.createRange();
      range.selectNodeContents(textarea);
      const selection = window.getSelection();
      selection!.removeAllRanges();
      selection!.addRange(range);
      textarea.setSelectionRange(0, 999999);
      document.execCommand('copy');
      document.body.removeChild(textarea);
    }
    UIkit.notification({
      message: 'クリップボードにコピーしました。',
      status: 'primary',
      timeout: 2000,
    });
  };

  render(): React.ReactNode {
    return (
      <div
        className={styles.container}
        style={this.props.style}
        onClick={async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
          await this.handleClick(event);
        }}
      >
        <div>{this.props.displayText}</div>
        <div className={styles.copy_icon} />
      </div>
    );
  }
}

export default CopiableText;
