import React, { Component } from 'react';
import { IExportTarget, ISpace } from '../types';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';
import styles from './export-and-open-in-google-drive.module.scss';

type IProps = {
  space: ISpace;
  exportTarget: IExportTarget;
};

type IState = {
  loading: boolean;
};

class ExportAndOpenInGoogleDrive extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  handleExportButtonClick = async (event: React.MouseEvent): Promise<void> => {
    event.preventDefault();
    this.setState({
      loading: true,
    });
    const exportToGoogleDrive = this.context.functions.httpsCallable('exportToGoogleDrive');
    const result = await exportToGoogleDrive({
      spaceId: this.props.space.id,
      sectionId: this.props.exportTarget.section!.id,
      questionId: this.props.exportTarget.question?.id,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      window.open(result.data.url);
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='ratio: 0.8' />;
    } else {
      return <div className={styles.export_button} onClick={this.handleExportButtonClick} />;
    }
  }
}

export default ExportAndOpenInGoogleDrive;
