import React, { Component, Fragment } from 'react';
import { IExportTarget, IQuestion, ISection, ISpace } from '../types';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import Firebase from 'firebase';
import ExportRawData from './export-raw-data';
import ExportAllRawData from './export-all-raw-data';
import styles from './export-data.module.scss';
import ExportAndOpenInGoogleDrive from './export-and-open-in-google-drive';

type IProps = {
  space: ISpace;
};

type IState = {
  exportTargets: IExportTarget[];
};

class ExportData extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  private modalRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      exportTargets: [],
    };
    this.modalRef = React.createRef<HTMLDivElement>();
  }

  getSections(): void {
    this.context.db
      .collection('spaces')
      .doc(this.props.space.id)
      .collection('sections')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((result) => {
          const section = this.createSection(result);
          if (result.data().type === 'q_and_a') {
            this.setState({
              exportTargets: [
                ...this.state.exportTargets,
                {
                  type: 'section',
                  section,
                },
              ],
            });
          } else {
            this.getSurveyQuestions(section);
          }
        });
      });
  }

  getSurveyQuestions(section: ISection): void {
    this.context.db
      .collection('spaces')
      .doc(this.props.space.id)
      .collection('sections')
      .doc(section.id)
      .collection('questions')
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((result) => {
          const question = this.createQuestion(result);
          this.setState({
            exportTargets: [
              ...this.state.exportTargets,
              {
                type: 'question',
                section,
                question,
              },
            ],
          });
        });
      });
  }

  createSection(result: Firebase.firestore.QueryDocumentSnapshot): ISection {
    return {
      id: result.id,
      name: result.data().name,
      open: result.data().open,
      type: result.data().type,
      questionsSortOrder: result.data().questions_sort_order,
      questionsFilter: result.data().questions_filter,
      createdAt: result.data().created_at.toDate(),
      sequenceNumber: result.data().sequence_number,
    };
  }

  createQuestion(result: Firebase.firestore.QueryDocumentSnapshot): IQuestion {
    return {
      id: result.id,
      nickname: result.data().nickname,
      items: result.data().items,
      open: result.data().open,
      consumed: result.data().consumed,
      visibleResult: result.data().visible_result,
      author: result.data().author,
      text: result.data().text,
      type: result.data().type,
      createdAt: result.data().created_at.toDate(),
      sequenceNumber: result.data().sequence_number,
      voteQuestionType: result.data().vote_question_type || 'question',
      selected: false,
    };
  }

  handleOpenExportModalClick = (event: React.MouseEvent): void => {
    event.preventDefault();
    UIkit.modal(this.modalRef.current!).show();
    this.setState({
      exportTargets: [],
    });
    this.getSections();
  };

  renderExportTargets(): React.ReactNode {
    const listItems = this.state.exportTargets.map((exportTarget, index) => {
      if (exportTarget.type === 'section') {
        return (
          <div key={`export-section-${exportTarget.section?.id}`} className={styles.target_row}>
            <div className={styles.target_type_vote}>投稿</div>
            <div className={styles.target_name}>{exportTarget.section!.name}</div>
            <div className={styles.target_download}>
              <ExportRawData space={this.props.space} exportTarget={exportTarget} />
              <ExportAndOpenInGoogleDrive space={this.props.space} exportTarget={exportTarget} />
            </div>
          </div>
        );
      } else {
        // 'question'
        return (
          <div key={`export-question-${exportTarget.question?.id}`} className={styles.target_row}>
            <div className={styles.target_type_survey}>アンケート</div>
            <div className={styles.target_name}>{exportTarget.question!.text}</div>
            <div className={styles.target_download}>
              <ExportRawData space={this.props.space} exportTarget={exportTarget} />
              <ExportAndOpenInGoogleDrive space={this.props.space} exportTarget={exportTarget} />
            </div>
          </div>
        );
      }
    });
    return listItems;
  }

  renderExportAllButton(): React.ReactNode {
    if (this.state.exportTargets.length > 0) {
      return <ExportAllRawData space={this.props.space} exportTargets={this.state.exportTargets} />;
    } else {
      return null;
    }
  }

  renderExportModal(): React.ReactNode {
    return (
      <div uk-modal='true' ref={this.modalRef}>
        <div className='uk-modal-dialog'>
          <button className='uk-modal-close-default' type='button' uk-close='true' />
          <div className='uk-modal-body'>
            <h2>データのエクスポート</h2>
            <div className={styles.target_container}>
              {this.renderExportTargets()}
              <div className={styles.target_footer}>{this.renderExportAllButton()}</div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    return (
      <Fragment>
        <button className={`${styles.export_button} uk-button`} onClick={this.handleOpenExportModalClick}>
          <span>CSV</span>
        </button>
        {this.renderExportModal()}
      </Fragment>
    );
  }
}

export default ExportData;
