import React, { Component } from 'react';
import styles from './counter-badge.module.scss';

type IProps = {
  count: number;
  label: string;
  color: 'primary' | 'secondary';
};

class CounterBadge extends Component<IProps, any> {
  render(): React.ReactNode {
    const count = this.props.count;
    const countStr = ('00000' + count).slice(-5);
    return (
      <div className={styles.counter_badge_container}>
        <span
          className={
            this.props.color === 'primary'
              ? styles.counter_badge_container_primary
              : styles.counter_badge_container_secondary
          }
        >
          {this.props.label}
          {countStr}
        </span>
      </div>
    );
  }
}

export default CounterBadge;
