import React, { Component } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import InProgress from '../common/in-progress';
import LicenseAgreement from './license-agreement';

export interface IAttendeePageState {
  signedIn: boolean;
}

abstract class AbstractAttendeePage<P, T extends IAttendeePageState> extends Component<RouteComponentProps<P>, T> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  didSignIn(): void {}

  doSignInAnonymously(): void {
    this.context
      .doSignInAnonymously()
      .then((value) => {
        console.log('Signed in Anonymously.');
      })
      .catch((reason) => {
        UIkit.modal.alert(`Error: ${reason}`).then(() => {
          this.props.history.push('/');
        });
      });
  }

  componentDidMount() {
    this.context.subscribeAuthStateChangedEvent((user) => {
      if (user === null) {
        this.doSignInAnonymously();
      } else {
        user.isAnonymous ? console.log('Signed in Anonymously.') : console.log('Signed in with Google Account.');
        this.setState({
          signedIn: true,
        });
        this.didSignIn();
      }
    });
  }

  componentWillUnmount() {
    this.context.unsubscribeAuthStateChangedEvent();
  }

  abstract renderImpl(): React.ReactNode;

  render(): React.ReactNode {
    if (this.state.signedIn) {
      return (
        <>
          {this.renderImpl()}
          <LicenseAgreement />
        </>
      );
    } else {
      return <InProgress />;
    }
  }
}

export default AbstractAttendeePage;
