import React, { useState } from 'react';
import FirebaseContext from './firebase_context';
import Firebase from './firebase';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Top from './top';
import NotFound from './not_found';
import Space from './attendee/space';
import AdminSpace from './admin/admin-space';
import SpacePassword from './attendee/space-entrance';
import AdminTop from './admin/admin-top';
import AdminEntrance from './admin/admin-entrance';
import Autify from './autify';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { isAndroid, isIOS } from './utils';
import { TouchBackend } from 'react-dnd-touch-backend';

const App = () => {
  const [firebase] = useState(new Firebase());

  const backend = isAndroid() || isIOS() ? TouchBackend : HTML5Backend;
  const opts = isAndroid() || isIOS() ? { delay: 200 } : {};

  return (
    <DndProvider backend={backend} options={opts}>
      <BrowserRouter>
        <FirebaseContext.Provider value={firebase}>
          <Switch>
            <Route exact path='/' component={Top} />
            <Route exact path='/spaces/:spaceId' component={Space} />
            <Route exact path='/e/:spaceId' component={Space} />
            <Route exact path='/spaces/:spaceId/entrance' component={SpacePassword} />
            <Route exact path='/e/:spaceId/entrance' component={SpacePassword} />
            <Route exact path='/admin' component={AdminTop} />
            <Route exact path='/admin/entrance' component={AdminEntrance} />
            <Route exact path='/admin/spaces/:spaceId' component={AdminSpace} />
            <Route exact path='/autify' component={Autify} />
            <Route component={NotFound} />
          </Switch>
        </FirebaseContext.Provider>
      </BrowserRouter>
    </DndProvider>
  );
};

export default App;
