import React, { ChangeEvent, Component } from 'react';
import styles from './edit-survey-question-form.module.scss';
import { Errors, IQuestion, ISection, ISpace } from '../types';
import UIkit from 'uikit';
import {
  MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM,
  MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM,
  STRING_MAX_LENGTH_SURVEY_QUESTION,
  STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM,
  validateArrayLength,
  validateArrayRequired,
  validateStringLength,
  validateStringRequired,
} from '../validator';
import FirebaseContext from '../firebase_context';
import { ERROR_QUESTION_ITEMS_CANT_BE_DELETED, ERROR_QUESTION_SELECTION_LIMIT_COUNT_CANT_BE_REDUCED } from '../errors';

type IProps = {
  space: ISpace;
  section: ISection;
  question: IQuestion;
  onEditQuestionMenuClick: () => void;
  offsetInQuestions: number;
};

type IState = {
  text: string;
  itemsSource: string;
  items: string[];
  selectionLimitCount: number;
  loading: boolean;
  errors: Errors;
};

class EditSurveyQuestionForm extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  modalRef: React.RefObject<HTMLDivElement>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      text: '',
      itemsSource: '',
      items: [],
      selectionLimitCount: 0,
      loading: false,
      errors: {},
    };
    this.modalRef = React.createRef<HTMLDivElement>();
  }

  handleEditQuestionClick = async (event: React.MouseEvent): Promise<void> => {
    this.props.onEditQuestionMenuClick();
    this.setState({
      text: this.props.question.text,
      itemsSource: this.props.question.items.join('\n'),
      items: this.props.question.items,
      selectionLimitCount: this.props.question.selectionLimitCount || 0,
    });
    UIkit.modal(this.modalRef.current!).show();
  };

  handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    this.setState({
      text,
      errors: validateStringLength(text, 'text', STRING_MAX_LENGTH_SURVEY_QUESTION, this.state.errors, true),
    });
  };

  handleItemsSourceChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const itemsSource = event.target.value;
    const items = itemsSource
      .split('\n')
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
    let errors = Object.assign({}, this.state.errors);
    delete errors.items;
    if (this.props.question.type === 'single_answer') {
      errors = validateArrayLength(items, 'items', MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM, errors, false);
    } else {
      errors = validateArrayLength(items, 'items', MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM, errors, false);
    }

    for (const item of items) {
      errors = validateStringLength(item, 'items', STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM, errors, false);
    }
    let selectionLimitCount = this.state.selectionLimitCount;
    if (selectionLimitCount > items.length) {
      selectionLimitCount = items.length;
    }
    this.setState({
      itemsSource,
      items,
      selectionLimitCount,
      errors,
    });
  };

  handleSelectionLimitCountChange = (event: ChangeEvent<HTMLInputElement>) => {
    let selectionLimitCount = event.target.value ? Number(event.target.value) : 0;
    if (selectionLimitCount > this.state.items.length) {
      selectionLimitCount = this.state.items.length;
    }
    this.setState({
      selectionLimitCount,
    });
  };

  handleUpdateButtonClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const updateQuestion = this.context.functions.httpsCallable('updateQuestion');
    const result = await updateQuestion({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      text: this.state.text,
      items: this.state.items,
      selectionLimitCount: this.state.selectionLimitCount,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      UIkit.modal(this.modalRef.current!).hide();
    } else if (result.data.errorCode === ERROR_QUESTION_ITEMS_CANT_BE_DELETED) {
      await UIkit.modal.alert('すでに回答されているため、選択肢を減らすことはできません。');
      UIkit.modal(this.modalRef.current!).show();
    } else if (result.data.errorCode === ERROR_QUESTION_SELECTION_LIMIT_COUNT_CANT_BE_REDUCED) {
      await UIkit.modal.alert('すでに回答されているため、選択可能数を減らすことはできません。');
      UIkit.modal(this.modalRef.current!).show();
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.text, 'text', errors, false);
    errors = validateStringLength(this.state.text, 'text', STRING_MAX_LENGTH_SURVEY_QUESTION, errors, false);
    errors = validateArrayRequired(this.state.items, 'items', 2, errors, false);
    if (this.props.question.type === 'single_answer') {
      errors = validateArrayLength(this.state.items, 'items', MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM, errors, false);
    } else {
      errors = validateArrayLength(this.state.items, 'items', MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM, errors, false);
    }
    for (const item of this.state.items) {
      errors = validateStringLength(item, 'items', STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM, errors, false);
    }
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderUpdateButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button
          className='uk-button uk-button-primary'
          type='button'
          onClick={this.handleUpdateButtonClick}
          id='edit-question-form-update-button'
        >
          質問修正
        </button>
      );
    }
  }

  renderSelectionLimitCount(): React.ReactNode {
    if (this.props.question.type === 'multiple_answers') {
      return (
        <div className={styles.form_row}>
          <label htmlFor={`edit-question-form-selection-limit-count-${this.props.question.id}`}>選択可能数</label>
          <input
            id='edit-question-form-selection-limit-count'
            className='uk-input'
            placeholder='回答時に選択可能な個数の上限を入力'
            value={this.state.selectionLimitCount}
            type='number'
            min={0}
            onChange={this.handleSelectionLimitCountChange}
            required={true}
          />
        </div>
      );
    } else {
      return null;
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <li>
          <div
            className={styles.survey_question_for_admin_menu_edit}
            onClick={this.handleEditQuestionClick}
            id={`edit-question-form-edit-menu-${this.props.offsetInQuestions}`}
          >
            修正する
          </div>
        </li>

        <div uk-modal='true' ref={this.modalRef}>
          <div className='uk-modal-dialog'>
            <button className='uk-modal-close-default' type='button' uk-close='true' />
            <div className='uk-modal-body'>
              <div className={styles.form_header}>
                <h2 className={styles.modal_title}>質問修正</h2>
              </div>
              <div className={styles.form_container}>
                <div className={styles.form_row}>
                  <label className='uk-form-label' htmlFor={`edit-question-form-text-${this.props.question.id}`}>
                    質問文
                  </label>
                  <div className='uk-form-controls'>
                    <input
                      className='uk-input'
                      id='edit-question-form-text'
                      type='text'
                      placeholder='質問したいことを入力してください。'
                      value={this.state.text}
                      onChange={this.handleTextChange}
                    />
                    <span className='uk-text-danger'>{this.state.errors.text}</span>
                  </div>
                </div>
                <div className={styles.form_row}>
                  <label htmlFor={`edit-question-form-items-${this.props.question.id}`}>選択肢</label>
                  <textarea
                    id='edit-question-form-items'
                    className='uk-textarea'
                    rows={3}
                    placeholder='選択肢を改行区切りで入力'
                    value={this.state.itemsSource}
                    onChange={this.handleItemsSourceChange}
                  />
                  <span className='uk-text-danger'>{this.state.errors.items}</span>
                </div>
                <div className={styles.form_row}>
                  <span>
                    質問タイプ: {this.props.question.type === 'single_answer' ? '1つだけ選択可能' : '複数選択可能'}
                  </span>
                </div>
                {this.renderSelectionLimitCount()}
                <div className={styles.form_footer}>{this.renderUpdateButton()}</div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EditSurveyQuestionForm;
