import AbstractAdminPage, { IAdminPageState } from './abstract-admin-page';
import { RouteComponentProps } from 'react-router-dom';
import React from 'react';
import AdminHeader from './admin-header';
import AdminNavbar from './admin-navbar';
import JoinToSpaceForAdminForm from './join-to-space-for-admin-form';
import styles from './admin-entrance.module.scss';

class AdminEntrance extends AbstractAdminPage<{}, IAdminPageState> {
  constructor(props: RouteComponentProps<{}>) {
    super(props);
    this.state = {
      signingIn: true,
      signedInAsAuthorizedUser: false,
    };
  }

  renderImpl(): React.ReactNode {
    return (
      <div>
        <AdminHeader />
        <AdminNavbar activePage='space_entrance' />
        <div className={styles.content_container}>
          <div className={styles.content_wrapper}>
            <div className={styles.content}>
              <JoinToSpaceForAdminForm />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AdminEntrance;
