import React, { Component } from 'react';
import FirebaseContext from '../firebase_context';
import Firebase from 'firebase';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ISpace, ISpaceFilter } from '../types';
import DeleteSpace from './delete-space';
import styles from './joined-spaces-for-admin.module.scss';
import moment from 'moment';
import ExportData from '../common/export-data';
import ToggleSwitch from '../common/toggle-switch';
import DelayedTask from '../common/delayed-task';
import UIkit from 'uikit';
import CreateSheetReport from '../common/create-sheet-report';

interface IProps extends RouteComponentProps {
  filter: ISpaceFilter;
}

type IState = {
  spaces: ISpace[];
};

type ISpaceVisible = {
  space: ISpace;
  visible: boolean;
};

class JoinedSpacesForAdmin extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  unsubscribeJoinedSpacesForAdminChange?: () => void;

  delayedTask: DelayedTask<ISpaceVisible>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      spaces: [],
    };
    this.delayedTask = new DelayedTask<ISpaceVisible>(1000, this.doChangeSpaceVisible);
  }

  doChangeSpaceVisible = async (spaceVisible: ISpaceVisible): Promise<void> => {
    const changeSpaceVisible = this.context.functions.httpsCallable('changeSpaceVisible');
    const result = await changeSpaceVisible({
      spaceId: spaceVisible.space.id,
      visible: spaceVisible.visible,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  componentDidMount() {
    const uid = this.context.currentUser()!.uid;
    this.unsubscribeJoinedSpacesForAdminChange = this.context.db
      .collection('joined_spaces_for_admin')
      .doc(uid)
      .onSnapshot(
        (documentSnapshot: Firebase.firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const spaces = documentSnapshot.data()!.spaces;
            const promises = Object.keys(spaces).map((spaceId) =>
              this.context.db.collection('spaces').doc(spaceId).get()
            );
            Promise.all(promises).then((values) => {
              this.setState({
                spaces: values
                  .filter((value) => {
                    if (value.exists) {
                      return !value.data()!.deleted;
                    } else {
                      return false;
                    }
                  })
                  .map((value) => ({
                    id: value.id,
                    name: value.data()!.name,
                    author: value.data()!.author,
                    customSpaceId: value.data()!.custom_space_id,
                    visible: value.data()!.visible,
                    createdAt: value.data()!.created_at.toDate(),
                  })),
              });
            });
          } else {
            this.setState({
              spaces: [],
            });
          }
        },
        (error) => {
          console.log(error);
        }
      );
  }

  componentWillUnmount(): void {
    this.unsubscribeJoinedSpacesForAdminChange && this.unsubscribeJoinedSpacesForAdminChange();
  }

  handleSpaceNameClick = (event: React.MouseEvent, space: ISpace): void => {
    this.props.history.push(`/admin/spaces/${encodeURIComponent(space.customSpaceId)}`);
  };

  handleToggleSwitchChange = async (space: ISpace, visible: boolean): Promise<void> => {
    const newSpaces = [...this.state.spaces];
    newSpaces.find((x) => x.id === space.id)!.visible = visible;
    this.setState({
      spaces: newSpaces,
    });
    this.delayedTask.waitAndGo({
      space,
      visible,
    });
  };

  renderSpaces(): React.ReactNode[] {
    return this.state.spaces
      .filter((space) => {
        if (this.props.filter === 'all') {
          return true;
        } else if (this.props.filter === 'open') {
          return space.visible;
        } else {
          // 'close'
          return !space.visible;
        }
      })
      .sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime())
      .map((value, index) => (
        <div key={value.id} className={styles.space}>
          <div className={styles.space_id}>イベントID: {value.customSpaceId}</div>
          <div className={styles.space_header}>
            <div className={styles.space_created_at}>{moment(value.createdAt).format('YYYY-MM-DD HH:mm:ss')}</div>
            <ExportData space={value} />
            <CreateSheetReport space={value} />
            <div className={styles.delete_space_column}>
              <DeleteSpace space={value} />
            </div>
          </div>
          <div
            id={`joined-space-for-admin-${index}`}
            className={styles.space_detail}
            onClick={(event: React.MouseEvent): void => {
              this.handleSpaceNameClick(event, value);
            }}
          >
            <div className={`${styles.space_name}`}>{value.name}</div>
            <ToggleSwitch
              id={`space-${value.id}`}
              text={['公開中', '非公開']}
              currentValue={value.visible}
              style={{
                marginLeft: 'auto',
              }}
              onChange={(checked: boolean): Promise<void> => this.handleToggleSwitchChange(value, checked)}
            />
          </div>
        </div>
      ));
  }

  renderNoAnySpaces(): React.ReactNode {
    return (
      <div className={styles.no_any_spaces_container}>
        <div className={styles.black_modrow} />
        <div className={styles.no_any_spaces_message}>
          まだイベントがないみたいです。＋ボタンでイベントを作るか、他のイベントに入ってみましょう。
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    if (this.state.spaces.length > 0) {
      return this.renderSpaces();
    } else {
      return this.renderNoAnySpaces();
    }
  }
}

export default withRouter(JoinedSpacesForAdmin);
