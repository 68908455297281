import React from 'react';
import { DragObjectWithType, useDrag, useDrop } from 'react-dnd';
import { ISection, ISpace } from '../types';
import { renderSectionType } from '../utils';
import styles from './section-list-item.module.scss';
import UIkit from 'uikit';

type IProps = {
  space: ISpace;
  section: ISection;
  sections: ISection[];
  editing: boolean;
  onEditSectionChange: (section: ISection) => void;
  onSectionOrderChange: (source: ISection, target: ISection) => void;
  onDeleteSection: (section: ISection) => void;
};

interface IDraggableObject extends DragObjectWithType {
  section: ISection;
}

const SectionListItem = (props: IProps) => {
  const [{ isDragging }, drag] = useDrag<IDraggableObject, any, any>({
    item: { section: props.section, type: 'section-list-item' },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const backgroundColor = isDragging ? '#ccbaba' : undefined;

  const [, drop] = useDrop<IDraggableObject, any, any>({
    accept: 'section-list-item',
    drop: (item) => {
      props.onSectionOrderChange(item.section, props.section);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
  });

  const attachRef = (el: any): void => {
    drag(el);
    drop(el);
  };

  const handleSectionClick = (): void => {
    props.onEditSectionChange(props.section);
  };

  const handleDeleteButtonClick = async (event: React.MouseEvent<HTMLDivElement>): Promise<void> => {
    event.stopPropagation();
    try {
      await UIkit.modal.confirm('このセクションを削除しますか？');
    } catch (reason) {
      return;
    }
    props.onDeleteSection(props.section);
  };

  const renderSectionOpenCloseBadge = (section: ISection): React.ReactNode => {
    if (section.open) {
      return <div className={`${styles.section_open_close_badge} ${styles.section_open_close_badge_open}`}>受付中</div>;
    } else {
      return (
        <div className={`${styles.section_open_close_badge} ${styles.section_open_close_badge_close}`}>受付終了</div>
      );
    }
  };

  const renderOnairBadge = (section: ISection): React.ReactNode => {
    if (props.space.currentSection === props.section.id) {
      return <div className={styles.section_now_onair}>ON AIR</div>;
    } else {
      return null;
    }
  };

  const editingClassName = props.editing ? styles.container_section_editing : '';

  return (
    <div
      ref={attachRef}
      className={`${styles.container} ${editingClassName}`}
      onClick={handleSectionClick}
      style={{ backgroundColor }}
    >
      <div className={styles.section_info_container}>
        {renderSectionOpenCloseBadge(props.section)}
        {renderOnairBadge(props.section)}
      </div>
      <div className={styles.section_name}>{props.section.name}</div>
      <div className={styles.section_footer}>
        <div className={styles.section_type}>
          <i className='uk-icon' uk-icon='tag' />
          {renderSectionType(props.section)}
        </div>
        <div className={styles.section_delete_button} onClick={handleDeleteButtonClick} />
      </div>
    </div>
  );
};

export default SectionListItem;
