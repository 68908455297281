import React from 'react';
import { ISpace } from '../types';
import styles from './create-sheet-report.module.scss';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';

type IProps = {
  space: ISpace;
};

type IState = {
  loading: boolean;
};

class CreateSheetReport extends React.Component<IProps, IState> {
  constructor(props: IProps) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  handleReportButtonClick = async (_event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    this.setState({
      loading: true,
    });
    const requestSheetReport = this.context.functions.httpsCallable('requestSheetReport');
    const result = await requestSheetReport({
      spaceId: this.props.space.id,
    });
    this.setState({
      loading: false,
    });
    if (result.data.success) {
      UIkit.notification({
        message: 'Google Sheetsで作成されます。<br>作成完了後に通知が届きます。',
        status: 'primary',
        timeout: 5000,
      });
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button className={`${styles.create_button} uk-button`} onClick={this.handleReportButtonClick}>
          <span>Report</span>
        </button>
      );
    }
  }
}

export default CreateSheetReport;
