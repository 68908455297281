import React, { Component } from 'react';
import { ISpace, ISpaceCredential } from '../types';
import ChangeNickname from './change-nickname';
import styles from './space-info.module.scss';

type IProps = {
  space?: ISpace;
  spaceCredential?: ISpaceCredential;
  nickname?: string;
};

class SpaceInfo extends Component<IProps, any> {
  render(): React.ReactNode {
    return (
      <div className={styles.container}>
        <div className={styles.nickname_row}>
          <div className={styles.nickname}>
            ようこそ
            <span>{this.props.nickname || 'untitled'}</span>
            さん
          </div>
          <ChangeNickname space={this.props.space!} nickname={this.props.nickname} />
        </div>
        <div className={styles.space_info_row}>イベントID: {this.props.space?.customSpaceId}</div>
        <div className={styles.space_name_row}>{this.props.space?.name}</div>
      </div>
    );
  }
}

export default SpaceInfo;
