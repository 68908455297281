import React, { Component } from 'react';
import { ISection, ISpace, QuestionsSortOrder } from '../types';
import styles from './questions-sort-order-list.module.scss';

type IProps = {
  space?: ISpace;
  currentSection?: ISection;
  onQuestionsSortOrderListChange: (currentSection: ISection, questionsSortOrder: QuestionsSortOrder) => void;
  supportRandomSort: boolean;
};

class QuestionsSortOrderList extends Component<IProps, any> {
  handleQuestionsSortOrderChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    const questionsSortOrder: QuestionsSortOrder = event.target.value as QuestionsSortOrder;
    this.props.onQuestionsSortOrderListChange(this.props.currentSection!, questionsSortOrder);
  };

  renderOptions(): React.ReactNode {
    const options: React.ReactNode[] = [
      <option key='vote_asc' value='vote_asc'>
        投票数が少ない順
      </option>,
      <option key='vote_desc' value='vote_desc'>
        投票数が多い順
      </option>,
      <option key='created_at_asc' value='created_at_asc'>
        投稿日時が古い順
      </option>,
      <option key='created_at_desc' value='created_at_desc'>
        投稿日時が新しい順
      </option>,
    ];
    if (this.props.supportRandomSort) {
      options.push(
        <option key='random' value='random'>
          ランダム順
        </option>
      );
    }
    return options;
  }

  render(): React.ReactNode {
    return (
      <select
        className={`${styles.select}`}
        value={this.props.currentSection!.questionsSortOrder}
        onChange={this.handleQuestionsSortOrderChange}
      >
        {this.renderOptions()}
      </select>
    );
  }
}

export default QuestionsSortOrderList;
