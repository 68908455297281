import React, { ChangeEvent, Component } from 'react';
import FirebaseContext from '../firebase_context';
import { Errors, ISection, ISpace, QuestionType } from '../types';
import UIkit from 'uikit';
import {
  validateStringLength,
  STRING_MAX_LENGTH_SURVEY_QUESTION,
  validateArrayLength,
  STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM,
  validateStringRequired,
  validateArrayRequired,
  MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM,
  MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM,
} from '../validator';
import styles from './survey-question-form.module.scss';

type IProps = {
  space?: ISpace;
  currentSection?: ISection;
};

type IState = {
  text: string;
  itemsSource: string;
  items: string[];
  questionType: QuestionType;
  selectionLimitCount: number;
  loading: boolean;
  errors: Errors;
};

class SurveyQuestionForm extends Component<IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: IProps) {
    super(props);
    this.state = {
      text: '',
      itemsSource: '',
      items: [],
      questionType: 'single_answer',
      selectionLimitCount: 0,
      loading: false,
      errors: {},
    };
  }

  handleTextChange = (event: ChangeEvent<HTMLInputElement>) => {
    const text = event.target.value;
    this.setState({
      text,
      errors: validateStringLength(text, 'text', STRING_MAX_LENGTH_SURVEY_QUESTION, this.state.errors, true),
    });
  };

  handleItemsSourceChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const itemsSource = event.target.value;
    const items = itemsSource
      .split('\n')
      .map((s) => s.trim())
      .filter((s) => s.length > 0);
    let errors = Object.assign({}, this.state.errors);
    delete errors.items;
    if (this.state.questionType === 'single_answer') {
      errors = validateArrayLength(items, 'items', MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM, errors, false);
    } else {
      errors = validateArrayLength(items, 'items', MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM, errors, false);
    }

    for (const item of items) {
      errors = validateStringLength(item, 'items', STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM, errors, false);
    }
    let selectionLimitCount = this.state.selectionLimitCount;
    if (selectionLimitCount > items.length) {
      selectionLimitCount = items.length;
    }
    this.setState({
      itemsSource,
      items,
      selectionLimitCount,
      errors,
    });
  };

  handleQuestionsTypeChange = async (event: React.ChangeEvent<HTMLSelectElement>): Promise<void> => {
    this.setState({
      questionType: event.target.value as QuestionType,
    });
  };

  handleSelectionLimitCountChange = async (event: React.ChangeEvent<HTMLInputElement>): Promise<void> => {
    let selectionLimitCount = event.target.value ? Number(event.target.value) : 0;
    if (selectionLimitCount > this.state.items.length) {
      selectionLimitCount = this.state.items.length;
    }
    this.setState({
      selectionLimitCount,
    });
  };

  handlePostQuestionButtonClick = async (event: React.MouseEvent) => {
    event.preventDefault();
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    const postQuestion = this.context.functions.httpsCallable('postQuestion');
    const result = await postQuestion({
      spaceId: this.props.space!.id,
      sectionId: this.props.currentSection!.id,
      type: this.state.questionType,
      text: this.state.text,
      items: this.state.items,
      selectionLimitCount: this.state.selectionLimitCount,
    });
    this.setState({
      text: '',
      itemsSource: '',
      items: [],
      questionType: 'single_answer',
      selectionLimitCount: 0,
      loading: false,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleFormSubmit = (event: React.FormEvent) => {
    event.preventDefault();
  };

  validate(): boolean {
    let errors: Errors = {};
    errors = validateStringRequired(this.state.text, 'text', errors, false);
    errors = validateStringLength(this.state.text, 'text', STRING_MAX_LENGTH_SURVEY_QUESTION, errors, false);
    errors = validateArrayRequired(this.state.items, 'items', 2, errors, false);
    if (this.state.questionType === 'single_answer') {
      errors = validateArrayLength(this.state.items, 'items', MAX_COUNT_SINGLE_ANSWER_QUESTION_ITEM, errors, false);
    } else {
      errors = validateArrayLength(this.state.items, 'items', MAX_COUNT_MULTIPLE_ANSWERS_QUESTION_ITEM, errors, false);
    }
    for (const item of this.state.items) {
      errors = validateStringLength(item, 'items', STRING_MAX_LENGTH_SURVEY_QUESTION_ITEM, errors, false);
    }
    this.setState({
      errors,
    });
    return Object.keys(errors).length === 0;
  }

  renderPostButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' className={styles.in_progress} />;
    } else {
      return (
        <button className='uk-button uk-button-primary' type='button' onClick={this.handlePostQuestionButtonClick}>
          投稿する
        </button>
      );
    }
  }

  renderWarningMessageForMultipleAnswers(): React.ReactNode {
    if (this.state.questionType === 'multiple_answers') {
      return (
        <div className='uk-text-meta uk-margin-small-top'>
          ※ 回答者が少なくとも１つは選択することを前提に選択肢を組み立ててください。
        </div>
      );
    } else {
      return null;
    }
  }

  renderSelectionLimitCount(): React.ReactNode {
    if (this.state.questionType === 'multiple_answers') {
      return (
        <div className={styles.survey_question_form_row}>
          <label htmlFor='question-form-selction-limit-count'>選択可能数</label>
          <input
            id='question-form-selection-limit-count'
            className='uk-input'
            placeholder='回答時に選択可能な個数の上限を入力'
            type='number'
            value={this.state.selectionLimitCount}
            min={0}
            onChange={this.handleSelectionLimitCountChange}
            required={true}
          />
          <div className='uk-text-meta uk-margin-small-top'>※ 上限を設けない場合は「0」を入力してください。</div>
        </div>
      );
    } else {
      return null;
    }
  }

  render(): React.ReactNode {
    if (this.props.currentSection) {
      return (
        <div className={styles.survey_question_form}>
          <div className={styles.survey_question_form_row}>
            <input
              id='question-form-text'
              className='uk-input'
              placeholder='質問したいことを入力'
              value={this.state.text}
              onChange={this.handleTextChange}
            />
            <span className='uk-text-danger'>{this.state.errors.text}</span>
          </div>
          <div className={styles.survey_question_form_row}>
            <label htmlFor='question-form-items'>選択肢</label>
            <textarea
              id='question-form-items'
              className='uk-textarea'
              rows={3}
              placeholder='選択肢を改行区切りで入力'
              value={this.state.itemsSource}
              onChange={this.handleItemsSourceChange}
            />
            <span className='uk-text-danger'>{this.state.errors.items}</span>
          </div>
          <div className={styles.survey_question_form_row}>
            <label htmlFor='question-form-type'>質問タイプを選択</label>
            <select value={this.state.questionType} onChange={this.handleQuestionsTypeChange}>
              <option value='single_answer'>１つだけ選択可能</option>
              <option value='multiple_answers'>複数選択可能</option>
            </select>
            {this.renderWarningMessageForMultipleAnswers()}
          </div>
          {this.renderSelectionLimitCount()}
          <div className={styles.survey_question_form_row}>{this.renderPostButton()}</div>
        </div>
      );
    } else {
      return null;
    }
  }
}

export default SurveyQuestionForm;
