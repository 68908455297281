import React, { Component } from 'react';
import { IAnswer, IQuestion, ISection, ISpace } from '../types';
import FirebaseContext from '../firebase_context';
import UIkit from 'uikit';
import moment from 'moment';
import styles from './vote-question.module.scss';
import VoteQuestionConsumeToggleButton from '../admin/vote-question-consume-toggle-button';
import { nl2br } from '../utils';
import DelayedTask from '../common/delayed-task';

type IProps = {
  space: ISpace;
  section: ISection;
  question: IQuestion;
  answers: IAnswer[];
  forAdmin: boolean;
  nickname?: string;
  onConsumedChange?: (consumed: boolean, question: IQuestion) => void;
  onAnswer?: (question: IQuestion, values: number[], nickname?: string) => void;
  onDelete?: (question: IQuestion) => void;
  onClick?: (question: IQuestion) => void;
  cardView?: boolean;
  preview?: boolean;
  fullscreen: boolean;
  onSelected?: (selected: boolean, question: IQuestion) => void;
};

class VoteQuestion extends Component<IProps, any> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  voteDelayedTask: DelayedTask<number>;
  deleteDelayedTask: DelayedTask<IQuestion>;

  constructor(props: IProps) {
    super(props);
    this.voteDelayedTask = new DelayedTask<number>(1000, this.doVote);
    this.deleteDelayedTask = new DelayedTask<IQuestion>(1000, this.doDelete);
  }

  doVote = async (value: number): Promise<void> => {
    const postAnswer = this.context.functions.httpsCallable('postAnswer');
    const result = await postAnswer({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: this.props.question.id,
      values: [value],
      nickname: this.props.nickname,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  doDelete = async (value: IQuestion): Promise<void> => {
    const deleteQuestion = this.context.functions.httpsCallable('deleteQuestion');
    const result = await deleteQuestion({
      spaceId: this.props.space.id,
      sectionId: this.props.section.id,
      questionId: value.id,
    });
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  handleVoteButtonClick = async (event: React.MouseEvent) => {
    const value = this.getMyAnswer()?.values[0] === 1 ? 0 : 1;
    this.context.logEvent('event', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'vote',
      value: String(value),
    });
    this.props.onAnswer!(this.props.question, [value], this.props.nickname);
    this.voteDelayedTask.waitAndGo(value);
  };

  handleDeleteButtonClick = async (event: React.MouseEvent) => {
    this.context.logEvent('event', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'delete_question',
    });
    try {
      await UIkit.modal.confirm('質問を削除しますね？');
    } catch (reason) {
      return;
    }
    this.context.logEvent('delete_question_confirm_dialog', 'click', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'ok',
    });
    this.props.onDelete!(this.props.question);
    this.deleteDelayedTask.waitAndGo(this.props.question);
  };

  handleClick = (): void => {
    this.props.onClick && this.props.onClick(this.props.question);
  };

  handleCheckboxClick = (event: React.MouseEvent<HTMLInputElement>): void => {
    event.stopPropagation();
  };

  handleChangeSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const checked = event.target.checked;
    this.props.onSelected && this.props.onSelected(checked, this.props.question);
  };

  getMyAnswer(): IAnswer | undefined {
    return this.props.answers.find((answer) => answer.uid === this.context.currentUser()!.uid);
  }

  render(): React.ReactNode {
    if (this.props.cardView) {
      return (
        <div key={this.props.question.id} className={`${styles.vote_question} ${styles.vote_question_card_view}`}>
          <div className={`${styles.vote_question_header} ${styles.vote_question_header_card_view}`}>
            <VoteQuestionType question={this.props.question} />
            <div className={styles.vote_question_header_vote_question_nickname}>
              {this.props.question.nickname || 'untitled'}
            </div>
            <ConsumeLabel forAdmin={this.props.forAdmin} question={this.props.question} />
            <VoteQuestionConsumeToggleButton
              forAdmin={this.props.forAdmin}
              question={this.props.question}
              sectionId={this.props.section.id}
              spaceId={this.props.space.id}
              onConsumedChange={this.props.onConsumedChange}
            />
          </div>
          <div className={`${styles.vote_question_text} ${styles.vote_question_text_card_view}`}>
            {nl2br(this.props.question.text)}
          </div>
          <div className={`${styles.vote_question_footer} ${styles.vote_question_footer_card_view}`}>
            <Footer
              preview={this.props.preview!}
              question={this.props.question}
              section={this.props.section}
              forAdmin={this.props.forAdmin}
              currentUserUid={this.context.currentUser()!.uid}
              answers={this.props.answers}
              handleVoteButtonClick={this.handleVoteButtonClick}
              handleDeleteButtonClick={this.handleDeleteButtonClick}
            />
          </div>
        </div>
      );
    } else {
      const containerClasses = [styles.vote_question];
      if (!this.props.forAdmin && this.props.question.author === this.context.currentUser()?.uid) {
        containerClasses.push(styles.vote_question_mine);
      }
      if (this.props.preview) {
        containerClasses.push('uk-animation-fade');
      }
      if (this.props.onClick) {
        containerClasses.push(styles.vote_question_clickable);
      }
      return (
        <div key={this.props.question.id} className={`${containerClasses.join(' ')}`} onClick={this.handleClick}>
          <div className={styles.vote_question_header}>
            <SelectedCheckbox
              forAdmin={this.props.forAdmin}
              cardView={this.props.cardView}
              fullscreen={this.props.fullscreen}
              question={this.props.question}
              handleCheckboxClick={this.handleCheckboxClick}
              handleChangeSelected={this.handleChangeSelected}
            />
            <VoteQuestionType question={this.props.question} />
            <div className={styles.vote_question_header_vote_question_datetime}>
              {moment(this.props.question.createdAt).format('HH:mm')}
            </div>
            <div className={styles.vote_question_header_vote_question_nickname}>
              {this.props.question.nickname || 'untitled'}
            </div>
            <ConsumeLabel forAdmin={this.props.forAdmin} question={this.props.question} />
            <VoteQuestionConsumeToggleButton
              forAdmin={this.props.forAdmin}
              question={this.props.question}
              sectionId={this.props.section.id}
              spaceId={this.props.space.id}
              onConsumedChange={this.props.onConsumedChange}
            />
          </div>
          <div className={styles.vote_question_text}>{nl2br(this.props.question.text)}</div>
          <div className={styles.vote_question_footer}>
            <Footer
              preview={this.props.preview!}
              question={this.props.question}
              section={this.props.section}
              forAdmin={this.props.forAdmin}
              currentUserUid={this.context.currentUser()!.uid}
              answers={this.props.answers}
              handleVoteButtonClick={this.handleVoteButtonClick}
              handleDeleteButtonClick={this.handleDeleteButtonClick}
            />
          </div>
        </div>
      );
    }
  }
}

export default VoteQuestion;

type VoteQuestionTypeProps = {
  question: IQuestion;
};

function VoteQuestionType(props: VoteQuestionTypeProps) {
  if (props.question.voteQuestionType === 'comment') {
    return <div className={styles.vote_question_header_vote_question_type}>コメント</div>;
  } else if (props.question.voteQuestionType === 'question') {
    return <div className={styles.vote_question_header_vote_question_type}>質問</div>;
  } else {
    return null;
  }
}

type FooterProps = {
  preview: boolean;
  handleVoteButtonClick: (event: React.MouseEvent) => void;
  answers: IAnswer[];
  forAdmin: boolean;
  currentUserUid: string;
  question: IQuestion;
  section: ISection;
  handleDeleteButtonClick: (event: React.MouseEvent) => void;
};

function Footer(props: FooterProps) {
  if (!props.preview) {
    return (
      <>
        <VoteCounts
          answers={props.answers}
          forAdmin={props.forAdmin}
          currentUserUid={props.currentUserUid}
          handleVoteButtonClick={props.handleVoteButtonClick}
          question={props.question}
          section={props.section}
        />
        <DeleteButton
          forAdmin={props.forAdmin}
          currentUserUid={props.currentUserUid}
          question={props.question}
          handleDeleteButtonClick={props.handleDeleteButtonClick}
        />
      </>
    );
  } else {
    return null;
  }
}

type VoteCountsProps = {
  answers: IAnswer[];
  forAdmin: boolean;
  section: ISection;
  question: IQuestion;
  handleVoteButtonClick: (event: React.MouseEvent) => void;
  currentUserUid: string;
};

function VoteCounts(props: VoteCountsProps) {
  const answers = props.answers;
  const upVoteCount = answers.filter((answer) => answer.values[0] === 1).length;
  const clickable = !props.forAdmin && props.section.open && !props.question.consumed;
  const classNames = [styles.vote_question_footer_vote_count];
  if (clickable) {
    classNames.push(styles.vote_question_footer_vote_count_clickable);
  }
  const myAnswer = props.answers.find((answer) => answer.uid === props.currentUserUid);
  if (myAnswer?.values[0] === 1) {
    classNames.push(styles.vote_question_footer_vote_count_voted);
  }
  if (clickable) {
    return (
      <div className={classNames.join(' ')} onClick={props.handleVoteButtonClick}>
        {upVoteCount}
      </div>
    );
  } else {
    return <div className={classNames.join(' ')}>{upVoteCount}</div>;
  }
}

type DeleteButtonProps = {
  forAdmin: boolean;
  question: IQuestion;
  currentUserUid: string;
  handleDeleteButtonClick: (event: React.MouseEvent) => void;
};

function DeleteButton(props: DeleteButtonProps) {
  if (props.forAdmin || props.question.author === props.currentUserUid) {
    return <div className={styles.vote_question_footer_delete_button} onClick={props.handleDeleteButtonClick} />;
  } else {
    return null;
  }
}

type ConsumeLabelProps = {
  forAdmin: boolean;
  question: IQuestion;
};

function ConsumeLabel(props: ConsumeLabelProps) {
  if (!props.forAdmin && props.question.consumed) {
    return <div className={styles.vote_question_header_consume_badge}>回答済み</div>;
  } else {
    return null;
  }
}

type SelectedCheckboxProps = {
  forAdmin: boolean;
  cardView?: boolean;
  fullscreen: boolean;
  question: IQuestion;
  handleCheckboxClick: (event: React.MouseEvent<HTMLInputElement>) => void;
  handleChangeSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

function SelectedCheckbox(props: SelectedCheckboxProps) {
  if (props.forAdmin && !props.cardView && !props.fullscreen) {
    return (
      <div className={styles.vote_question_selected_container}>
        <input
          id={`vote-question-selected-checkbox-${props.question.id}`}
          type='checkbox'
          className={`${styles.vote_question_selected_checkbox} uk-checkbox`}
          checked={props.question.selected}
          onChange={props.handleChangeSelected}
          onClick={props.handleCheckboxClick}
        />
      </div>
    );
  } else {
    return null;
  }
}
