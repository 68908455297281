class DelayedTask<T> {
  private queue: number[];
  private callback: (parameter: T, delayedTask: DelayedTask<T>) => Promise<void>;
  private time: number;

  constructor(time: number, callback: (parameter: T, delayedTask: DelayedTask<T>) => Promise<void>) {
    this.queue = [];
    this.callback = callback;
    this.time = time;
  }

  waitAndGo(parameter: T): void {
    if (this.queue.length > 0) {
      this.queue.forEach((taskId: number) => {
        clearTimeout(taskId);
        this.queue.shift();
      });
    }
    const taskId = setTimeout(this.callback, this.time, parameter, this);
    this.queue.push(taskId);
  }
}

export default DelayedTask;
