import React, { Fragment } from 'react';
import AbstractSurveyQuestion from './abstract-survey-question';
import styles from './multiple-answers-question.module.scss';
import FirebaseContext from '../firebase_context';

class MultipleAnswersQuestion extends AbstractSurveyQuestion {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  handleItemSelectionChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = Number(event.target.value);
    const checked = event.target.checked;

    let values: number[] = this.getMyAnswer()?.values || [];
    values = values.filter((v) => v !== value);
    if (checked) {
      values.push(value);
    }
    this.context.logEvent('event', 'change_select', {
      event_id: this.props.space.customSpaceId,
      section_id: this.props.section.id,
      question_id: this.props.question.id,
      target: 'multi_answers',
      value: values.map((v) => String(v)).join(','),
    });
    this.props.onAnswer!(this.props.question, values);
    this.delayedTaskPostAnswer.waitAndGo(values);
  };

  renderItems(): React.ReactNode {
    if (this.props.forAdmin) {
      return null;
    }
    const myValues = this.getMyAnswer()?.values || [];
    const itemFragments = this.props.question.items.map((item, index) => {
      let disabled;
      if (this.props.question.selectionLimitCount === 0) {
        disabled = !this.props.section.open || !this.props.question.open;
      } else {
        disabled =
          !this.props.section.open ||
          !this.props.question.open ||
          (myValues.length === this.props.question.selectionLimitCount && !myValues.includes(index));
      }
      return (
        <Fragment key={index}>
          <label>
            <input
              id={`question-${this.props.offsetInQuestions}-${index}`}
              className='uk-checkbox'
              type='checkbox'
              name={`question-${this.props.question.id}`}
              checked={myValues?.includes(index)}
              onChange={this.handleItemSelectionChange}
              disabled={disabled}
              value={index}
            />
            <div className={myValues?.includes(index) ? styles.multiple_answers_question_answered : ''}>{item}</div>
          </label>
        </Fragment>
      );
    });
    return <div className={styles.multiple_answers_question}>{itemFragments}</div>;
  }

  createAnswerMap(): { [p: number]: number } {
    return this.props.answers.reduce<{ [p: number]: number }>((map, answer) => {
      for (const value of answer.values) {
        let number = map[value] || 0;
        map[value] = number + 1;
      }
      return map;
    }, {});
  }

  renderSelectionLimitCount(): React.ReactNode {
    if (this.props.question.selectionLimitCount! > 0) {
      return ` (${this.props.question.selectionLimitCount}つまで)`;
    } else {
      return null;
    }
  }
}

export default MultipleAnswersQuestion;
