import React, { Component } from 'react';
import { ISpace, ISpaceCredential, ISpaceSlackSetting } from '../types';
import styles from './admin-space-info.module.scss';
import moment from 'moment';
import CopiableText from '../common/copiable-text';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import EditSpace from './edit-space';
import { createSpaceUrl } from '../utils';
import UIkit from 'uikit';
import FirebaseContext from '../firebase_context';

type IProps = {
  space?: ISpace;
  spaceCredential?: ISpaceCredential;
  spaceSlackSetting?: ISpaceSlackSetting;
  onToggleSectionListClick: (event: React.MouseEvent) => void;
  onSpaceVisibleChange: (space: ISpace, visible: boolean) => void;
};

type IState = {
  userStatisticsLoading: boolean;
  userSessionCount: number;
  userCountPostedVoteQuestion: number;
};

class AdminSpaceInfo extends Component<RouteComponentProps<{}> & IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  userStatisticsModalRef: React.RefObject<HTMLDivElement>;

  constructor(props: RouteComponentProps<{}> & IProps) {
    super(props);
    this.userStatisticsModalRef = React.createRef<HTMLDivElement>();
    this.state = {
      userStatisticsLoading: false,
      userSessionCount: 0,
      userCountPostedVoteQuestion: 0,
    };
  }

  handleToggleSectionListClick = (event: React.MouseEvent): void => {
    this.props.onToggleSectionListClick(event);
  };

  handleBackButtonClick = (event: React.MouseEvent): void => {
    this.props.history.push('/admin');
  };

  handleUserStatisticsButtonClick = async (event: React.MouseEvent): Promise<void> => {
    await this.updateUserStatistics();
  };

  async updateUserStatistics(): Promise<void> {
    this.setState({
      userStatisticsLoading: true,
    });
    UIkit.modal(this.userStatisticsModalRef.current!).show();
    const getUserStatistics = this.context.functions.httpsCallable('getUserStatistics');
    const result = await getUserStatistics({
      spaceId: this.props.space!.id,
      summaryTypes: ['user_session_count_per_minute', 'user_count_posted_vote_question'],
    });
    this.setState({
      userStatisticsLoading: false,
    });
    if (result.data.success) {
      this.setState({
        userSessionCount: result.data.userSessionCountPerMinute,
        userCountPostedVoteQuestion: result.data.userCountPostedVoteQuestion,
      });
    } else {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  }

  handleUpdateButtonClick = async (): Promise<void> => {
    await this.updateUserStatistics();
  };

  renderUserStatisticsItems(): React.ReactNode {
    if (this.state.userStatisticsLoading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <ul className='uk-list uk-list-striped'>
          <li>
            <div className={styles.label}>ユーザセッション数</div>
            <div className={styles.value}>{this.state.userSessionCount}</div>
          </li>
          <li>
            <div className={styles.label}>質問投稿ユーザ数</div>
            <div className={styles.value}>{this.state.userCountPostedVoteQuestion}</div>
          </li>
        </ul>
      );
    }
  }

  renderUserStatisticsModalDialog(): React.ReactNode {
    return (
      <div uk-modal='true' ref={this.userStatisticsModalRef}>
        <div className='uk-modal-dialog'>
          <button className='uk-modal-close-default' type='button' uk-close='true' />
          <div className='uk-modal-body'>
            <div className={styles.user_statistics_header}>
              <h2 className={styles.modal_title}>統計情報</h2>
            </div>
            <div className={styles.user_statistics_container}>{this.renderUserStatisticsItems()}</div>
            <div className={styles.user_statistics_footer}>
              <button className='uk-button' type='button' onClick={this.handleUpdateButtonClick}>
                情報更新
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  render(): React.ReactNode {
    const spaceUrl = createSpaceUrl(this.props.space!, this.props.spaceCredential!);
    const spaceInfoText = `Moderatorでイベントに参加する\n${spaceUrl}\n\nイベントID: ${this.props.space?.customSpaceId}\nパスワード: ${this.props.spaceCredential?.spacePassword}`;
    return (
      <div className={styles.container}>
        <div className={styles.space_info_container}>
          <div className={`${styles.back_button}`} onClick={this.handleBackButtonClick} />
          <div className={styles.space_info_area}>
            <div className={styles.space_name}>{this.props.space?.name}</div>
            <div className={styles.space_created_at}>{moment(this.props.space?.createdAt).format('YYYY-MM-DD')}</div>
          </div>
          <EditSpace
            space={this.props.space}
            credential={this.props.spaceCredential}
            slackSetting={this.props.spaceSlackSetting}
            onSpaceVisibleChange={this.props.onSpaceVisibleChange}
          />
        </div>
        <div className={`${styles.copiable_text_list} uk-visible@s`}>
          <div className={styles.header_button} onClick={this.handleUserStatisticsButtonClick}>
            統計情報
          </div>
          {this.renderUserStatisticsModalDialog()}
          <div className={styles.header_button}>
            <CopiableText value={spaceUrl} displayText='URL' />
          </div>
          <div className={styles.header_button}>イベント情報を表示</div>
          <div
            className={`${styles.header_space_info} dropdown-pos-90`}
            uk-dropdown='animation: false; pos: top-right; mode: click'
          >
            <div className={styles.header_space_info_detail}>
              <div className={styles.header_space_info_detail_item}>イベントID: {this.props.space?.customSpaceId}</div>
              <div className={styles.header_space_info_detail_item}>
                パスワード: {this.props.spaceCredential?.spacePassword}
              </div>
              <CopiableText
                value={spaceInfoText}
                displayText='イベント情報をコピー'
                style={{
                  marginLeft: 'auto',
                }}
              />
            </div>
          </div>
          <a className={styles.open_attendee_page_button} href={spaceUrl} target='_blank' rel='noopener noreferrer'>
            <span>イベントを新規タブで開く</span>
          </a>
        </div>
        <div className={`${styles.toggle_section_list} uk-hidden@s`} onClick={this.handleToggleSectionListClick} />
      </div>
    );
  }
}

export default withRouter(AdminSpaceInfo);
