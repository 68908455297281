import React, { Component } from 'react';
import styles from './licene-agreement.module.scss';
import FirebaseContext from '../firebase_context';
import Firebase from 'firebase';
import moment from 'moment';
import UIkit from 'uikit';

type IState = {
  alreadyAgreed: boolean;
};

class LicenseAgreement extends Component<any, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: any) {
    super(props);
    this.state = {
      alreadyAgreed: true,
    };
  }

  componentDidMount() {
    this.getAgreementUpdatedAt().then((agreementUpdatedAt) => {
      this.getLastAgreedAt().then((lastAgreedAt) => {
        if (!lastAgreedAt || moment(lastAgreedAt).isBefore(moment(agreementUpdatedAt))) {
          this.setState({
            alreadyAgreed: false,
          });
        }
      });
    });
  }

  getAgreementUpdatedAt(): Promise<Date> {
    return new Promise<Date>((resolve, reject) => {
      this.context.db
        .collection('configurations')
        .doc('agreement')
        .get()
        .then((documentSnapshot: Firebase.firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            resolve(documentSnapshot.data()!.updated_at.toDate());
          } else {
            reject('Agreement configuration not found.');
          }
        });
    });
  }

  getLastAgreedAt(): Promise<Date | undefined> {
    return new Promise((resolve, reject) => {
      this.context.db
        .collection('users')
        .doc(this.context.currentUser()!.uid)
        .get()
        .then((documentSnapshot: Firebase.firestore.DocumentSnapshot) => {
          if (documentSnapshot.exists) {
            const lastAgreedAt = documentSnapshot.data()!.last_agreed_at;
            resolve(lastAgreedAt ? lastAgreedAt.toDate() : undefined);
          } else {
            resolve(undefined);
          }
        });
    });
  }

  handleAgreeButtonClick = async (event: React.MouseEvent<HTMLButtonElement>): Promise<void> => {
    this.setState({
      alreadyAgreed: true,
    });
    const approveLicense = this.context.functions.httpsCallable('approveLicense');
    const result = await approveLicense({});
    if (!result.data.success) {
      await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
    }
  };

  render(): React.ReactNode {
    if (this.state.alreadyAgreed) {
      return null;
    } else {
      return (
        <div className={styles.container}>
          <div className={styles.sentence}>
            このサイトを利用することによって、あなたはこのサイトの
            <a href='/cookie.html' target='_blank'>
              クッキーポリシー
            </a>
            および
            <a href='/tos.html' target='_blank'>
              利用規約
            </a>
            を読んで理解し、同意したものとみなします。
          </div>
          <button className='uk-button' onClick={this.handleAgreeButtonClick}>
            理解しました
          </button>
        </div>
      );
    }
  }
}

export default LicenseAgreement;
