import React, { ChangeEvent, Component } from 'react';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import styles from './join-to-space-form.module.scss';
import UIkit from 'uikit';
import { ModeratorScreenName } from '../types';
import FirebaseContext from '../firebase_context';
import { ERROR_PASSWORD_NOT_MATCH } from '../errors';

type IState = {
  customSpaceId: string;
  spacePassword: string;
  loading: boolean;
};

interface IProps {
  customSpaceId?: string;
  withPassword?: boolean;
  screenName: ModeratorScreenName;
}

class JoinToSpaceForm extends Component<RouteComponentProps<{}> & IProps, IState> {
  static contextType = FirebaseContext;
  context!: React.ContextType<typeof FirebaseContext>;

  constructor(props: RouteComponentProps<{}> & IProps) {
    super(props);
    this.state = {
      customSpaceId: this.props.customSpaceId || '',
      spacePassword: '',
      loading: false,
    };
  }

  handleSpaceIdChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      customSpaceId: event.target.value,
    });
  };

  handleSpacePasswordChange = (event: ChangeEvent<HTMLInputElement>) => {
    this.setState({
      spacePassword: event.target.value,
    });
  };

  handleJoinButtonClick = async (event: React.MouseEvent): Promise<void> => {
    this.context.logEvent(this.props.screenName, 'click', {
      event_id: this.state.customSpaceId,
      target: 'join_to_event',
    });
    if (!this.validate()) {
      return;
    }
    this.setState({
      loading: true,
    });
    if (this.props.withPassword) {
      const customSpaceIdSnapshot = await this.context.db
        .collection('custom_space_id_map')
        .doc(this.state.customSpaceId)
        .get();
      if (customSpaceIdSnapshot.exists) {
        const spaceId = customSpaceIdSnapshot.data()!.space_id;
        const joinToSpace = this.context.functions.httpsCallable('joinToSpace');
        const result = await joinToSpace({
          spaceId,
          passwordType: 'normal',
          spacePassword: this.state.spacePassword,
        });
        this.setState({
          loading: false,
        });
        if (result.data.success) {
          this.props.history.push(`/e/${encodeURIComponent(this.state.customSpaceId)}`);
        } else {
          if (result.data.errorCode === ERROR_PASSWORD_NOT_MATCH) {
            await UIkit.modal.alert('パスワードが一致しません。');
          } else {
            await UIkit.modal.alert(`Error: ${result.data.errorMessage}`);
          }
        }
      } else {
        this.setState({
          loading: false,
        });
        await UIkit.modal.alert('イベントが存在しません。');
      }
    } else {
      this.props.history.push(`/e/${encodeURIComponent(this.state.customSpaceId)}`);
    }
  };

  validate(): boolean {
    let valid = true;
    if (!this.state.customSpaceId.trim()) {
      valid = false;
      UIkit.notification({
        message: 'イベントIDを入力してください。',
        status: 'danger',
        timeout: 2000,
      });
    }
    return valid;
  }

  renderPasswordField(): React.ReactNode {
    if (this.props.withPassword) {
      return (
        <input
          className={`${styles.input_field} uk-input`}
          type='password'
          placeholder='パスワードを入力してください。'
          value={this.state.spacePassword}
          onChange={this.handleSpacePasswordChange}
        />
      );
    } else {
      return null;
    }
  }

  renderButton(): React.ReactNode {
    if (this.state.loading) {
      return <div uk-spinner='true' />;
    } else {
      return (
        <button
          className={`${styles.join_button} uk-button uk-button-secondary`}
          type='button'
          onClick={this.handleJoinButtonClick}
        >
          <span>参加する</span>
        </button>
      );
    }
  }

  render(): React.ReactNode {
    return (
      <>
        <input
          className={`${styles.input_field} uk-input`}
          type='text'
          placeholder='イベントIDを入れてね'
          value={this.state.customSpaceId}
          onChange={this.handleSpaceIdChange}
        />
        {this.renderPasswordField()}
        {this.renderButton()}
      </>
    );
  }
}

export default withRouter(JoinToSpaceForm);
